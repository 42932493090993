import React from 'react';

import dayjs from 'dayjs';
import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';

import { RejectionPayload } from 'features/ConsultationOffer/api/useConsultationOffer';
import { ConsultationOffer } from 'types';

import { SlotComponent } from './Slot';

type Props = {
  slotsRendezVous: string[];
  handleAccept: (rdvAt?: string) => Promise<void>;
  handleReject?: (payload: RejectionPayload) => Promise<ConsultationOffer>;
  submitting: boolean;
  dateDebutEstimee?: string;
  consultationOffer: ConsultationOffer;
};

export const RdvSelector: React.FC<Props> = ({
  slotsRendezVous,
  handleAccept,
  submitting,
  dateDebutEstimee,
  consultationOffer,
}) => {
  const slotsGroupedByDay = _groupBy(slotsRendezVous, date => dayjs(date).format('ddd DD MMM'));
  const hasInfos = slotsRendezVous.length > 0 && consultationOffer.withRdv;

  return (
    <>
      {hasInfos && (
        <>
          <div className="font-bold text-ds-h5 text-neutral-800">Rendez-vous client</div>
          <div className="text-neutral-700 text-ds-b1 mb-md">
            Ce chantier vous intéresse ? Choisissez le créneau qui vous arrange parmi les
            disponibilités du client. Si aucun créneau ne convient, contactez le chef de projet qui
            s’occupe du projet pour lui communiquer vos disponibilités.
          </div>
        </>
      )}
      <div className="flex flex-col gap-md">
        {_keys(slotsGroupedByDay).map(day => (
          <div key={day}>
            <SlotComponent
              day={day}
              onChooseSlot={handleAccept}
              hourSlots={slotsGroupedByDay[day]}
              submitting={submitting}
              dateDebutEstimeeTravaux={dateDebutEstimee}
            />
          </div>
        ))}
      </div>
    </>
  );
};
