import { Devis } from '@travauxlib/shared/src/types';

import { useDevisHistoryContext } from '..';

export const MAX_HISTORY_LENGTH = 10;

export const useAddDevisHistoryEntry = (): ((devis: Devis) => void) => {
  const { state, setState } = useDevisHistoryContext();

  return (devis: Devis) => {
    const { token } = devis;
    const existingPast = state[token] ? state[token].past : [];

    setState({
      ...state,
      [token]: {
        future: [],
        past: [...existingPast, devis].slice(-MAX_HISTORY_LENGTH),
      },
    });
  };
};
