import { AbilityBuilder, MongoAbility, MongoQuery } from '@casl/ability';

import { ProCompany } from 'types';

export type Actions = 'activate' | 'create' | 'read' | 'update' | 'archive' | 'send' | 'manage';
export type Subjects =
  | 'BatiPrix'
  | 'BonCommande'
  | 'CentraleAchat'
  | 'HemeaPay'
  | 'Notification'
  | 'SuiviChantier'
  | 'DevisRGE'
  | 'BaseDePrix'
  | 'Deal'
  | 'ProClient'
  | 'Contacts'
  | 'Devis'
  | 'Factures'
  | 'Dashboard'
  | 'DevisCustomization'
  | 'Labellisation'
  | 'all'
  | { isHemea?: boolean };

export type AppAbility = MongoAbility<[Actions, Subjects], MongoQuery>;

export abstract class Ability {
  protected labelliseeStatus = ['LABELLISEE', 'ACTIVEE'];

  protected isLabellisee = (companyStatus?: string): boolean =>
    !!companyStatus && this.labelliseeStatus.includes(companyStatus);

  protected isLabellisationEnCours = (companyStatus?: string): boolean =>
    companyStatus === 'LABELLISATION_EN_COURS';

  protected isDelabellisee = (companyStatus?: string): boolean => companyStatus === 'DELABELLISEE';

  constructor(protected abilityBuilder: AbilityBuilder<AppAbility>) {}

  abstract defineAbility(company?: ProCompany): void;

  build(): AppAbility {
    return this.abilityBuilder.build();
  }
}
