import React, { useState } from 'react';

import Eye from '@travauxlib/shared/src/components/DesignSystem/assets/Eye.svg?react';
import EyeClose from '@travauxlib/shared/src/components/DesignSystem/assets/EyeClose.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';

import { HistoryItem } from './types';

type Props = {
  item: HistoryItem;
  changeTransactionVisibility?: () => void;
};

export const ChangeTransactionVisibility: React.FC<Props> = ({
  item,
  changeTransactionVisibility,
}: Props) => {
  const [isOpenVisibility, setIsOpenVisibility] = useState(false);

  if (!changeTransactionVisibility) {
    return null;
  }
  return (
    <>
      <IconButton
        onClick={() => setIsOpenVisibility(true)}
        size="xs"
        data-testid={`ignore-transaction-${item.key}`}
      >
        {item.isVisible ? <EyeClose /> : <Eye />}
      </IconButton>

      <FeedbackMessages
        variant="warning"
        validateAction={{
          label: `${item.isVisible ? 'Cacher' : 'Afficher'} l'operation`,
          onClick: () => {
            changeTransactionVisibility();
            setIsOpenVisibility(false);
          },
        }}
        isOpen={isOpenVisibility}
        title={`${item.isVisible ? 'Cacher' : 'Afficher'} l'operation?`}
        message={`En confirmant, la transaction sera ${
          item.isVisible ? 'visible' : 'cachée'
        } dans l'historique des mouvements sur ce compte séquestre pour le client et le pro. Cette action doit etre appliquée sur toutes les operations qui doivent etre affichées (par exemple, le paiement puis le remboursement au client)!`}
        handleClose={() => setIsOpenVisibility(false)}
      />
    </>
  );
};
