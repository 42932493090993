import dayjs, { Dayjs } from 'dayjs';

import { UserProfile, CompanyType } from '@travauxlib/shared/src/types';

import { ProCompany } from 'types';

import { useProfile } from './useProfile';

export const useIsHemeaEmployee = (): boolean => {
  const { profile } = useProfile();
  return profile?.isHemeaEmployee;
};

export const useFullName = (): string => {
  const { profile } = useProfile();
  return profile?.fullName;
};

export const useProCompany = (): ProCompany => {
  const { profile } = useProfile();
  return profile?.company;
};

export const useStripeCardExpirationDate = (): undefined | Dayjs => {
  const proCompany = useProCompany();
  return proCompany.stripeCardExpirationDate === undefined
    ? undefined
    : dayjs(proCompany.stripeCardExpirationDate);
};

export const useStripePaymentFailed = (): boolean => {
  const proCompany = useProCompany();
  return proCompany?.stripePaymentFailed || false;
};

export const useActivatedMarginCalculation = (): boolean => {
  const proCompany = useProCompany();
  return proCompany?.activatedMarginCalculation || false;
};

export const useEmailRelanceContent = (): string | undefined => {
  const proCompany = useProCompany();
  return proCompany?.emailRelanceContent;
};

export const useClubProStatus = (): ProCompany['clubProStatus'] => {
  const proCompany = useProCompany();
  return proCompany?.clubProStatus;
};

export const useUniceURL = (): string | undefined => {
  const { profile } = useProfile();
  return profile?.uniceURL;
};

export const useDefaultTauxTVA = (): UserProfile<ProCompany>['defaultTauxTVA'] => {
  const { profile } = useProfile();
  return profile?.defaultTauxTVA;
};

export const useIsUnsubscribing = (): boolean => useClubProStatus() === 'unsubscribing';

export const useIsLabellisee = (): boolean => {
  const proCompany = useProCompany();

  return !!proCompany && ['LABELLISEE', 'ACTIVEE'].includes(proCompany.status);
};

export const useInsurances = (): ProCompany['insurances'] => useProCompany()?.insurances;

export const useIsArchitecte = (): boolean => useProCompany()?.isArchitecte;

export const useIsPro = (): boolean => useProCompany()?.companyType === 'pro';

export const useCanAccessChartePro = (): boolean => {
  const proCompany = useProCompany();
  const isLabellisee = useIsLabellisee();

  return proCompany?.companyType === 'pro' && isLabellisee;
};

export const useCompanyType = (): CompanyType => useProCompany()?.companyType;

export const useArchiType = (): ProCompany['archiType'] => useProCompany()?.archiType;

export const useIsOptymeaAccountActivated = (): boolean =>
  useProCompany()?.isOptymeaAccountActivated;

export const useHasValidInsurances = (): boolean => useProCompany()?.hasValidInsurances;

export const useCanActivateTrialPeriod = (): boolean => {
  const proCompany = useProCompany();
  return !!proCompany?.canActivateTrialPeriod;
};

export const useDisabledFactureAutomaticNumbering = (): boolean => {
  const proCompany = useProCompany();
  return proCompany?.disabledFactureAutomaticNumbering;
};

export const useAccountBlockedDate = (): Dayjs | null => {
  const proCompany = useProCompany();
  if (proCompany?.isEmailVerified) {
    return null;
  }
  return dayjs(proCompany?.createdAt).add(2, 'week');
};

export const useIsAccountBlocked = (): boolean => {
  const accountBlockedDate = useAccountBlockedDate();

  return accountBlockedDate !== null && dayjs().isAfter(accountBlockedDate);
};

export const useSubscriptionEndDate = (): string | undefined => {
  const proCompany = useProCompany();
  return proCompany.subscriptionEndDate ? proCompany.subscriptionEndDate : undefined;
};

export const useClubProPlan = (): string | undefined => {
  const proCompany = useProCompany();
  return proCompany.clubProPlanType;
};

export const useIsProBaseDePrixHemeaEnabled = (): boolean => {
  const proCompany = useProCompany();

  return proCompany.isBaseDePrixHemeaEnabled;
};
