import React from 'react';

import { Form } from 'react-final-form';

import {
  Modal,
  ModalContent,
  SimpleModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { composeValidators, required } from '@travauxlib/shared/src/utils/form';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { useCreateAppelDePaiement } from '../api/useCreateAppelDePaiement';

type Props = {
  onClose: () => void;
  dealUuid: string;
  resteAAppeler: number;
  isOpen: boolean;
};

export type FormDataPaiement = {
  montant: number;
};

export const AppelDePaiementModal: React.FC<Props> = ({
  onClose,
  dealUuid,
  isOpen,
  resteAAppeler,
}) => {
  const { isLoading, isSuccess, createAppelDePaiement } = useCreateAppelDePaiement();
  const validateMontantAppelle = (montantAppelle: number): undefined | string =>
    roundToTwoDecimals(resteAAppeler - montantAppelle) < 0 ? 'Montant invalide' : undefined;

  if (isSuccess) {
    return (
      <SimpleModal handleClose={onClose} isOpen={isOpen}>
        <h3 className="!text-center mb-lg">✅ Appel de paiement envoyé</h3>
        <div className="!text-center mb-sm">
          Votre appel de paiement a été envoyé au client par email. Il peut dès à présent le valider
          depuis son interface.
        </div>
      </SimpleModal>
    );
  }

  return (
    <Modal isOpen={isOpen} title="Appel de paiement" handleClose={onClose} size="md">
      <Form<FormDataPaiement>
        onSubmit={async values => {
          await createAppelDePaiement({ dealUuid, montant: values.montant });
        }}
      >
        {({ handleSubmit, invalid, values }) => (
          <ModalContent
            validateAction={{
              label: 'Envoyer',
              loading: isLoading,
              type: 'submit',
              disabled: invalid || isLoading,
            }}
            cancelAction={{ label: 'Annuler', onClick: onClose }}
            handleSubmit={handleSubmit}
          >
            <strong>Avant</strong>
            <div className="mb-md">
              Reste à appeler :{' '}
              <EURCurrency data-testid="before-appel" amount={resteAAppeler} forceFullPattern />
            </div>
            <NumberInputField
              name="montant"
              id="montant"
              label="Montant"
              min={0.01}
              fractionDigits={2}
              suffix="€"
              validate={composeValidators(required, validateMontantAppelle)}
              className="mb-md"
            />
            <strong>Après</strong>
            <div>
              Reste à appeler :{' '}
              <EURCurrency
                data-testid="after-appel"
                amount={Math.max(0, roundToTwoDecimals(resteAAppeler - (values.montant || 0)))}
                forceFullPattern
              />
            </div>
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
