import React from 'react';

import { Dropzone } from '@travauxlib/shared/src/components/Dropzone';
import { Attachment } from '@travauxlib/shared/src/types';

import { UploadFilesPayload } from 'features/Devis/api/useUploadFiles';

import { AttachmentItem } from './AttachmentItem';

type Props = {
  dealUuid: string;
  token: string;
  attachments: Attachment[];
  onChange: (newAttachments: Attachment[]) => void;
  label?: string;
  hideSave?: boolean;
  uploadFiles: ({ token, dealUuid, files, fileType }: UploadFilesPayload) => Promise<{
    token: string;
    dealUuid: string;
    files: Attachment[];
  }>;
  fileType: 'bonCommandeAttachment' | 'devisAttachment';
};

export const Attachments: React.FC<Props> = ({
  dealUuid,
  token,
  onChange,
  attachments = [],
  label,
  hideSave,
  uploadFiles,
  fileType,
}) => (
  <Dropzone<Attachment>
    label={label}
    files={attachments}
    onChange={onChange}
    handleUploadFile={files =>
      uploadFiles({
        files,
        dealUuid,
        token,
        fileType,
      })
    }
    fileComponent={({ file, index, handleRemoveFile }) => (
      <div className="mb-sm">
        <AttachmentItem
          key={file.fileUrl}
          attachment={file}
          index={index}
          handleRemoveAttachment={handleRemoveFile}
          hideSave={hideSave}
        />
      </div>
    )}
  />
);
