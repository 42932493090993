import { useLocation } from 'react-router-dom';

import { getUrlParam } from '@travauxlib/shared/src/utils/urls';

import { useCreateCompanyWithSiret } from 'api/useCreateCompanyWithSiret';

export const useHandleCreateCompanyWithSiret = (): ((payload: {
  siret: number;
}) => Promise<void>) => {
  const createCompanyWithSiret = useCreateCompanyWithSiret();
  const location = useLocation();

  const token = getUrlParam(location, 'token')!;
  const handleCreateCompanyWithSiret = async (values: { siret: number }): Promise<void> => {
    await createCompanyWithSiret({
      payload: { ...values, token },
    }).catch(error => {
      if (error?.response?.status === 409) {
        return { email: error.response.data };
      }

      return undefined;
    });
  };
  return handleCreateCompanyWithSiret;
};
