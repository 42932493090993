type Props = {
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  calendlyLink: string;
  calendlySource: string;
  calendlyCampaign?: string;
};

function normalizePhone(phoneNumber?: string): string | undefined {
  // we only handle french phone numbers at this point, so add +33
  if (!phoneNumber) {
    return;
  }
  return phoneNumber.startsWith('+') || phoneNumber.startsWith('33')
    ? phoneNumber
    : '+33'.concat(phoneNumber);
}

export const useOpenCalendly =
  (): ((props: Props) => void) =>
  (props: Props): void => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      calendlySource,
      calendlyLink,
      calendlyCampaign,
    } = props;

    try {
      Calendly.initPopupWidget({
        url: calendlyLink,
        prefill: {
          email,
          name: `${firstName} ${lastName}`,
          customAnswers: phoneNumber ? { a1: normalizePhone(phoneNumber) } : undefined,
        },
        utm: { utmSource: calendlySource, utmCampaign: calendlyCampaign },
      });
    } catch (error) {
      // if we can't display the Calendly popup, the user is redirected ton the Calendly page
      // Better not to lose him at this stage !
      if (error instanceof Error) {
        window.location.assign(calendlyLink);
      }
    }
  };
