import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { SuiviLot as SuiviLotType } from '@travauxlib/shared/src/types';
import { DevisLocation } from '@travauxlib/shared/src/types';
import { getCompletionFromLignes } from '@travauxlib/shared/src/utils/getCompletionFromLignes';

import { SuiviCard } from './SuiviCard';
import { SuiviLigne } from './SuiviLigne';

type Props = {
  lot: SuiviLotType;
  index: number;
  locations: DevisLocation[];
};

export const SuiviLot: React.FC<Props> = ({ lot, index, locations }) => {
  const completionLot = getCompletionFromLignes(lot.lignes);
  return (
    <div className="text-left px-md pt-md bg-white rounded mb-xs">
      <div className="flex flex-wrap items-center text-ds-b1">
        <div className="mr-md font-bold">{lot.label}</div>
        <div className="flex grow justify-end">
          <EURCurrency amount={completionLot.amount} />
          <span className="ml-sm whitespace-nowrap font-bold">{completionLot.percentage} %</span>
        </div>
      </div>
      <div className="pt-sm sm-desktop:hidden block">
        {lot.lignes.map((ligne, indexLigne) => (
          <SuiviCard
            indexLot={index}
            indexLigne={indexLigne}
            key={ligne.uuid}
            ligne={ligne}
            locations={locations}
          />
        ))}
      </div>
      <div className="pt-sm hidden sm-desktop:block px-md">
        {lot.lignes.map((ligne, indexLigne) => (
          <SuiviLigne
            indexLot={index}
            indexLigne={indexLigne}
            key={ligne.uuid}
            ligne={ligne}
            locations={locations}
          />
        ))}
      </div>
    </div>
  );
};
