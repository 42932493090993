import React from 'react';

import { Field } from 'react-final-form';

import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useUploadFiles } from 'features/Devis/api/useUploadFiles';
import { Attachments } from 'features/Devis/components/DevisForm/Attachments';

type Props = {
  onClose: () => void;
  dealUuid: string;
  devisToken: string;
  isArchitecte: boolean;
  dirtySinceLastSubmit: boolean;
  submitError?: string;
  handleSubmit: (e: React.FormEvent) => void;
};

export const Step1: React.FC<Props> = ({
  onClose,
  dealUuid,
  devisToken,
  isArchitecte,
  submitError,
  dirtySinceLastSubmit,
  handleSubmit,
}) => {
  const uploadFiles = useUploadFiles();

  return (
    <ModalContent
      cancelAction={{
        label: 'Annuler',
        onClick: onClose,
      }}
      validateAction={{
        type: 'submit',
        label: 'Suivant',
      }}
      handleSubmit={handleSubmit}
    >
      <Field
        name="attachments"
        render={({ input: { onChange, value } }) => (
          <>
            <Attachments
              label={`Ajouter ${devisOrProposition({
                isArchitecte,
                prefix: 'un',
              })} et ses pièces jointes`}
              hideSave
              dealUuid={dealUuid}
              token={devisToken}
              attachments={value}
              onChange={onChange}
              uploadFiles={uploadFiles}
              fileType="devisAttachment"
            />
          </>
        )}
      />
      {!dirtySinceLastSubmit && submitError && (
        <div className="!text-danger text-right">{submitError}</div>
      )}
    </ModalContent>
  );
};
