import { useEffect } from 'react';

import { Deal } from 'types';

import { useOpenClubProPaywall } from './useOpenClubProPaywall';

export const useClubProPaywall = ({
  deal,
  isAllowed,
}: {
  deal?: Deal;
  isAllowed: boolean;
}): void => {
  const openClubProPaywallModal = useOpenClubProPaywall(deal);

  useEffect(() => {
    if (!isAllowed) {
      openClubProPaywallModal();
    }
  }, [isAllowed]);
};
