import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { ClubProFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class PrestatairesTrackingClass extends Tracker<ClubProFeatures> {
  onPrestatairesAdded(): void {
    this.sendTracking({ event: TrackingEvents.Added });
  }
}

export const PrestatairesTracking = new PrestatairesTrackingClass(ClubProFeatures.Prestataires);
