import React, { useMemo } from 'react';

import _orderBy from 'lodash/orderBy';

import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { DevisFacturationStatus, FactureStatuses } from '@travauxlib/shared/src/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { StatusTagsFacture } from 'components/StatusTagsFacture';
import { FactureActions } from 'features/Deals/features/Factures/components/FactureActions';
import { Facture } from 'types';

import { useNavigateToFacture } from '../../hooks/useNavigateToFacture';
type Props = {
  facturesList: Facture[];
  facturationStatus?: DevisFacturationStatus;
};

export const FacturesList: React.FC<Props> = ({ facturesList, facturationStatus }) => {
  const sortedFactureList = _orderBy(facturesList, 'createdAt', 'asc');
  const navigateToFacture = useNavigateToFacture();
  const hasFactureToFactureBesideCloture = useMemo(
    () =>
      facturesList.some(({ status, isCloture }) => status === FactureStatuses.draft && !isCloture),
    [facturesList],
  );

  return (
    <div>
      <Table<Facture>
        className="mb-0 pb-0"
        columnConfigs={[
          {
            name: 'Numéro',
            columnKey: 'numero',
            renderValue: numero => <>{numero}</>,
          },
          {
            name: 'Titre',
            columnKey: 'title',
            renderValue: (title: string) => <div className="max-w-[300px] truncate">{title}</div>,
          },
          {
            name: 'Créer le',
            columnKey: 'createdAt',
            renderValue: (createdAt: string) => <>{formatFrenchDate(createdAt)}</>,
          },
          {
            name: 'Envoyée le',
            columnKey: 'dateEmission',
            renderValue: (dateEmission: string) => (
              <>{dateEmission ? formatFrenchDate(dateEmission) : ''}</>
            ),
          },
          {
            name: 'Total TTC',
            columnKey: 'prixTotalTTC',
            renderValue: (prixTotalTTC: number, facture) => (
              <div className="w-full text-right">
                {facture.isCloture && !facture.isSituation ? (
                  <span>
                    {!facturationStatus?.isClotured ? (
                      '- €'
                    ) : (
                      <EURCurrency amount={facturationStatus.factureSoldeTotals?.totalTTC} />
                    )}
                  </span>
                ) : (
                  <EURCurrency prefix={facture.isAvoir ? '- ' : ''} amount={prixTotalTTC} />
                )}
              </div>
            ),
          },
          {
            name: 'Statut',
            columnKey: 'status',
            renderValue: (_, facture: Facture) => (
              <div onClick={event => event.stopPropagation()}>
                <StatusTagsFacture
                  fixedFloating
                  facture={facture}
                  disabled={facture.status !== FactureStatuses.awaiting_payment}
                />
              </div>
            ),
          },
          {
            headless: true,
            hideSort: true,
            renderValue: (_, facture) => (
              <div
                className="flex items-center justify-center -my-md"
                onClick={event => event.stopPropagation()}
              >
                <FactureActions
                  hasFactureToFactureBesideCloture={hasFactureToFactureBesideCloture}
                  isFacturationClotured={!!facturationStatus?.isClotured}
                  facture={facture}
                  fixedFloating
                />
              </div>
            ),
          },
        ]}
        defaultSort={{ columnKey: 'isCloture', order: 'asc' }}
        items={sortedFactureList}
        onRowClick={facture => {
          navigateToFacture(facture);
        }}
        disableRow={facture => facture.isCloture && hasFactureToFactureBesideCloture}
      />
    </div>
  );
};
