import { useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { Contractor } from '@travauxlib/shared/src/types/';
import { request } from '@travauxlib/shared/src/utils/request';

import { PrestatairesTracking } from 'utils/tracking/PrestatairesTracking';

import { CONTRACTORS_KEY } from './useContractors';

const handleCreateContractor = (body: Omit<Contractor, 'uuid'>): Promise<void> => {
  const url = `${APP_CONFIG.proApiURL}/contractors`;
  return request(url, {
    method: 'POST',
    body,
  });
};

export const useCreateContractor = (): { createContractor: typeof handleCreateContractor } => {
  const queryClient = useQueryClient();

  const { mutateAsync: createContractor } = useMutation({
    mutationFn: handleCreateContractor,
    onSuccess: () => {
      PrestatairesTracking.onPrestatairesAdded();
      queryClient.invalidateQueries({ queryKey: [CONTRACTORS_KEY] });
    },
    onError: () => {
      toast.error("Le prestataire n'a pas pu être créé");
    },
  });

  return { createContractor };
};
