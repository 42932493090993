import { useOpenDemandeChantierFinishedModal } from './useOpenDemandeChantierFinishedModal';

import { useCreateDemandeChantier } from '../api/useCreateDemandeChantier';
import { DemandeChantierFormData } from '../types';

export const useHandleCreateDemandeChantier = (): ((
  values: DemandeChantierFormData,
) => Promise<void | { email: any }>) => {
  const createDemandeChantier = useCreateDemandeChantier();
  const openDemandeChantierFinishedModal = useOpenDemandeChantierFinishedModal();

  const handleCreateDemandeChantier = async (
    values: DemandeChantierFormData,
  ): Promise<void | { email: any }> => {
    try {
      await createDemandeChantier({
        ...values,
        conversionPage: document.referrer,
      });
      openDemandeChantierFinishedModal();
    } catch (error: any) {
      if (error?.response?.status === 409) {
        return { email: error.response.data };
      }
      return undefined;
    }
  };
  return handleCreateDemandeChantier;
};
