import React from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';

type Props = {
  isOpen: boolean;
  handleConfirm: () => void;
  closeModal: () => void;
};

export const DeleteFactureWarningMessage: React.FC<Props> = ({
  isOpen,
  handleConfirm,
  closeModal,
}) => (
  <FeedbackMessages
    variant="warning"
    title="Suppression d'une facture"
    message="Attention toute suppression d’une facture en brouillon est définitive"
    isOpen={isOpen}
    cancelAction={{
      label: 'Retour',
      onClick: closeModal,
    }}
    validateAction={{
      label: 'Valider',
      onClick: handleConfirm,
    }}
  />
);
