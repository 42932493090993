import React from 'react';

import GuideDuoTone from '@travauxlib/shared/src/components/DesignSystem/assets/GuideDuoTone.svg?react';
import { TagTS } from '@travauxlib/shared/src/features/SuiviChantier/components/TagTS';

import { LigneAvancement } from './LigneAvancement';

import { SuiviLot } from '../../types';
import { SuiviLotContainer } from '../SuiviLotContainer';

type Props = {
  suiviLots: SuiviLot[];
};

export const SuiviProByDevis: React.FC<Props> = ({ suiviLots }) => (
  <div className="flex flex-col sm-desktop:gap-lg gap-md mb-md sm-desktop:mb-3xl">
    {suiviLots.map(lot => {
      const isDevisEnPieceJointe = lot.label
        .toLowerCase()
        .includes('Devis en pièce jointe'.toLowerCase());

      return (
        <SuiviLotContainer
          key={lot.uuid}
          label={`Devis ${lot.devisNumber}`}
          Icon={() => <GuideDuoTone />}
          lignes={lot.lignes}
          validateActionOrTag={ligne => <TagTS ligne={ligne} />}
          ligneContent={ligne => (
            <LigneAvancement
              ligne={ligne}
              namePrefix={`${ligne.lotUuid}.lignes[${ligne.indexInLot}]`}
              withStep={!isDevisEnPieceJointe}
            />
          )}
          shouldDisplayChangedIndicators
        />
      );
    })}
  </div>
);
