import React from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Form } from 'react-final-form';

import { DateTimePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DateTimePicker/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useUpdateDateRDV } from 'features/Deals/api/useUpdateDateRDV';

type Props = {
  dealUuid: string;
  conversationUuid: string;
  onClose: () => void;
  meetingDate: Dayjs;
};

type FormValues = {
  dealUuid: string;
  meetingDate: Dayjs;
};

export const EditRdv: React.FC<Props> = ({ meetingDate, dealUuid, onClose }) => {
  const { updateDateRDV } = useUpdateDateRDV();

  return (
    <Form<FormValues>
      initialValues={{ dealUuid, meetingDate }}
      onSubmit={async ({ meetingDate }) => {
        await updateDateRDV({ meetingDate, dealUuid });
        onClose();
      }}
      initialValuesEqual={() => true}
    >
      {({ handleSubmit, submitting, hasValidationErrors }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Valider',
            type: 'submit',
            disabled: submitting || hasValidationErrors,
          }}
          cancelAction={{
            label: 'Annuler',
            disabled: submitting,
            type: 'button',
            onClick: onClose,
          }}
        >
          <div>
            <div className="!text-left">Date et heure du rendez-vous</div>
            <DateTimePickerField
              id="meetingDate"
              name="meetingDate"
              shouldBeDisabled={(date: Date) => dayjs(date).isBefore(dayjs(), 'day')}
              validate={value =>
                meetingDate.isSame(value) ? 'Veuillez choisir une date différente' : undefined
              }
            />
          </div>
        </ModalContent>
      )}
    </Form>
  );
};
