import React, { FC } from 'react';

import classNames from 'classnames';

import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { getInitials } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar/getInitials';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { UserProfile } from '@travauxlib/shared/src/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { Message, ProCompany } from 'types';

import { MessageAttachment } from '../MessageAttachmentList/MessageAttachment';

type MessageProps = {
  message: Message;
  profile: UserProfile<ProCompany>;
};

export const MessageComponent: FC<MessageProps> = ({ message, profile }) => {
  const isFromCurrentUser = message.fromUuid === profile?.uuid;
  const nameSender = isFromCurrentUser ? profile?.fullName : message.from;

  return (
    <div
      className={classNames('flex py-xmd text-ds-b2', {
        'flex-row-reverse': isFromCurrentUser,
      })}
      key={message.uuid}
    >
      {nameSender ? (
        <Avatar text={getInitials(nameSender)} />
      ) : (
        <img
          src={`${baseImgixUrl}/static/logo-hemea-pastille.png?w=30&h=30`}
          srcSet={`${baseImgixUrl}/static/logo-hemea-pastille.png?w=60&h=60 2x`}
          alt="Pastille hemea"
          width="37px"
          height="37px"
          className="mr-xs shrink-0"
        />
      )}
      <div className="flex flex-col rounded-md mx-sm p-md pr-xl border bg-primarylight overflow-auto">
        <div className="font-bold mb-md">{isFromCurrentUser ? 'Vous' : nameSender || 'hemea'}</div>
        <div className="mb-xs">
          <div className="flex flex-wrap mb-sm">
            {message.attachments.map(attachment => (
              <MessageAttachment
                filename={attachment.filename}
                url={attachment.url}
                key={attachment.uuid}
                className="bg-light p-sm mx-xxs mb-sm"
              />
            ))}
          </div>
          <MultilineParagraph text={message.content} />
        </div>
        <div className="text-right text-gray-600 text-ds-sm">
          {formatFrenchDate(message.createdAt)}
        </div>
      </div>
    </div>
  );
};
