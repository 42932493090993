import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { ProClient, ConsultationOffer } from 'types';

import { useProClients } from '../../ProClients/api/useProClients';

export const CONSULTATION_OFFERS_KEY = 'consultation-offers';

const useConsultationOffers = (): {
  consultationOffers: ConsultationOffer[];
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery<{ data: ConsultationOffer[] }>({
    queryKey: [CONSULTATION_OFFERS_KEY],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/consultation-offers`;
        return await request(requestURL);
      } catch (err) {
        toast.error("Les clients n'ont pas pu être récupérés");
        throw err;
      }
    },
  });
  return {
    consultationOffers: data?.data || [],
    isLoading,
  };
};

export const useProClientsAndConsultationOffers = (
  {
    search,
    showArchived,
  }: {
    search: string;
    showArchived: boolean;
  } = { search: '', showArchived: false },
):
  | undefined
  | {
      proClients: ProClient[];
      consultationOffers: ConsultationOffer[];
    } => {
  const { consultationOffers, isLoading: loadingConsultationOffers } = useConsultationOffers();
  const { proClients, isLoading: loadingProClient } = useProClients({ search, showArchived });

  if (loadingProClient || loadingConsultationOffers) {
    return undefined;
  }

  return {
    proClients,
    consultationOffers,
  };
};
