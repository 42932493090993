import { useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { LAST_FACTURES_KEY } from 'features/Deals/features/Factures/api/useLastFacture';
import { FACTURES_BY_PERIOD_KEY } from 'features/DevisFactures/api/useFacturesByPeriod';
import { Facture } from 'types';

import { FACTURES_KEY } from './useFacturesList';

const handlePayFacture = (facture: Facture): Promise<Facture> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${facture.dealUuid}/factures/${facture.token}/pay`;
  return request(requestURL, {
    method: 'PATCH',
  });
};

export const usePayFacture = (): typeof handlePayFacture => {
  const queryClient = useQueryClient();
  const { mutateAsync: payFacture } = useMutation({
    mutationFn: handlePayFacture,
    onSuccess: (facture: Facture) => {
      queryClient.invalidateQueries({ queryKey: [FACTURES_KEY, facture.dealUuid] });
      queryClient.invalidateQueries({ queryKey: [FACTURES_BY_PERIOD_KEY] });
      queryClient.invalidateQueries({ queryKey: [LAST_FACTURES_KEY] });
      toast.success('La facture a bien été payée');
    },
    onError: () => {
      toast.error("Le statut de la facture n'a pas pu être modifié");
    },
  });

  return payFacture;
};
