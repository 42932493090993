import React from 'react';

import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  showSecondarySidebar: boolean;
  isBelowLg: boolean;
  className?: string;
};

export const SecondaryProSideBarContainer: React.FC<Props> = ({
  children,
  isBelowLg,
  showSecondarySidebar,
  className,
}) => (
  <div
    className={classNames(
      'select-none w-[15.625rem] shadow-[2px_1px_12px_rgba(0,0,0,0.08)] bg-white transition-[left] duration-1000 ',
      {
        'left-full': showSecondarySidebar,
        '-left-[13.25rem]': !showSecondarySidebar,
        'h-[calc(100vh-3.75rem)]': isBelowLg,
      },
      className,
    )}
  >
    {children}
  </div>
);
