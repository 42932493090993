import { useMutation } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { RegistrationFormData } from 'features/Registration/types';

const handleCreateRegistration = (payload: RegistrationFormData): Promise<{ token: string }> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro-registrations`;

  return request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

export const useCreateRegistration = (): typeof handleCreateRegistration => {
  const { mutateAsync: createRegistration } = useMutation({ mutationFn: handleCreateRegistration });

  return createRegistration;
};
