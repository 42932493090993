import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { LAST_DEVIS_KEY } from 'features/Deals/features/Devis/api/useLastDevis';
import { Devis } from 'types';

import { DEVIS_KEY } from './useDevis';
import { DEVIS_LIST_KEY } from './useDevisList';

const handleUpdateStatus = ({
  dealUuid,
  token,
  status,
}: {
  dealUuid: string;
  token: string;
  status: Devis['status'];
}): Promise<Devis> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/devis/${token}/status?status=${status}`;
  return request(requestURL, {
    method: 'PATCH',
  });
};

export const useUpdateStatus = (): typeof handleUpdateStatus => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateStatus } = useMutation({
    mutationFn: handleUpdateStatus,
    onSuccess: (devis: Devis) => {
      queryClient.setQueryData([DEVIS_KEY, devis.dealUuid, devis.token], devis);
      queryClient.invalidateQueries({ queryKey: [DEVIS_LIST_KEY, devis.dealUuid] });
      queryClient.invalidateQueries({ queryKey: [LAST_DEVIS_KEY] });
      toast.success('Le statut du devis a bien été modifié');
    },
    onError: () => {
      toast.error("Le statut du devis n'a pas pu être modifié");
    },
  });
  return updateStatus;
};
