import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { OrExclusive } from '@travauxlib/shared/src/types/utils';

import { Deal } from 'types';

import { DealClientDetails } from '../components/ClientDetails/DealClientDetails';

// TODO CLEAN ME WHEN DEAL WILL OWN NO MORE CUSTOMER INFO (customerName, customerPhoneNumber, customerEmail)
type Props = OrExclusive<
  { deal: Deal },
  {
    initialValues: Pick<
      Deal,
      'customerName' | 'customerPhoneNumber' | 'customerEmail' | 'proClientUuid'
    >;
  }
>;

export const useOpenDealClientDetailsModal = (): ((props: Props) => void) => {
  const openModal = useOpenModal();

  return (props: Props) =>
    openModal(DealClientDetails, {
      ...props,
      title: 'Informations du chantier',
      closable: true,
      size: 'md',
    });
};
