import { useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { Contractor } from '@travauxlib/shared/src/types/';
import { request } from '@travauxlib/shared/src/utils/request';

import { CONTRACTORS_KEY } from './useContractors';

const handleUpdateContractor = (body: Contractor): Promise<void> => {
  const url = `${APP_CONFIG.proApiURL}/contractors/${body.uuid}`;
  return request(url, {
    method: 'PUT',
    body,
  });
};

export const useUpdateContractor = (): { updateContractor: typeof handleUpdateContractor } => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateContractor } = useMutation({
    mutationFn: handleUpdateContractor,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONTRACTORS_KEY] });
    },
    onError: () => {
      toast.error("Le prestataire n'a pas pu être mis à jour");
    },
  });

  return { updateContractor };
};
