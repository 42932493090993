import { useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request, isNotFound } from '@travauxlib/shared/src/utils/request';

import { DEVIS_LIST_KEY } from 'features/Devis/api/useDevisList';

const handleImportEstimations = async (
  dealUuid: string,
): Promise<{ dealUuid: string; nbEstimations: number }> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/devis/from-estimations`;
  const { nbEstimations } = await request(requestURL, {
    method: 'POST',
  });
  return { dealUuid, nbEstimations };
};

export const useImportEstimations = (): typeof handleImportEstimations => {
  const queryClient = useQueryClient();

  const { mutateAsync: importEstimations } = useMutation({
    mutationFn: handleImportEstimations,
    onSuccess: ({ dealUuid, nbEstimations }: { dealUuid: string; nbEstimations: number }) => {
      if (nbEstimations !== 0) {
        toast.success(`${nbEstimations} estimations ont été importées`);
        queryClient.invalidateQueries({ queryKey: [DEVIS_LIST_KEY, dealUuid] });
      } else {
        toast.warning("Pas d'estimations trouvées pour le client");
      }
    },
    onError: (err: Error) => {
      if (isNotFound(err)) {
        toast.error('Pas de client associé à ce deal');
      } else {
        toast.error("Les estimations n'ont pas pu être importées");
      }
    },
  });

  return importEstimations;
};
