import React from 'react';

import { Navigate, useLocation, useParams } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { ConfigurationStatus } from '@travauxlib/shared/src/features/Configurateur/types';

import { dashboardUrl, getDealConfigurationUrl, getDealRootUrl } from 'utils/urls';

import { useConsultationOffer } from './api/useConsultationOffer';
import { Descriptif } from './components/Descriptif';

export const ConsultationOffer: React.FC = () => {
  const { token } = useParams();
  const { search } = useLocation();
  const source = new URLSearchParams(search).get('source');
  const { consultationOffer, isLoading, isUpdating, reject, accept } = useConsultationOffer({
    token: token!,
    source,
  });

  if (isLoading || !consultationOffer) {
    return <LoaderWrapper />;
  }

  const isConsultationOfferRejected = consultationOffer.response && !consultationOffer.dealUuid;

  if (consultationOffer.response && consultationOffer.dealUuid) {
    const redirectUrl =
      consultationOffer.configuration?.status === ConfigurationStatus.Sent
        ? getDealConfigurationUrl(consultationOffer.dealUuid!)
        : getDealRootUrl(consultationOffer.dealUuid!);
    return <Navigate replace to={redirectUrl} />;
  }

  if (consultationOffer.isExpired || isConsultationOfferRejected) {
    return <Navigate replace to={dashboardUrl} />;
  }

  return (
    <div className="ml-0 lg-desktop:pl-[94px] lg-desktop:pr-[124px]">
      <div className="py-md">
        <Descriptif
          consultationOffer={consultationOffer}
          submitting={isUpdating}
          handleReject={values => reject({ ...values, token: consultationOffer.token })}
          handleAccept={rdvAt => accept({ rdvAt, token: consultationOffer.token })}
        />
      </div>
    </div>
  );
};
