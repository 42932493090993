import React, { useState } from 'react';

import MinusCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/MinusCircleOutline.svg?react';
import PlusCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircleOutline.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';

import { HistoryItem } from './types';

type Props = {
  item: HistoryItem;
  ignoreTransaction?: () => void;
};

export const IgnoreTransaction: React.FC<Props> = ({ item, ignoreTransaction }: Props) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  if (!ignoreTransaction) {
    return null;
  }
  return (
    <>
      <IconButton onClick={() => setIsOpenDelete(true)} size="xs">
        {item.isIgnored ? <PlusCircleOutline /> : <MinusCircleOutline />}
      </IconButton>
      <FeedbackMessages
        variant="warning"
        validateAction={{
          label: `${item.isIgnored ? 'Prendre en compte' : 'Ignorer'} la transaction ?`,
          onClick: () => {
            ignoreTransaction();
            setIsOpenDelete(false);
          },
        }}
        isOpen={isOpenDelete}
        title={`${item.isIgnored ? 'Prendre en compte' : 'Ignorer'} la transaction ?`}
        message={`En confirmant, la transaction sera ${
          item.isIgnored ? 'prise en compte' : 'ignorée'
        } dans l'historique des mouvements sur ce compte séquestre. Cette action ne doit être appliquée que sur les mouvements inhabituels sur le compte !`}
        handleClose={() => setIsOpenDelete(false)}
      />
    </>
  );
};
