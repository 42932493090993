import { useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Facture } from 'types';

import { FACTURES_KEY } from './useFacturesList';

const handleDeleteFacture = ({ dealUuid, token }: Facture): Promise<Facture> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/factures/${token}`;
  return request(requestURL, {
    method: 'DELETE',
    params: {
      isArchive: false,
    },
  });
};

export const useDeleteFacture = (): {
  deleteFacture: typeof handleDeleteFacture;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteFacture } = useMutation({
    mutationFn: handleDeleteFacture,
    onSuccess: (facture: Facture) => {
      queryClient.invalidateQueries({ queryKey: [FACTURES_KEY, facture.dealUuid] });
      toast.success('La facture a bien été supprimée');
    },
    onError: () => {
      toast.error("La facture n'a pas pu être supprimée");
    },
  });

  return { deleteFacture };
};
