import React, { useEffect } from 'react';

import { Form } from 'react-final-form';

import { ReassuranceColumn } from 'components/ReassuranceColumn';
import { reassuranceColumnConfig } from 'features/Registration/utils';
import { sendEventToTrackingModules } from 'utils/sendEventToTrackingModules';

import { FormBody } from './FormBody';
import { FormFooter } from './FormFooter';
import { FormHeader } from './FormHeader';

import { useHandleCreateRegistration } from '../../hooks/useHandleCreateRegistration';
import { RegistrationFormData } from '../../types';

export const AccountStep: React.FC = () => {
  const handleCreateRegistration = useHandleCreateRegistration();

  useEffect(() => {
    sendEventToTrackingModules({ event: 'pro.inscription.started' });
  }, []);

  return (
    <Form<RegistrationFormData>
      onSubmit={values => {
        handleCreateRegistration({
          ...values,
          phoneNumber: values.phoneNumberObj.number!,
        });
        sendEventToTrackingModules({ event: 'pro.inscription.finished' });
      }}
      initialValues={{ optin: false }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="!h-full !min-h-screen">
          <div className="flex -mx-md h-full min-h-screen">
            <ReassuranceColumn config={reassuranceColumnConfig} />
            <div className="sm-desktop:p-md bg-white w-full sm-desktop:right-0 sm-desktop:min-w-7/12 sm-desktop:pl-[6.25rem] sm-desktop:pr-[9rem] sm-desktop:pt-xxl">
              <FormHeader />
              <FormBody />
              <FormFooter />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
