import { useNavigate } from 'react-router-dom';

import { Facture } from 'types';
import { getFactureUrl } from 'utils/urls';

type HandleClickItem = (facture: Facture) => void | Window | null;

export const useNavigateToFacture = (): HandleClickItem => {
  const navigate = useNavigate();

  const handleClickItem = (facture: Facture): void | Window | null => {
    const link = getFactureUrl(facture);
    const externalLink = facture.status !== 'draft' || facture.archived;
    return externalLink ? window.open(link) : navigate(link);
  };

  return handleClickItem;
};
