import React from 'react';

import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useIsAccountBlocked, useProCompany } from 'api/profileSelectors';
import { useResendConfirmationEmail } from 'api/useResendConfirmationEmail';

export const BlockedAccountModal: React.FC = () => {
  const isAccountBlocked = useIsAccountBlocked();
  const { resendConfirmationEmail, isLoading } = useResendConfirmationEmail();
  const company = useProCompany();

  if (!isAccountBlocked) {
    return null;
  }

  return (
    <SimpleModal
      isOpen
      size="md"
      validateAction={{
        label: "Renvoyer l'email",
        onClick: () => resendConfirmationEmail({ userEmail: company?.email }).catch(() => {}),
        disabled: isLoading,
        loading: isLoading,
      }}
    >
      <div className="text-center">
        <div className="bg-lighter inline-flex items-center rounded mb-xl py-md px-xl">
          <CrossCircle className="w-lg h-lg text-danger" />
        </div>
        <div className="text-center">
          <h3 className="mb-sm">Votre compte est bloqué car votre email n'a pas été vérifié.</h3>
          Vous n'avez pas reçu le mail ?
        </div>
      </div>
    </SimpleModal>
  );
};
