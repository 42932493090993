import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { BonCommande } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { BON_COMMANDE_LIST_KEY } from 'features/Deals/api/useGetBonCommandeList';

const handleUnarchive = (bonCommande: BonCommande): Promise<BonCommande> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${bonCommande.dealUuid}/bon-commande/${bonCommande.token}/unarchive`;
  return request(requestURL, {
    method: 'PUT',
  });
};

export const useUnarchiveBonCommande = (): typeof handleUnarchive => {
  const queryClient = useQueryClient();

  const { mutateAsync: unarchive } = useMutation({
    mutationFn: handleUnarchive,
    onSuccess: (bonCommande: BonCommande) => {
      toast.success('Le bon de commande a bien été désarchivé');
      queryClient.invalidateQueries({ queryKey: [BON_COMMANDE_LIST_KEY, bonCommande.dealUuid] });
    },
    onError: () => {
      toast.error("Le bon commande n'a pas pu être désarchivé");
    },
  });
  return unarchive;
};
