import { ProCompany } from 'types';
import { shouldNavigateToCharte } from 'utils/shouldNavigateToCharte';

import { Ability } from './Ability';

export class LegacyPremium extends Ability {
  defineAbility(company?: ProCompany, isHemeaEmployee?: boolean): void {
    const { can, cannot } = this.abilityBuilder;

    can('manage', 'all');

    if (shouldNavigateToCharte(this.isLabellisee(company?.status), company, isHemeaEmployee)) {
      cannot('manage', 'Deal', { isHemea: true });
    }

    if (!this.isLabellisee(company?.status) && !this.isLabellisationEnCours(company?.status)) {
      cannot('manage', 'Labellisation');
    }
  }
}
