import React from 'react';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';

export const IncorrectBudgetReason = 'Budget client non adapté';

export const IncorrectBudget: React.FC = () => (
  <div className="!py-sm">
    <InputField
      id="estimatedBudget"
      name="estimatedBudget"
      maxLength={255}
      label="Quel est le budget selon vous pour ce chantier ?"
    />
  </div>
);
