import { useQuery } from '@tanstack/react-query';

import { useFuseWorker } from '@travauxlib/shared/src/hooks/useFuseWorker';
import { Contractor } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

const searchOptions = {
  shouldSort: true,
  threshold: 0.3,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    {
      name: 'name',
      weight: 0.7,
    },
    {
      name: 'billingAddress.address',
      weight: 0.1,
    },
    {
      name: 'billingAddress.postalCode',
      weight: 0.1,
    },
    {
      name: 'billingAddress.city',
      weight: 0.1,
    },
  ],
};

export const CONTRACTORS_KEY = 'contractors';

export const useContractors = (
  search: string = '',
): { contractors: Contractor[]; isLoading: boolean } => {
  const { data: contractors = [], isLoading } = useQuery<Contractor[]>({
    queryKey: [CONTRACTORS_KEY],
    queryFn: async () => {
      const url = `${APP_CONFIG.proApiURL}/contractors`;
      const { data } = await request(url);
      return data;
    },
  });

  const searchResults = useFuseWorker<Contractor>(contractors, searchOptions, search);

  return {
    contractors: search ? searchResults : contractors,
    isLoading,
  };
};
