import React from 'react';

import { useForm, useFormState } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';

import { RejectionPayload } from 'features/ConsultationOffer/api/useConsultationOffer';

import { IncorrectBudget, IncorrectBudgetReason } from './IncorrectBudget';
import { NotAvailableReason, NotAvailableComponent } from './NotAvailable';

export type Props = {
  handleReject: (payload: RejectionPayload) => Promise<void>;
};

const options = [
  NotAvailableReason,
  'Trop loin',
  'Trop petit chantier',
  'Je ne sais pas faire',
  IncorrectBudgetReason,
  'Je ne peux pas me positionner',
].map(value => ({
  value,
  label: value,
}));

const multiStepsReasons = [NotAvailableReason, IncorrectBudgetReason];

export const RejectReasons: React.FC<Props> = ({ handleReject }) => {
  const {
    values: { rejectionReason },
  } = useFormState();
  const form = useForm();
  if (![NotAvailableReason, IncorrectBudgetReason].includes(rejectionReason)) {
    return (
      <div className="!flex !flex-col">
        {options.map(option => (
          <Button
            fullwidth
            variant="secondary"
            className="!mb-xxs"
            key={option.value}
            type="button"
            onClick={() =>
              multiStepsReasons.includes(option.value)
                ? form.change('rejectionReason', option.value)
                : handleReject({ rejectionReason: option.value })
            }
          >
            {option.label}
          </Button>
        ))}
        <InputField
          name="rejectionReason"
          id="other-reason"
          label="Autre raison"
          placeholder="Précisez la raison de votre refus"
        />
      </div>
    );
  }

  return (
    <>
      <Button variant="secondary" type="button" className="!mb-md" disabled>
        {rejectionReason}
      </Button>

      {rejectionReason === NotAvailableReason ? <NotAvailableComponent /> : <IncorrectBudget />}
    </>
  );
};
