import React from 'react';

import { ProgressBar } from './ProgressBar';

import { Chantier } from '../../types';

type Props = {
  chantier: Chantier;
  onAddPaiementClick?: () => void;
};

export const CompteSequestreProgressBars: React.FC<Props> = ({ chantier, onAddPaiementClick }) => (
  <>
    <div className="!mb-xl">
      <ProgressBar
        title="Provisions"
        progress={chantier.totalProvisions}
        total={chantier.montantTotalTTC}
        tooltips={['Reçues', 'À recevoir']}
        bg="!bg-success"
      />
    </div>
    <div className="!mb-4xl">
      <ProgressBar
        title="Paiements"
        addBtnOnClick={onAddPaiementClick}
        progress={chantier.totalPaiements}
        total={chantier.montantTotalTTC}
        tooltips={['Effectués', 'À effectuer']}
        bg="!bg-info"
      />
    </div>
  </>
);
