import React, { useState } from 'react';

import _sum from 'lodash/sum';

import { AccountCard } from '@travauxlib/shared/src/features/Chantiers/components/AccountCard';
import { LiberationDesFondsHistoryModal } from '@travauxlib/shared/src/features/Chantiers/components/AccountCard/LiberationDesFondsHistoryModal';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

type Props = {
  chantier: Chantier;
};

export const ProAccountCard: React.FC<Props> = ({ chantier }) => {
  const [isLiberationHistoryModalOpen, setIsLiberationHistoryModalOpen] = useState(false);

  const verse = _sum(chantier.liberationsDeFonds.map(l => l.paidToCompany));

  return (
    <>
      <AccountCard
        title="Mon compte bancaire"
        details={[
          {
            supLabel: 'Versé',
            amount: verse,
            subSuffix: 'Historique',
            subOnClick: () => setIsLiberationHistoryModalOpen(true),
          },
        ]}
        status="neutral"
        showAmounts
      />
      {isLiberationHistoryModalOpen && (
        <LiberationDesFondsHistoryModal
          chantier={chantier}
          paidToCompanyColumnLabel="Versé"
          showAuthor={false}
          onClose={() => setIsLiberationHistoryModalOpen(false)}
        />
      )}
    </>
  );
};
