import React, { FC } from 'react';

import classNames from 'classnames';

import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';

type AddParticipantButtonProps = {
  onClick: () => void;
  withDetail: boolean;
};

export const AddParticipantButton: FC<AddParticipantButtonProps> = ({ onClick, withDetail }) => (
  <div
    key="addParticipant"
    role="button"
    onClick={onClick}
    onKeyPress={onClick}
    tabIndex={0}
    className={classNames('p-0 bg-transparent !relative !cursor-pointer', {
      '!flex !py-xs !mr-sm !items-center !font-bold': withDetail,
    })}
    data-testid="new"
  >
    <div className="text-ds-b1 !mb-0 !text-white text-uppercase !font-bold !rounded-full !inline-flex !justify-center !items-center !mr-sm !bg-primary w-xxl h-xxl">
      <PlusSymbol className="w-xl h-xl" />
    </div>
    {withDetail && 'Ajouter un participant'}
  </div>
);
