import {
  CountryCode,
  formatIncompletePhoneNumber,
  formatNumber,
  isSupportedCountry,
  isValidPhoneNumber as isValidPhoneNumberFromLib,
} from 'libphonenumber-js';

export const formatInternationalPhoneNumber = (countryCode: CountryCode, value: string): string =>
  formatNumber(value, countryCode, 'INTERNATIONAL', { humanReadable: false });

export const isValidPhoneNumber = (countryCode: CountryCode, value: string): boolean =>
  isValidPhoneNumberFromLib(value, countryCode);

export const formatPhoneNumber = (countryCode?: CountryCode, value?: string): string | undefined =>
  countryCode && isSupportedCountry(countryCode) && value
    ? formatIncompletePhoneNumber(value, countryCode)
    : value;
