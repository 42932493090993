import React from 'react';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';

import { useDevis } from 'features/Devis/api/useDevis';

type Props = {
  replacedSignedDevisToken: string;
  dealUuid: string;
  className?: string;
};

export const WarnForReplacementTooltip: React.FC<Props> = ({
  replacedSignedDevisToken,
  dealUuid,
  className,
}) => {
  const { devis: replacedDevis } = useDevis({
    token: replacedSignedDevisToken,
    dealUuid: dealUuid,
  });

  return replacedDevis ? (
    <WithTooltip
      trigger={
        <span className={className}>
          <Tag size="md" variant="info" label="Remplacement" />
        </span>
      }
      width="22rem"
    >
      <div className="!p-xs">
        La signature du présent devis entraînera l'annulation de l'intégralité du devis N°
        {replacedDevis.numero}
      </div>
    </WithTooltip>
  ) : null;
};
