import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { TvaPicker as RawTvaPickerChips } from './TvaPickerChips';

import { TvaPicker as RawTvaPicker, Props as TvaPickerProps } from '.';

type OwnProps = {
  defaultProp: string;
};

type TvaPickerWrapperProps = FieldRenderProps<number> & OwnProps;

const TvaPickerWrapper: React.FC<TvaPickerWrapperProps> = ({
  input: { value, onChange },
  meta: { touched, error },
  label,
}) => {
  const shouldDisplayError = touched && error;

  return (
    <>
      <RawTvaPicker onChange={onChange} selectedTauxTVA={value} label={label} />
      {shouldDisplayError && <div className="text-danger text-ds-sm !block">{error}</div>}
    </>
  );
};

const TvaPickerChipsWrapper: React.FC<TvaPickerWrapperProps> = ({
  input: { value, onChange },
  meta: { touched, error },
  label,
}) => {
  const shouldDisplayError = touched && error;

  return (
    <>
      <RawTvaPickerChips onChange={onChange} selectedTauxTVA={value} label={label} />
      {shouldDisplayError && <div className="text-danger text-ds-sm block">{error}</div>}
    </>
  );
};

type Props = {
  name: string;
} & Omit<TvaPickerProps, 'value' | 'onChange'> &
  UseFieldConfig<number>;

export const TvaPickerField: React.FC<Props> = props => (
  <Field component={TvaPickerWrapper} {...props} />
);

export const TvaPickerChipsField: React.FC<Props> = props => (
  <Field component={TvaPickerChipsWrapper} {...props} />
);
