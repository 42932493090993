import React from 'react';

import { Form } from 'react-final-form';

import DocumentOutline from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentOutline.svg?react';
import EcoOutline from '@travauxlib/shared/src/components/DesignSystem/assets/EcoOutline.svg?react';
import RequestQuoteOutline from '@travauxlib/shared/src/components/DesignSystem/assets/RequestQuoteOutline.svg?react';
import Upload from '@travauxlib/shared/src/components/DesignSystem/assets/Upload.svg?react';
import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { ChipPictoVerticalListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { required, validateConditions } from '@travauxlib/shared/src/utils/form';
import { ModeCalculCommission } from '@travauxlib/shared/src/utils/modeCalculCommission';

import { useProCompany } from 'api/profileSelectors';
import { useOpenCreateDevisRGEModal } from 'features/Deals/hooks/useOpenCreateDevisRGEModal';
import { isDevisTemplate } from 'features/Deals/utils/devis';
import { useCreateDevis } from 'features/Devis/api/useCreateDevis';
import { useUploadDevisModal } from 'features/Devis/hooks/useUploadDevisModal';
import { usePermissions } from 'hooks/usePermissions';
import { Devis, DevisTemplate } from 'types';
import { getDevisUrl } from 'utils/urls';

import { useGetDevisFromDevisTemplate } from '../api/useGetDevisFromDevisTemplate';
import { useOpenCreateDevisFromStartModal } from '../hooks/useOpenCreateDevisFromStartModal';

type Props = {
  dealUuid: string;
  projectUuid?: string;
  isArchitecte?: boolean;
  handleClose: () => void;
  devisTemplatesItems: DevisTemplate[];
  devisUserTemplatesItems: Devis[];
  devisNumeros: string[];
  isDealFromHemea?: boolean;
};

type SelectCreationDevisTypenFormData = {
  devisTypeSelected: 'scratch' | 'template' | 'uploaded' | 'rge';
  devisOrDevisTemplate: Devis | DevisTemplate;
  confirmIncreasedCommission: boolean;
};

export const CreateDevisModalContent: React.FC<Props> = ({
  handleClose,
  dealUuid,
  projectUuid,
  isArchitecte,
  devisTemplatesItems,
  devisUserTemplatesItems,
  devisNumeros,
  isDealFromHemea,
}) => {
  const profile = useProCompany();
  const uploadModal = useUploadDevisModal({ dealUuid, projectUuid });
  const openCreateDevisFromStartModal = useOpenCreateDevisFromStartModal();
  const getDevisFromDevisTemplate = useGetDevisFromDevisTemplate();
  const { createDevis } = useCreateDevis({ redirectFc: getDevisUrl });
  const openCreateDevisRGEModal = useOpenCreateDevisRGEModal();
  const { useProtectedCall } = usePermissions();
  const [protectedOpenDevisRGE] = useProtectedCall(
    () => {
      openCreateDevisRGEModal({
        isArchitecte,
        dealUuid,
        devisNumeros,
        projectUuid,
      });
    },
    'manage',
    'DevisRGE',
  );
  const hasTemplateDevis = devisTemplatesItems.length + devisUserTemplatesItems.length > 0;

  const shouldUse18DegressiveCommission =
    isDealFromHemea &&
    profile.modeCalculCommission === ModeCalculCommission.commission_18_degressive;

  const options = [
    {
      centerIcon: <DocumentOutline />,
      value: 'scratch',
      label: 'Devis vierge',
    },
    {
      centerIcon: <RequestQuoteOutline />,
      value: 'template',
      label: 'Modèle',
    },
    {
      centerIcon: <Upload />,
      value: 'uploaded',
      label: 'Devis importé',
    },
    {
      centerIcon: <EcoOutline />,
      value: 'rge',
      label: 'Devis RGE',
    },
  ].filter(
    option => option.value !== 'template' || (option.value === 'template' && hasTemplateDevis),
  );

  return (
    <Form<SelectCreationDevisTypenFormData>
      onSubmit={async ({ devisOrDevisTemplate, devisTypeSelected }) => {
        if (devisTypeSelected === 'scratch') {
          openCreateDevisFromStartModal({
            isArchitecte,
            dealUuid,
            devisNumeros,
            projectUuid,
          });
        } else if (devisTypeSelected === 'template' && devisOrDevisTemplate) {
          const newDevis = isDevisTemplate(devisOrDevisTemplate)
            ? await getDevisFromDevisTemplate(devisOrDevisTemplate)
            : devisOrDevisTemplate;

          createDevis({
            devis: {
              ...newDevis,
              projectTitle: newDevis.title,
              projectUuid,
            },
            dealUuid,
          });
          handleClose();
        } else if (devisTypeSelected === 'rge') {
          protectedOpenDevisRGE();
        } else {
          uploadModal.handleOpenModal();
        }
      }}
      initialValues={{ devisTypeSelected: 'scratch' }}
    >
      {({ handleSubmit, submitting, values: { devisTypeSelected } }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Valider',
            type: 'submit',
            disabled: submitting,
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: handleClose,
          }}
        >
          <ChipPictoVerticalListField
            id="devis-type-selected"
            name="devisTypeSelected"
            options={options}
            size="sm"
            className="flex gap-md"
          />
          {devisTypeSelected === 'uploaded' && shouldUse18DegressiveCommission && (
            <>
              <AlertMessage
                className="my-md"
                level="warning"
                title="La non-utilisation du logiciel de devis entraîne une hausse de la commission hemea à 18%."
              />
              <CheckboxField
                name="confirmIncreasedCommission"
                validate={validateConditions}
                label="Je confirme avoir pris connaissance de la hausse de la commission"
              />
            </>
          )}
          {devisTypeSelected === 'rge' && (
            <AlertMessage
              className="my-md"
              level="info"
              title="Ce devis est éligible pour les travaux de rénovation énergétique et répond aux exigences de MaPrimeRenov' et CEE pour l'obtention d'aides."
            />
          )}
          {devisTypeSelected === 'template' && (
            <div className="w-full mt-md">
              <DropdownField
                id="devisOrDevisTemplate"
                name="devisOrDevisTemplate"
                label="Sélectionner un modèle"
                options={[...devisUserTemplatesItems, ...devisTemplatesItems].map(template => ({
                  value: template,
                  label: template.title,
                }))}
                disableInput
                validate={required}
              />
            </div>
          )}
        </ModalContent>
      )}
    </Form>
  );
};
