import { useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Facture } from 'types';

import { FACTURES_KEY } from './useFacturesList';

type AcomptePourcentage = {
  typeAcompte: 'Pourcentage';
  pourcentageFacture: number;
};

type AcompteMontant = {
  typeAcompte: 'Montant';
  montantFacture: number;
};

type FactureAcompteInfo = {
  proDevisToken: string;
  title?: string;
} & (AcomptePourcentage | AcompteMontant);

type CreateAcomptePayload = {
  dealUuid: string;
  factureAcompteInfo: FactureAcompteInfo;
};

const handleCreateAcompte = ({
  dealUuid,
  factureAcompteInfo,
}: CreateAcomptePayload): Promise<Facture> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/factures/acompte`;
  return request(requestURL, {
    method: 'POST',
    body: factureAcompteInfo,
  });
};

export const useCreateAcompte = (): typeof handleCreateAcompte => {
  const queryClient = useQueryClient();
  const { mutateAsync: createAcompte } = useMutation({
    mutationFn: handleCreateAcompte,
    onSuccess: (facture: Facture) => {
      queryClient.invalidateQueries({ queryKey: [FACTURES_KEY, facture.dealUuid] });
    },
    onError: () => {
      toast.error("La facture n'a pas pu être créée");
    },
  });

  return createAcompte;
};
