import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { Deal } from 'types';

import { DEAL_KEY } from './useDeal';

const handleUpdateTypePrestationArchi = ({
  dealUuid,
  typePrestationArchi,
}: {
  dealUuid: string;
  typePrestationArchi: string;
}): Promise<Deal> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/update-type-prestation-archi`;
  return request(requestURL, {
    method: 'PATCH',
    body: JSON.stringify({
      typePrestationArchi,
    }),
  });
};

export const useUpdateTypePrestationArchi = (): {
  updateTypePrestationArchi: typeof handleUpdateTypePrestationArchi;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateTypePrestationArchi, isPending } = useMutation({
    mutationFn: handleUpdateTypePrestationArchi,
    onSuccess: (deal: Deal) => {
      queryClient.setQueryData([DEAL_KEY, deal.uuid], deal);
    },
  });
  return { updateTypePrestationArchi, isLoading: isPending };
};
