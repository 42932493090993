import React from 'react';

import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links/Link';

export const CGUBlock: React.FC = () => (
  <div className="text-ds-sm text-neutral-600 px-md sm-desktop:px-0 py-lg sm-desktop:py-0 bg-neutral-100 sm-desktop:bg-white">
    Les données que vous nous fournissez sont traitées par les services clients et marketing de
    hemea aux fins de :<br /> - Nous adresser vos demandes, accéder à nos ressources en ligne,
    utiliser notre logiciel. <br /> - Nous permettre de vous envoyer des offres commerciales et
    marketing. <br /> Pour en savoir plus, consultez notre{' '}
    <Link
      href={`${APP_CONFIG.wwwURL}/fr/cgu`}
      rel="noopener noreferrer"
      target="_blank"
      className="!text-ds-sm !text-neutral-800"
      inline
    >
      Politique de confidentialité
    </Link>
    .
  </div>
);
