import React from 'react';

import classNames from 'classnames';
import { Field, useFormState } from 'react-final-form';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import {
  required,
  requiredValidGoogleMapAddress,
  validateGoogleMapAddress,
} from '@travauxlib/shared/src/utils/form';

type Props = {
  formattedAddressFieldName: string;
  googleMapsFieldName: string;
  addressComplementFieldName: string;
  disabledFieldName: string;
  legacyFieldName: string;
  legacyValue?: {
    address?: string;
    postalCode?: string;
    city?: string;
  };
  label: string;
  isRequired?: boolean;
};

type AddressLabelProps = {
  fieldName: string;
  switchModeLabel: string;
};

const AddressLabel: React.FC<AddressLabelProps> = ({ fieldName, switchModeLabel }) => (
  <div
    className={classNames('text-right', {
      'mb-xs': switchModeLabel === 'Saisie automatique',
    })}
  >
    <Field name={fieldName}>
      {({ input: { onChange, value } }) => (
        <button
          onClick={() => {
            onChange(!value);
          }}
          type="button"
          className="bg-transparent text-primary hover:underline !p-0"
        >
          <small>{switchModeLabel}</small>
        </button>
      )}
    </Field>
  </div>
);

export const AddressField: React.FC<Props> = ({
  formattedAddressFieldName,
  googleMapsFieldName,
  addressComplementFieldName,
  legacyFieldName,
  disabledFieldName,
  label,
  isRequired,
}) => {
  const { [formattedAddressFieldName]: formattedAddress, [disabledFieldName]: disabledGoogleMaps } =
    useFormState().values;

  if (disabledGoogleMaps) {
    return (
      <>
        <TextareaField
          label="Adresse"
          className="!w-full"
          id="manual-address"
          data-testid="manual-address"
          name={`${legacyFieldName}.address`}
          validate={value => isRequired && required(value)}
        />
        <AddressLabel fieldName={disabledFieldName} switchModeLabel="Saisie automatique" />
        <div className="mb-md">
          <InputField
            id={`${legacyFieldName}-postal-code`}
            label="Code postal"
            name={`${legacyFieldName}.postalCode`}
            validate={value => isRequired && required(value)}
          />
        </div>
        <InputField
          id={`${legacyFieldName}-city`}
          label="Ville"
          name={`${legacyFieldName}.city`}
          validate={value => isRequired && required(value)}
        />
      </>
    );
  }
  return (
    <div className="grid grid-cols-12 gap-md">
      <div className="col-span-full sm-desktop:col-span-6">
        <PlacesAutocompleteField
          data-testid="automatic-address"
          id="automatic-address"
          label={label}
          initialInputValue={formattedAddress}
          placeholder="53 boulevard de Strasbourg, 75010 Paris"
          name={googleMapsFieldName}
          googleApiKey={APP_CONFIG.googleApiKey}
          validate={value =>
            isRequired
              ? requiredValidGoogleMapAddress('postal_code')(value)
              : validateGoogleMapAddress('postal_code')(value)
          }
        />
        <AddressLabel fieldName={disabledFieldName} switchModeLabel="Saisie manuelle" />
      </div>
      <TextareaField
        className="!w-full mb-md col-span-full sm-desktop:col-span-6"
        id="complement"
        label="Complément d'adresse"
        placeholder="Etage, code, bâtiment..."
        name={addressComplementFieldName}
      />
    </div>
  );
};
