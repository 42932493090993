import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { ClubProFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class DemandeChantierTrackingClass extends Tracker<ClubProFeatures> {
  onDemandeChantierViewed(): void {
    this.sendTracking({ event: TrackingEvents.Viewed });
  }

  onDemandeChantierCompleted(properties: {
    'Prenom entrepreneur'?: string;
    'Nom entrepreneur'?: string;
    'Email entrepreneur'?: string;
    'Numero entrepreneur'?: string;
  }): void {
    this.sendTracking({
      event: TrackingEvents.Completed,
      properties,
    });
  }
}

export const DemandeChantierTracking = new DemandeChantierTrackingClass(
  ClubProFeatures.DemandeChantier,
);
