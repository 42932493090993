import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { loadable } from '@travauxlib/shared/src/utils/loadable';

import { DevisTS } from 'features/DevisTS/Loadable';

import { Devis as DevisComponent } from './components/Devis/Loadable';
import { Export } from './components/Export/Loadable';
import { SendDevisEmail } from './components/SendDevisEmail/Loadable';

export const { DevisComponent: Index } = loadable(() => import('./index'), {
  fallback: <LoaderWrapper isFullHeightScreen />,
});

export const Devis = {
  Index,
  SendDevisEmail,
  Export,
  DevisTS,
  Devis: DevisComponent,
};
