import React from 'react';

import classNames from 'classnames';
import { Form } from 'react-final-form';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { required, validateEmail, requiredValidEmail } from '@travauxlib/shared/src/utils/form';

import { ProClientAndChantierAddress } from 'types';

import { AddressField } from './AddressField';

import { useHandleSubmitProClient } from '../../hooks/useHandleClientDetails';

export type Props = {
  proClient?: ProClientAndChantierAddress;
  handleClose: () => void;
};

type FormValues = ProClientAndChantierAddress & {
  dealName: string;
  disabledChantierGoogleMaps?: boolean;
  disabledBillingGoogleMaps?: boolean;
  sameBillingAndChantierAddress: boolean;
  sendEmail?: boolean;
};

const ModalProClientDetailsComponent: React.FC<Props> = ({ proClient, handleClose }) => {
  const handleSubmitProClient = useHandleSubmitProClient();
  const isNewClient = !proClient?.uuid;

  return (
    <Form<FormValues>
      onSubmit={async values => {
        await handleSubmitProClient({
          ...values,
          chantierGoogleMapsAddress: values.disabledChantierGoogleMaps
            ? undefined
            : values.chantierGoogleMapsAddress,
          billingGoogleMapsAddress: values.disabledBillingGoogleMaps
            ? undefined
            : values.billingGoogleMapsAddress,
        });

        handleClose();
      }}
      validate={values => {
        if (values.sendEmail) {
          return {
            email: requiredValidEmail(values.email),
          };
        }
        return {};
      }}
      initialValues={{
        ...proClient,
        sameBillingAndChantierAddress: isNewClient,
        sendEmail: false,
        disabledBillingGoogleMaps: !isNewClient && !proClient.billingGoogleMapsAddress,
      }}
    >
      {({ handleSubmit, values }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: isNewClient ? 'Créer un nouveau client' : 'Valider',
            type: 'submit',
          }}
          cancelAction={{ label: 'Annuler', onClick: handleClose }}
        >
          <div className="mb-md grid grid-cols-12 gap-md">
            {isNewClient && (
              <div className="col-span-full sm-desktop:col-span-6">
                <InputField
                  label="Titre du chantier"
                  placeholder="Exemple : Rénovation cuisine"
                  id="deal-title"
                  data-testid="deal-title"
                  name="dealTitle"
                  validate={required}
                />
              </div>
            )}
            <div
              className={classNames({
                'col-span-full sm-desktop:col-span-6': isNewClient,
                'col-span-full': !isNewClient,
              })}
            >
              <InputField
                autoFocus
                label="Nom du client"
                placeholder="Exemple : Jean Dupont ou SARL Dupuis"
                id="client-name"
                data-testid="client-name"
                name="name"
                validate={required}
              />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField
                id="client-email"
                label="Adresse e-mail"
                placeholder="Exemple : jean.dupont@mail.com"
                name="email"
                type="email"
                validate={validateEmail}
              />
            </div>

            <div className="col-span-full sm-desktop:col-span-6">
              <InputField
                id="client-phone-number"
                label="Numéro de téléphone"
                placeholder="Exemple : 06 12 34 56 78"
                name="phoneNumber"
              />
            </div>

            {isNewClient && (
              <div className="col-span-full">
                <AddressField
                  label="Adresse du chantier"
                  formattedAddressFieldName="chantierFormattedAddress"
                  disabledFieldName="disabledChantierGoogleMaps"
                  googleMapsFieldName="chantierGoogleMapsAddress"
                  addressComplementFieldName="chantierAddressComplement"
                  legacyFieldName="chantierAddress"
                  isRequired
                />
              </div>
            )}
          </div>
          <>
            {isNewClient && (
              <div className="!flex !items-center col-span-full">
                <CheckboxField
                  data-testid="sameBillingAndChantierAddress"
                  id="sameBillingAndChantierAddress"
                  className="!block !mb-md"
                  name="sameBillingAndChantierAddress"
                  label="Adresse de facturation identique"
                />
              </div>
            )}
            {(!isNewClient || !values.sameBillingAndChantierAddress) && (
              <div data-testid="billing-address">
                <AddressField
                  label="Adresse de facturation"
                  disabledFieldName="disabledBillingGoogleMaps"
                  formattedAddressFieldName="billingFormattedAddress"
                  googleMapsFieldName="billingGoogleMapsAddress"
                  addressComplementFieldName="billingAddressComplement"
                  legacyFieldName="billingAddress"
                  isRequired
                />
              </div>
            )}
          </>
          {isNewClient && (
            <div className="!flex !items-center !mb-xmd">
              <CheckboxField
                data-testid="sendEmail"
                id="sendEmail"
                name="sendEmail"
                label="Initialiser la conversation avec mon client"
                className="!block"
              />
            </div>
          )}
        </ModalContent>
      )}
    </Form>
  );
};

export const ModalProClientDetailsUnprotected = ModalProClientDetailsComponent;
