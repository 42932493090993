import React, { useState } from 'react';

import classNames from 'classnames';

import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { tvaValues } from '@travauxlib/shared/src/utils/constants';

import { getTvaOptions } from './utils/utils';

import { FormLabel } from '../FormLabel';

export type Props = {
  selectedTauxTVA?: number;
  onChange: (tauxTVA?: number) => void;
  small?: boolean;
  label?: string;
  isHighlighted?: boolean;
};

export const TvaPicker: React.FC<Props> = ({
  selectedTauxTVA,
  onChange,
  small,
  label,
  isHighlighted,
}) => {
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(selectedTauxTVA);

  const classes = small
    ? {
        left: 'pl-xxs',
        right: 'pr-xxs',
      }
    : {
        left: 'pl-xs',
        right: 'pr-xs',
      };

  const handleClick = (value: number | 'custom'): void => {
    if (typeof value === 'number') {
      onChange(value);
    } else {
      setIsInputActive(true);
    }
  };

  const hasCustomValue = !!selectedTauxTVA && tvaValues[selectedTauxTVA] == undefined;
  const tvaOptions = getTvaOptions(hasCustomValue ? selectedTauxTVA : undefined);
  return (
    <>
      {label && <FormLabel className="!font-bold !mb-xs" label={label} />}
      <div className="flex flex-nowrap items-center rounded">
        {tvaOptions.map(({ label, value }, index) => {
          const selected = value === 'custom' ? hasCustomValue : selectedTauxTVA === value;

          return (
            <div className={classNames(small ? 'h-auto' : 'h-xxl', 'w-1/5')} key={value}>
              <div
                className={classNames('!h-full', {
                  [classes.right]: index !== tvaOptions.length - 1,
                  [classes.left]: index !== 0,
                })}
              >
                {value === 'custom' && isInputActive ? (
                  <NumberInput
                    id="custom"
                    label="Valeur"
                    className="!h-full"
                    autoFocus
                    value={inputValue}
                    onChange={value => value !== undefined && setInputValue(value)}
                    onBlur={() => {
                      onChange(inputValue);
                      setIsInputActive(false);
                    }}
                    onKeyPress={e => e.stopPropagation()}
                  />
                ) : (
                  <button
                    className={classNames(
                      'block rounded h-full w-full bg-primarylight',
                      'border-2 border-transparent hover:border-primary',
                      { active: selected },
                      {
                        '!border-primarylighter': selected || (isHighlighted && !isInputActive),
                      },
                    )}
                    type="button"
                    data-testid={`radio_${index}_${value}`}
                    onClick={() => handleClick(value)}
                  >
                    {label}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
