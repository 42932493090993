import React, { useState } from 'react';

import ArchiveOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ArchiveOutline.svg?react';
import Copy from '@travauxlib/shared/src/components/DesignSystem/assets/Copy.svg?react';
import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/Download.svg?react';
import Eye from '@travauxlib/shared/src/components/DesignSystem/assets/Eye.svg?react';
import QuoteCanceled from '@travauxlib/shared/src/components/DesignSystem/assets/QuoteCanceled.svg?react';
import QuoteValidated from '@travauxlib/shared/src/components/DesignSystem/assets/QuoteValidated.svg?react';
import Send from '@travauxlib/shared/src/components/DesignSystem/assets/Send.svg?react';
import {
  ActionList,
  ActionListItem,
} from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { downloadAsCsv } from '@travauxlib/shared/src/features/LogicielDeDevis/utils/downloadAsCsv';
import { FactureStatuses } from '@travauxlib/shared/src/types';
import { isFactured } from '@travauxlib/shared/src/utils/facture/isFactured';

import { useArchiveFacture } from 'features/Factures/api/useArchiveFacture';
import { useCreateAvoirForFacture } from 'features/Factures/api/useCreateAvoirForFacture';
import { useCreateFacture } from 'features/Factures/api/useCreateFacture';
import { useDeleteFacture } from 'features/Factures/api/useDeleteFacture';
import { Facture } from 'types';

import { DeleteFactureWarningMessage } from './DeleteFactureWarningMessage';

import { useNavigateToFacture } from '../../hooks/useNavigateToFacture';

type Props = {
  facture: Facture;
  isFacturationClotured: boolean;
  hasFactureToFactureBesideCloture: boolean;
  fixedFloating?: boolean;
};

const duplicateFacture = (facture: Facture): Facture => {
  const version = facture.title.match(/- version (\d+)$/);
  const title = version
    ? facture.title.replace(/- version (\d+)/, `- version ${parseInt(version[1], 10) + 1}`)
    : `${facture.title} - version 2`;

  return {
    ...facture,
    title,
  };
};

export const FactureActions: React.FC<Props> = ({
  facture,
  isFacturationClotured,
  hasFactureToFactureBesideCloture,
  fixedFloating,
}) => {
  const [isConfirmDeleteFactureModalOpen, setIsConfirmDeleteFactureModalOpen] = useState(false);
  const createFacture = useCreateFacture();
  const createAvoir = useCreateAvoirForFacture();
  const navigateToFacture = useNavigateToFacture();

  const { archiveFacture, unarchiveFacture } = useArchiveFacture();
  const { deleteFacture } = useDeleteFacture();
  const factured = isFactured(facture);
  const isArchived = facture.archived;
  const facturationLabel = facture.isCloture ? 'Cloturer' : 'Facturer';
  const facturationIcon = facture.isCloture ? <QuoteValidated /> : <Send />;

  let items: ActionListItem[] = [
    {
      type: 'action',
      label: factured || isArchived ? 'Consulter' : facturationLabel,
      onClick: () => navigateToFacture(facture),
      icon: factured || isArchived ? <Eye /> : facturationIcon,
      disabled: hasFactureToFactureBesideCloture && facture.isCloture,
    },
    {
      type: 'action',
      disabled: facture.isCloture || isFacturationClotured || isArchived,
      label: 'Dupliquer',
      icon: <Copy />,
      onClick: () =>
        createFacture({
          facture: duplicateFacture(facture),
          preventRedirect: true,
        }),
    },
    {
      type: 'action',
      label: 'Créer un avoir',
      icon: <QuoteCanceled />,
      disabled:
        isArchived ||
        !(
          !facture.isAvoir &&
          !isFacturationClotured &&
          (facture.status === 'awaiting_payment' || facture.status === 'paid')
        ),
      onClick: () => createAvoir(facture),
    },
    {
      type: 'action',
      label: facture.archived ? 'Désarchiver' : 'Archiver',
      disabled:
        (!isArchived && (facture.status !== FactureStatuses.draft || facture.isCloture)) ||
        isFacturationClotured,
      icon: <ArchiveOutline />,
      onClick: () => (facture.archived ? unarchiveFacture(facture) : archiveFacture(facture)),
    },
    {
      type: 'action',
      label: 'Supprimer',
      disabled: facture.status !== FactureStatuses.draft,
      icon: <Delete />,
      onClick: () => setIsConfirmDeleteFactureModalOpen(true),
    },
    {
      type: 'divider',
      label: 'first-divider',
    },
    {
      type: 'action',
      icon: <Download />,
      label: 'Télécharger au format PDF',
      onClick: () => window.open(`${APP_CONFIG.apiURL}/client/factures/${facture.token}/pdf`),
    },
    {
      type: 'action',
      label: 'Télécharger au format Excel',
      onClick: () =>
        downloadAsCsv(facture.lots, facture.prixTotalHT, `facture-${facture.token}.csv`),
    },
  ];

  return (
    <>
      <ActionList
        small
        fixedFloating={fixedFloating}
        trigger={(toggle, referenceProps) => (
          <IconButton
            onClick={toggle}
            {...referenceProps}
            className="ml-sm"
            size="md"
            data-testid="open-facture-actions"
          >
            <DotsMenu />
          </IconButton>
        )}
        items={items}
      />
      <DeleteFactureWarningMessage
        closeModal={() => setIsConfirmDeleteFactureModalOpen(false)}
        handleConfirm={() => deleteFacture(facture)}
        isOpen={isConfirmDeleteFactureModalOpen}
      />
    </>
  );
};
