import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Deal } from 'types';

import { DEAL_KEY } from './useDeal';

import { PRO_CLIENT_KEY } from '../../ProClients/api/useProClient';
import { PRO_CLIENTS_KEY } from '../../ProClients/api/useProClients';

const handleUpdateDeal = (deal: Deal): Promise<Deal> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${deal.uuid}`;
  return request(requestURL, {
    method: 'PATCH',
    body: JSON.stringify(deal),
  });
};

export const useUpdateDeal = (
  onSuccess?: (deal?: Deal) => void,
  onError?: (error: unknown) => void,
): { updateDeal: typeof handleUpdateDeal; isLoading: boolean } => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateDeal, isPending } = useMutation({
    mutationFn: handleUpdateDeal,
    onSuccess: (deal: Deal) => {
      queryClient.invalidateQueries({ queryKey: [PRO_CLIENTS_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRO_CLIENT_KEY, deal.proClientUuid] });
      queryClient.setQueryData([DEAL_KEY, deal.uuid], deal);
      onSuccess?.(deal);
    },
    onError: error => {
      toast.error("Le deal n'a pas pu être modifié");
      onError?.(error);
    },
  });

  return { updateDeal, isLoading: isPending };
};
