import React, { useEffect, useState } from 'react';

import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { Ligne, Lot } from '@travauxlib/shared/src/types';

import { useProfile } from 'api/useProfile';
import { useDeal } from 'features/Deals/api/useDeal';
import { ModalAssurances } from 'features/Deals/hooks/useOpenCreateDevisRGEModal';
import { useDevis } from 'features/Devis/api/useDevis';
import { useUpdateDevis } from 'features/Devis/api/useUpdateDevis';
import { Deal, Devis } from 'types';
import { getDevisEnvoiUrl } from 'utils/urls';

import { Step1 } from './components/Step1';
import { Step2 } from './components/Step2';
import { FormValues } from './types';
import { getPrixTotalHT, getPrixTotalTTC } from './utils/getPrice';

type OwnProps = {
  isArchitecte: boolean;
  devisToken: string;
  dealUuid: string;
  customerName: string;
  handleClose: () => void;
};

type InjectedProps = {
  onSubmit: (values: FormValues) => Promise<void>;
  devis: Devis;
  deal: Deal;
  initialValues: FormValues;
};

type Props = OwnProps & InjectedProps;

export const RawAddAttachmentModal: React.FC<Props> = props => {
  const { deal, devis, handleClose, isArchitecte, initialValues } = props;
  const [page, setPage] = useState<number>(1);

  return (
    <Form<FormValues>
      initialValues={initialValues}
      initialValuesEqual={() => true}
      onSubmit={async (values: FormValues) => {
        const { onSubmit } = props;
        if (page === 1) {
          if (values.attachments.length === 0) {
            return { [FORM_ERROR]: 'Il manque le devis' };
          }
          setPage(2);
          return undefined;
        }
        const hasEmptyTotalHT =
          values.montantsTVA.filter(montantTVA => montantTVA.totalHT === 0).length !== 0;

        if (hasEmptyTotalHT) {
          return {
            [FORM_ERROR]: 'Le total HT de chaque montant doit être supérieur à 0',
          };
        }
        await onSubmit(values);
        return undefined;
      }}
    >
      {({
        values: { montantsTVA },
        submitError,
        dirtySinceLastSubmit,
        submitting,
        handleSubmit,
      }) => (
        <>
          {page === 1 && (
            <Step1
              dirtySinceLastSubmit={dirtySinceLastSubmit}
              submitError={submitError}
              dealUuid={deal.uuid}
              devisToken={devis.token}
              onClose={handleClose}
              isArchitecte={isArchitecte}
              handleSubmit={handleSubmit}
            />
          )}
          {page === 2 && (
            <Step2
              dirtySinceLastSubmit={dirtySinceLastSubmit}
              submitError={submitError}
              deal={deal}
              prixTotalHT={getPrixTotalHT(montantsTVA)}
              prixTotalTTC={getPrixTotalTTC(montantsTVA)}
              submitting={submitting}
              isArchitecte={isArchitecte}
              montantsTVA={montantsTVA}
              handleSubmit={handleSubmit}
              onPrevious={() => setPage(1)}
            />
          )}
        </>
      )}
    </Form>
  );
};

export const convertFormValuesToProperDevis = (devisFromForm: FormValues): Devis => {
  const { montantsTVA, ...rest } = devisFromForm;
  const lotUuid = uuidV4();

  const lots: Lot[] = [
    {
      uuid: lotUuid,
      label: 'Devis en pièce jointe',
      items: montantsTVA.map(({ totalHT, tauxTVA }) => ({
        uuid: uuidV4(),
        lotUuid: lotUuid,
        tauxTVA,
        designation: `Prestations soumises à un taux de TVA de ${tauxTVA}% - devis en pièce jointe`,
        prixUnitaireHT: totalHT,
        quantite: 1,
        prixHT: totalHT,
        prixTTC: totalHT + totalHT * (tauxTVA / 100),
        prixAchat: totalHT,
        marge: 0,
        montantTVA: totalHT * (tauxTVA / 100),
        unite: 'unitaire',
        type: 'ligne',
        locations: [],
      })),
    },
  ];

  return {
    ...rest,
    archived: false,
    hidden: false,
    projectTitle: devisFromForm.title,
    lots,
  } as Devis;
};

const buildDevisWithMontantsTva = (devis: Devis): FormValues => {
  const { lots } = devis;
  if (lots.length === 0) {
    return {
      ...devis,
      montantsTVA: [{ totalHT: 0, tauxTVA: 10 }],
    } as FormValues;
  }
  const [firstLot] = lots;

  return {
    ...devis,
    montantsTVA: firstLot.items
      .filter(item => item.type === 'ligne')
      .map((ligne: Ligne) => ({
        tauxTVA: ligne.tauxTVA,
        totalHT: ligne.prixHT,
      })),
  } as FormValues;
};

export const AddAttachmentModal: React.FC<OwnProps> = props => {
  const {
    profile: { company },
  } = useProfile();
  const [pendingDevisRGE, setPendingDevisRGE] = useState<Devis>();
  const { dealUuid, devisToken } = props;
  const { devis } = useDevis({ dealUuid, token: devisToken });
  const deal = useDeal(dealUuid);
  const navigate = useNavigate();
  const updateDevis = useUpdateDevis({ addToHistory: true });

  const submitDevisAndRedirect = async (devis: Devis): Promise<void> => {
    await updateDevis(devis);
    navigate(getDevisEnvoiUrl(devis));
  };

  useEffect(() => {
    if (pendingDevisRGE && company.hasValidInsurances) {
      submitDevisAndRedirect(pendingDevisRGE);
    }
  }, [company.hasValidInsurances, pendingDevisRGE]);

  if (!deal || !devis) {
    return <LoaderWrapper />;
  }

  const initialValues = buildDevisWithMontantsTva(devis);
  const onSubmit = async (devisFromForm: FormValues): Promise<void> => {
    const devis = convertFormValuesToProperDevis({
      ...devisFromForm,
    });
    if (devisFromForm.isRGE && !company.hasValidInsurances) {
      setPendingDevisRGE(devis);
    } else {
      submitDevisAndRedirect(devis);
    }
  };
  if (pendingDevisRGE) {
    return <ModalAssurances handleClose={() => setPendingDevisRGE(undefined)} />;
  }
  return (
    <RawAddAttachmentModal
      {...props}
      deal={deal}
      devis={devis}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
