import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { UserProfile } from '@travauxlib/shared/src/types';
import { request, isConflict } from '@travauxlib/shared/src/utils/request';

import { ProCompany } from 'types';
import { sendEventToTrackingModules } from 'utils/sendEventToTrackingModules';
import { dashboardDevisRecapUrl } from 'utils/urls';

import { AUTH_KEY } from './useProfile';

type CreateCompanyWithSiretPayload = {
  siret: number;
  token: string;
  uuid?: string;
};

export const handleCreateCompanyWithSiret = ({
  payload,
}: {
  payload: CreateCompanyWithSiretPayload;
}): Promise<UserProfile<ProCompany>> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro-registrations/create-company`;

  return request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

export const useCreateCompanyWithSiret = (): typeof handleCreateCompanyWithSiret => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutateAsync: createCompanyWithSiret } = useMutation({
    mutationFn: handleCreateCompanyWithSiret,
    onSuccess: (profile: UserProfile<ProCompany>) => {
      sendEventToTrackingModules({ event: 'pro.siret.finished' });
      queryClient.setQueryData([AUTH_KEY], profile);
      navigate(`${dashboardDevisRecapUrl}?accountCreated=true`);
    },
    onError: (error: Error) => {
      const message = isConflict(error)
        ? "L'email est déjà utilisé par une autre entreprise ou un autre utilisateur."
        : 'Une erreur est survenue, veuillez contacter notre support';
      toast.error(message);
    },
  });

  return createCompanyWithSiret;
};
