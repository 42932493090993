import React from 'react';

import { Devis } from 'types';

import { Choice } from './Choice';

type Props = {
  handleClose: () => void;
  devis: Devis;
};

export const FacturerDevisModal: React.FC<Props> = ({ handleClose, devis }) => (
  <Choice devis={devis} onClose={handleClose} />
);
