import { BaseDePrixFilter } from '../types';

export const baseDePrixBaseURL = `${APP_CONFIG.proApiURL}/base-de-prix`;
export const baseDePrixBatiPrixBaseURL = `${APP_CONFIG.proApiURL}/base-de-prix-batiprix`;

export const mainOeuvreBaseUrl = `${APP_CONFIG.proApiURL}/main-oeuvre`;
export const materiauBaseUrl = `${APP_CONFIG.proApiURL}/materiau`;
export const fournitureBaseUrl = `${APP_CONFIG.proApiURL}/fourniture`;
export const baseDePrixFilters: BaseDePrixFilter[] = [
  {
    value: 'hemea',
    label: 'hemea',
  },
  {
    value: 'batiprix',
    label: 'Batiprix',
  },
  {
    value: 'self',
    label: 'Mes lignes',
  },
];
