import React, { useEffect, useState } from 'react';

import { InteractiveTags } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/InteractiveTags';
import { TagOption } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/types';
import { FactureStatuses } from '@travauxlib/shared/src/types';

import { usePayFacture } from 'features/Factures/api/usePayFacture';
import { Facture } from 'types';

const options = [
  { label: 'Brouillon', variant: 'light', value: FactureStatuses.draft },
  { label: 'En attente de paiement', variant: 'warning', value: FactureStatuses.awaiting_payment },
  { label: 'Payée', variant: 'success', value: FactureStatuses.paid },
] as TagOption[];

export const StatusTagsFacture: React.FC<{
  facture: Facture;
  handleTracking?: () => void;
  disabled: boolean;
  fixedFloating?: boolean;
}> = ({ facture, handleTracking, disabled, fixedFloating }) => {
  const payFacture = usePayFacture();
  const [selectedTag, setSelectedTag] = useState<string>(facture.status);

  const handlePayFacture = (): void => {
    handleTracking?.();
    payFacture(facture);
  };

  useEffect(() => {
    setSelectedTag(facture.status);
  }, [facture]);

  return (
    <div onClick={event => event.stopPropagation()}>
      <InteractiveTags
        fixedFloating={fixedFloating}
        value={selectedTag}
        onChange={tag => {
          handlePayFacture();
          setSelectedTag(tag);
        }}
        options={options}
        disabled={disabled}
        placeholder={FactureStatuses.draft}
      />
    </div>
  );
};
