import React from 'react';

import classNames from 'classnames';

import Announcement from '@travauxlib/shared/src/components/DesignSystem/assets/Announcement.svg?react';

import { usePermissions } from 'hooks/usePermissions';
import { NotificationTracking } from 'utils/tracking/NotificationTracking';

type Props = {
  notificationsTabOpen: boolean;
  setNotificationsTabOpen: (notificationsTabOpen: boolean) => void;
  pendingNotificationNumber: number;
  isFullyOpen: boolean;
};

export const NotificationsTrigger: React.FC<Props> = ({
  notificationsTabOpen,
  setNotificationsTabOpen,
  pendingNotificationNumber,
  isFullyOpen,
}) => {
  const { useProtectedCall } = usePermissions();
  const [protectedOpenNotifications] = useProtectedCall(
    (value: boolean) => {
      NotificationTracking.onNotificationTabOpened();
      setNotificationsTabOpen(value);
    },
    'read',
    'Notification',
  );

  return (
    <div onClick={() => protectedOpenNotifications(!notificationsTabOpen)}>
      <div className="flex flex-row [&>.text-neutral-600]:hover:text-dark cursor-pointer">
        <div
          className={classNames('flex justify-center items-center my-xmd ml-[18px]', {
            'text-neutral-600': !notificationsTabOpen,
          })}
        >
          <Announcement className="w-lg h-lg" data-testid="icon-notifications" />
        </div>
        <div
          className={classNames(
            'bg-primary rounded-full text-[12px] w-[18px] h-[18px] mt-sm flex justify-center items-center text-white',
            { hidden: pendingNotificationNumber === 0 },
          )}
        >
          {pendingNotificationNumber}
        </div>
        <div
          className={classNames('flex mt-xmd font-bold', {
            hidden: !isFullyOpen,
            'ml-md': pendingNotificationNumber === 0,
            'text-neutral-600': !notificationsTabOpen,
          })}
        >
          Notifications
        </div>
      </div>
    </div>
  );
};
