import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SuiviChantier } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const SUIVI_LIST_KEY = 'suivi-list';

export const useGetSuiviChantierList = (
  dealUuid: string,
): { suiviChantierList: SuiviChantier[]; isLoading: boolean } => {
  const { data, isPending } = useQuery<{ data: SuiviChantier[] }>({
    queryKey: [SUIVI_LIST_KEY, dealUuid],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/suivi-chantier`;
        return await request(requestURL);
      } catch {
        toast.error("Le suivi n'a pas pu être récupéré");
      }
    },
  });
  return { suiviChantierList: data?.data || [], isLoading: isPending };
};
