import React from 'react';

import { useFormState } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { useCanSubmitForm } from '@travauxlib/shared/src/utils/form';

import { CGUBlock } from 'components/CGUBlock';

export const FormFooter: React.FC = () => {
  const { submitting } = useFormState();
  const canSubmit = useCanSubmitForm();

  return (
    <div className="px-md">
      <Button
        type="submit"
        data-tracking-group="Demande_Chantier"
        fullwidth
        data-tracking-item="Demande_Chantier"
        className="mb-lg"
        disabled={!canSubmit}
        loading={submitting}
      >
        Valider mes informations
      </Button>
      <CGUBlock />
    </div>
  );
};
