import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { UserProfile } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { ProCompany } from 'types';

import { AUTH_KEY } from './useProfile';

const handleActivateTrialPeriod = (): Promise<ProCompany> => {
  const requestUrl = `${APP_CONFIG.proApiURL}/club-pro/activate-trial-period`;

  return request(requestUrl, { method: 'POST' });
};

export const useActivateTrialPeriod = (): {
  activateTrialPeriod: typeof handleActivateTrialPeriod;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: activateTrialPeriod, isPending } = useMutation({
    mutationFn: handleActivateTrialPeriod,
    onSuccess: (company: ProCompany) => {
      const profile: UserProfile<ProCompany> = queryClient.getQueryData([AUTH_KEY])!;
      queryClient.setQueryData([AUTH_KEY], { ...profile, company });

      toast.success("Votre période d'essai a été activée");
    },
    onError: () => {
      toast.error("L'activation de votre période d'essai a échoué");
    },
  });
  return { activateTrialPeriod, isLoading: isPending };
};
