import React from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import Archive from '@travauxlib/shared/src/components/DesignSystem/assets/Archive.svg?react';
import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import DealHemea from 'assets/deal_hemea.svg?react';
import { useMultiUser } from 'features/App/hooks/useMultiUser';
import { useUpdateDeal } from 'features/Deals/api/useUpdateDeal';
import { useOpenArchiveDealModal } from 'features/Deals/hooks/useOpenArchiveDealModal';
import { EditableDealTitle } from 'features/ProClients/components/DealCard/EditableDealTitle';
import { Deal } from 'types';
import { getDealProUser } from 'utils/getDealProUser';
import { getDealRootUrl } from 'utils/urls';

type Props = {
  deal: Deal;
};

export const DealCard: React.FC<Props> = ({ deal }) => {
  const openArchiveProjectModal = useOpenArchiveDealModal();
  const navigate = useNavigate();
  const { updateDeal } = useUpdateDeal();
  const { proUsers, hasMultiUsersAccess } = useMultiUser();
  const dealProUser = getDealProUser(proUsers, deal);

  return (
    <Card
      className={classNames(
        'border-[0.125rem] border-transparent hover:border-primary !rounded-md !bg-primarylighter !h-full',
        {
          'opacity-50': deal.archived,
        },
      )}
      onClick={() => navigate(getDealRootUrl(deal.uuid))}
    >
      <>
        <div className="flex justify-between font-bold !items-center !mb-md">
          <EditableDealTitle deal={deal} />
          <div className="flex items-center">
            {deal.isHemea && <DealHemea className="shrink-0 my-auto mr-sm" />}
            <EURCurrency amount={deal.amountHTOfSignedDevis} />
          </div>
        </div>
        <div className="!mb-xmd">
          {deal.chantierFormattedAddress && (
            <div className="!flex !items-center !mb-xs">
              <Location className="w-md h-md mr-sm" /> {deal.chantierFormattedAddress}
            </div>
          )}
          {deal.customerPhoneNumber && (
            <div className="!flex !items-center !mb-xs">
              <Phone className="w-md h-md mr-sm" /> {deal.customerPhoneNumber}
            </div>
          )}
          {deal.customerEmail && (
            <div className="!flex !items-center !mb-xs">
              <Envelope className="w-md h-md mr-sm" /> {deal.customerEmail}
            </div>
          )}
          {hasMultiUsersAccess && dealProUser && (
            <div className="!flex !items-center !mb-xs ">
              <Person className="w-md h-md mr-sm" /> {dealProUser.fullName}
            </div>
          )}
        </div>
        <div className="!flex !items-center !justify-between !flex-wrap !mt-auto">
          <div className="!mr-md !mb-xs">Créé le {formatFrenchDate(deal.createdAt)}</div>
          <Button
            className="bg-transparent p-0 !flex !items-center !font-bold !mb-xs"
            variant="tertiary"
            leftIcon={<Archive />}
            onClick={event => {
              event.stopPropagation();
              openArchiveProjectModal({
                label: 'le chantier ?',
                archived: deal.archived,
                handleAccept: async () => {
                  await updateDeal({ ...deal, archived: !deal.archived });
                },
              });
            }}
          >
            {deal.archived ? 'Désarchiver' : 'Archiver'} le chantier
          </Button>
        </div>
      </>
    </Card>
  );
};
