import React from 'react';

import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

type Props = {
  id: string;
  tooltipPrefix: string;
  amount: number;
  total: number;
  bg: string;
  showPercent?: boolean;
};

export const ProgressBarSection: React.FC<Props> = ({
  id,
  tooltipPrefix,
  amount,
  total,
  bg,
  showPercent = true,
}) => {
  const percent = roundToTwoDecimals((amount * 100) / total || 0);
  return (
    <>
      {tooltipPrefix && (
        <WithTooltip
          position="bottom"
          trigger={
            <div
              data-tip
              data-for={id}
              className={`flex flex-col justify-center overflow-hidden text-center whitespace-nowrap ${bg}`}
              role="progressbar"
              style={{
                width: `${percent}%`,
                color: ['!bg-ghostwhite', '!bg-light', '!bg-white'].includes(bg)
                  ? 'black'
                  : 'white',
              }}
              aria-valuenow={percent}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              {showPercent && <strong>{percent.toLocaleString()} %</strong>}
            </div>
          }
        >
          <div>
            {tooltipPrefix} : <EURCurrency amount={amount} forceFullPattern />
          </div>
        </WithTooltip>
      )}
    </>
  );
};
