import { devisFactureStatuses } from '@travauxlib/shared/src/utils/constants';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { MenuItem } from 'types';
import {
  partenariatAssurancesUrl,
  partenariatCarsUrl,
  partenariatCEUrl,
  partenariatDTUUrl,
  partenariatFormationUrl,
  partenariatMateriauxUrl,
  partenariatServicesUrl,
  partenariatSupportAdministratifUrl,
} from 'utils/urls';

export const baseImgixOnboardingUrl = `${baseImgixUrl}/static/onboarding-pro`;

export const devisStatusValues = [
  devisFactureStatuses.draft.value,
  devisFactureStatuses.sent.value,
  devisFactureStatuses.signed.value,
];

export const facturesStatusValues = [
  devisFactureStatuses.draft.value,
  devisFactureStatuses.awaiting_payment.value,
  devisFactureStatuses.paid.value,
];

export const trustPilotInfos = {
  author: 'ENRG BAT',
  title: 'Excellente gestion',
  date: '22 déc. 2020',
  review: `Grâce au pouvoir de négociation de hemea,
  j’économise 20 000€ tous les ans avec en plus :
  la livraison gratuite et des conseils professionnels.
  Je conseille la Centrale d’Achat hemea à
  toutes les entreprises du BTP qui veulent faire
  plus de marge sur leurs chantiers.`,
};

export const imgExtensions = ['.png', '.gif', '.jpg', '.jpeg', '.webp'];

// 20Mo
export const MAX_FILE_SIZE = 20000000;

export const centraleAchatSideBarTabs: MenuItem[] = [
  { to: partenariatMateriauxUrl, label: 'Matériaux' },
  { to: partenariatCarsUrl, label: 'Véhicules' },
  { to: partenariatServicesUrl, label: 'Services' },
  { to: partenariatAssurancesUrl, label: 'Assurances' },
  { to: partenariatDTUUrl, label: 'Assistance' },
  { to: partenariatCEUrl, label: "Comité d'entreprise" },
  { to: partenariatSupportAdministratifUrl, label: 'Modèles de contrat' },
  { to: partenariatFormationUrl, label: 'Formations' },
];

export const PRO_COMPANY_USERS_KEY = 'procompanyusers';

export const LAST_DOCUMENT_AMOUNT_TO_FETCH = 6;
