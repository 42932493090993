export const baseStaticS3Url =
  'https://travauxlib-espace-pro-prod.s3.amazonaws.com/static/cgvcgucgs/2024';

export const cgsOffre = `${baseStaticS3Url}/hemea-conditions-generales-de-service-offres-avec-couverture-de-service-2024.pdf`;
export const cgvArchi = `${baseStaticS3Url}/hemea-conditions-generales-de-vente-client-architecte-2024.pdf`;
export const cgvPrestataire = `${baseStaticS3Url}/hemea-conditions-generales-de-vente-client-prestataire-2024.pdf`;
export const cgsPrestataire = `${baseStaticS3Url}/cgs-novare-construction-conditions-generales-de-services-hemea-x-prestataires-2024.pdf`;
export const cgsArchi = `${baseStaticS3Url}/cgs-novare-construction-conditions-generales-de-services-hemea-x-architecte-2024.pdf`;
export const cguvClubPro = `${baseStaticS3Url}/cguv-novare-construction-conditions-generales-d-utilisation-et-de-vente-club-pro-2024.pdf`;

export const chartePro = `${baseStaticS3Url}/hemea_Charte-pro.pdf`;

export const avantagesCEHemea = `${baseStaticS3Url}/avantages-ce.pdf`;
