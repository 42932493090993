import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';

import { useAddDevisHistoryEntry } from '@travauxlib/shared/src/components/DevisHistoryContext/hooks/useAddDevisHistoryEntry';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Devis } from 'types';

import { DEVIS_KEY } from './useDevis';
import { DEVIS_LIST_KEY } from './useDevisList';

const handleUpdateDevis =
  (addToHistory: boolean) =>
  (devis: Devis): Promise<Devis> => {
    const requestURL = `${APP_CONFIG.proApiURL}/deals/${devis.dealUuid}/devis/${devis.token}`;

    return request(requestURL, {
      // Pas très propre mais un peu nécessaire
      // y a des pros qui devaient faire des copié/collé chelou
      // et ajouter le caractère unicode \u0000 dans leur devis.
      // C'est le seul caractère que postgres peut pas accepter dans ses strings
      // vu que c'est le delimiteur de string en C.

      // on pourrait faire plus propre en utilisant une classe CleanString à la PhoneNumber
      // mais sans doute chronophage et relou
      body: JSON.stringify(devis).replace(/\\u0000/g, ''),
      method: 'PUT',
      params: addToHistory
        ? {}
        : {
            isUndoredo: true,
          },
    });
  };

export const useUpdateDevis: ({
  addToHistory,
}: {
  addToHistory: boolean;
}) => UseMutateAsyncFunction<Devis, unknown, Devis> = ({ addToHistory = true }) => {
  const queryClient = useQueryClient();
  const addEntryInHistory = useAddDevisHistoryEntry();

  const { mutateAsync: updateDevisAndDevisItems } = useMutation<Devis, unknown, Devis>({
    mutationFn: handleUpdateDevis(addToHistory),
    onMutate: async (newDevis: Devis) => {
      await queryClient.cancelQueries({ queryKey: [DEVIS_KEY, newDevis.dealUuid, newDevis.token] });
      const previousDevis = queryClient.getQueryData<Devis>([
        DEVIS_KEY,
        newDevis.dealUuid,
        newDevis.token,
      ]);

      queryClient.setQueryData<Devis>(
        [DEVIS_KEY, newDevis.dealUuid, newDevis.token],
        previousDevis => ({ ...previousDevis, ...newDevis }),
      );

      return { previousDevis };
    },
    onSuccess: (devis: Devis, _, context: { previousDevis: Devis }) => {
      const previous = context.previousDevis;

      if (previous && addToHistory) {
        addEntryInHistory(previous);
      }

      queryClient.invalidateQueries({ queryKey: [DEVIS_LIST_KEY, devis.dealUuid] });
    },
    onError: (_, newDevis, context: { previousDevis: Devis }) => {
      queryClient.setQueryData(
        [DEVIS_KEY, newDevis.dealUuid, newDevis.token],
        context.previousDevis,
      );
      toast.error('Impossible de mettre à jour le devis');
    },
    onSettled: (_, _2, newDevis) => {
      queryClient.invalidateQueries({ queryKey: [DEVIS_KEY, newDevis.dealUuid, newDevis.token] });
    },
  });

  return updateDevisAndDevisItems;
};
