import React, { useState } from 'react';

import AnnouncementDuoTone from '@travauxlib/shared/src/components/DesignSystem/assets/AnnouncementDuoTone.svg?react';
import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

type Props = {
  title: string;
  message: JSX.Element;
  callback: () => void;
  guideUrl: string;
  Icon?: React.ComponentType<{ className: string }>;
};

export const AnnouncementModal: React.FC<Props> = ({
  title,
  message,
  callback,
  guideUrl,
  Icon,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const applyCallbackAndCloseModal = (): void => {
    callback();
    setIsOpen(false);
  };

  return (
    <FeedbackMessages
      isOpen={isOpen}
      handleClose={applyCallbackAndCloseModal}
      Icon={Icon ? Icon : AnnouncementDuoTone}
      title={title}
      message={message}
      validateAction={{
        label: 'Consulter le guide',
        onClick: () => {
          window.open(guideUrl, '_blank', 'noreferrer');
          applyCallbackAndCloseModal();
        },
      }}
      cancelAction={{
        label: 'Consulter plus tard',
        onClick: applyCallbackAndCloseModal,
      }}
      centered
    />
  );
};

export const useOpenAnnouncementModal = (): ((props: Props) => void) => {
  const openModal = useOpenModal();

  return props => {
    openModal(AnnouncementModal, props);
  };
};
