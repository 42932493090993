import { tvaValues } from '@travauxlib/shared/src/utils/constants';

export const standardOptions = Object.entries(tvaValues)
  .map(([, { value }]) => ({
    label: `TVA ${value.toLocaleString('fr')}`,
    value: value,
  }))
  .sort((a, b) => a.value - b.value);

export const getTvaOptions = (
  customValue?: number,
): Array<{ label: string; value: number | 'custom' }> => [
  ...standardOptions,
  {
    label: customValue !== undefined ? `TVA ${customValue.toLocaleString('fr')}` : 'Autre',
    value: 'custom',
  },
];
