import React from 'react';

import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';
import { BonCommande } from '@travauxlib/shared/src/types';

import { Header } from './Header';

import { BonCommandeTable } from '../BonCommandeTable';
import { BonCommandeCard } from '../BonCommandeTable/BonCommandeCard';

type Props = {
  bonCommandeList: BonCommande[];
  mainBonCommande: BonCommande;
};

export const BonCommandeBlock: React.FC<Props> = ({ bonCommandeList, mainBonCommande }) => {
  const suffixe = bonCommandeList.length > 1 ? 's' : '';
  const isBelowXl = useIsBelowBreakpoint('md-desktop');

  return (
    <div className="position-relative">
      <div className="pb-xxs mb-xs md-desktop:mb-0 bg-primarylighter">
        <Header
          subTitle={`${bonCommandeList.length} version${suffixe} du bon de commande`}
          mainBonCommande={mainBonCommande}
        />
      </div>
      {isBelowXl ? (
        bonCommandeList.map(bonCommande => <BonCommandeCard bonCommande={bonCommande} />)
      ) : (
        <BonCommandeTable bonCommandeList={bonCommandeList} />
      )}
    </div>
  );
};
