import React from 'react';

import classNames from 'classnames';

import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import PlaceholderPlan from '@travauxlib/shared/src/components/DesignSystem/assets/PlaceholderPlan.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';

type Props = {
  downloadUrl: string;
  name: string;
  className?: string;
};

export const DocumentCard: React.FC<Props> = ({
  downloadUrl,
  name,
  className = 'sm-desktop:w-[346px]',
}) => (
  <Card state="outlined" bodyClassNames="!px-xs" className={classNames('w-full', className)}>
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <div className="mr-xs">
          <PlaceholderPlan width={64} height={64} />
        </div>
        <Link href={downloadUrl} target="_blank">
          {name}
        </Link>
      </div>
      <div>
        <ButtonLink href={downloadUrl} leftIcon={<OpenTab />} variant="tertiary" />
      </div>
    </div>
  </Card>
);
