import React from 'react';

import { useFormState } from 'react-final-form';

import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

export const NotAvailableReason = 'Pas dispo sur cette période';

export const NotAvailableComponent: React.FC = () => {
  const {
    values: { dispoTravaux },
  } = useFormState();
  return (
    <div>
      Veuillez nous indiquer vos dates d&apos;indisponibilité pour que nous évitions de vous
      relancer sur cette période :
      <ul className="!m-0 !px-md !pt-xmd pb-md">
        <DatePickerField
          className="!mb-md"
          label="Je suis disponible pour commencer des travaux à partir du&nbsp;:"
          parse={parseDateInput}
          id="dispoTravaux"
          name="dispoTravaux"
        />
        {dispoTravaux && (
          <DatePickerField
            label="Si différent, je suis disponible pour faire des chiffrages / devis à partir du&nbsp;:"
            parse={parseDateInput}
            id="dispoDevis"
            name="dispoDevis"
          />
        )}
      </ul>
    </div>
  );
};
