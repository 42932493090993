import React from 'react';

import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';

type Props = {
  onClick: () => void;
  label: string;
};

export const DashedActionButton: React.FC<Props> = ({ onClick, label }) => (
  <div className="rounded border mt-xxs mb-xs border-primary border-dashed py-xs px-xs sm-desktop:px-md">
    <div onClick={onClick} className=" items-center cursor-pointer ">
      <div className="text-neutral-900 text-ds-b1 w-full flex justify-center font-bold text-ds-b1">
        <PlusCircle className="mr-xs text-primary h-lg w-lg sm-desktop:ml-0" /> {label}
      </div>
    </div>
  </div>
);
