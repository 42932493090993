import _groupBy from 'lodash/groupBy';
import _maxBy from 'lodash/maxBy';

import { BonCommande } from '@travauxlib/shared/src/types';

export const groupAndSortBonCommande = (
  bonCommandeList: BonCommande[],
): {
  bonCommandeList: BonCommande[];
  updatedAt: string | number;
}[] => {
  const groupedBonCommandeList = _groupBy(
    bonCommandeList,
    bonCommande => bonCommande.proDevisToken,
  );
  return Object.entries<BonCommande[]>(groupedBonCommandeList)
    .map(([, bonCommandeList]) => ({
      bonCommandeList,
      updatedAt: _maxBy(bonCommandeList, 'updatedAt')?.updatedAt || 0,
    }))
    .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
};
