import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Deal } from 'types';

import { DEAL_KEY } from './useDeal';

import { PRO_CLIENTS_KEY } from '../../ProClients/api/useProClients';

type UpdateDateRDVPayload = {
  dealUuid: string;
  meetingDate: Dayjs;
};

const handleUpdateDateRDV = ({ dealUuid, meetingDate }: UpdateDateRDVPayload): Promise<Deal> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/meetings`;
  return request(requestURL, {
    method: 'POST',
    body: { meetingDate },
  });
};

export const useUpdateDateRDV = (): {
  updateDateRDV: typeof handleUpdateDateRDV;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateDateRDV, isPending } = useMutation({
    mutationFn: handleUpdateDateRDV,
    onSuccess: (deal: Deal) => {
      queryClient.setQueryData([DEAL_KEY, deal.uuid], deal);
      queryClient.invalidateQueries({ queryKey: [PRO_CLIENTS_KEY] });
      toast.success('Les modifications ont bien été prises en compte');
    },
  });

  return { updateDateRDV, isLoading: isPending };
};
