import React, { useEffect } from 'react';

import { Form } from 'react-final-form';

import { ReassuranceColumn } from 'components/ReassuranceColumn';
import { useHandleCreateDemandeChantier } from 'features/DemandeChantier/hooks/useHandleCreateDemandeChantier';
import { reassuranceColumnConfig } from 'features/DemandeChantier/utils';
import { sendEventToTrackingModules } from 'utils/sendEventToTrackingModules';
import { DemandeChantierTracking } from 'utils/tracking/DemandeChantierTracking';

import { FormBody } from './FormBody';
import { FormFooter } from './FormFooter';
import { FormHeader } from './FormHeader';

import { DemandeChantierFormData } from '../../types';

export const FormWrapper: React.FC = () => {
  const handleCreateDemandeChantier = useHandleCreateDemandeChantier();

  useEffect(() => {
    DemandeChantierTracking.onDemandeChantierViewed();
    sendEventToTrackingModules({ event: 'pro.demande-chantier.started' });
  }, []);

  return (
    <Form<DemandeChantierFormData>
      onSubmit={values => {
        if (values.phoneNumberObj.countryCode !== 'FR') {
          handleCreateDemandeChantier({
            ...values,
            phoneNumber: values.phoneNumberObj.internationalPhoneNumber!,
          });
        } else {
          handleCreateDemandeChantier({ ...values, phoneNumber: values.phoneNumberObj.number! });
        }
        DemandeChantierTracking.onDemandeChantierCompleted({
          'Email entrepreneur': values.email,
          'Nom entrepreneur': values.lastName,
          'Prenom entrepreneur': values.firstName,
          'Numero entrepreneur': values.phoneNumberObj.number!,
        });
        sendEventToTrackingModules({ event: 'pro.demande-chantier.finished' });
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="!h-full !min-h-screen">
          <div className="flex -mx-md h-full min-h-screen">
            <ReassuranceColumn config={reassuranceColumnConfig} />
            <div className="sm-desktop:p-md bg-white w-full sm-desktop:right-0 sm-desktop:min-w-7/12 sm-desktop:pl-[6.25rem] sm-desktop:pr-[9rem] sm-desktop:pt-xxl">
              <FormHeader />
              <FormBody />
              <FormFooter />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
