import React from 'react';

import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';

type Props = {
  src: string;
};

const THUMBNAIL_WIDTH = 60;

export const ImgThumbnail: React.FC<Props> = ({ src }) => (
  <div className="p-xxs bg-white rounded h-auto border-solid border-neutral-200 mr-xs">
    <ImgLazy alt="" width={THUMBNAIL_WIDTH} url={src} />
  </div>
);
