import React from 'react';

type RightColumnSectionTitleProps = {
  text: string;
  children?: JSX.Element | JSX.Element[];
  onClick: () => void;
};

export const RightColumnSectionTitle: React.FC<RightColumnSectionTitleProps> = ({
  children,
  text,
  onClick,
}) => (
  <div className="!flex !items-center">
    <div
      onClick={onClick}
      role="button"
      className="text-ds-h5 !pl-xs !pt-xs !font-bold text-black hover:text-primary-400"
    >
      {text}
    </div>
    {children}
  </div>
);
