import React from 'react';

import { DevisFactureTag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/DevisFactureTag';
import { DevisFactureStatuses } from '@travauxlib/shared/src/types';

type Props = {
  status: DevisFactureStatuses;
};

export const Status: React.FC<Props> = ({ status }) => (
  <div className="inline-flex flex-col items-center">
    <DevisFactureTag status={status} />
  </div>
);
