import React, { useState } from 'react';

import classNames from 'classnames';
import _groupBy from 'lodash/groupBy';
import { useFormState } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDown.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUp.svg?react';
import PaymentsDuoTone from '@travauxlib/shared/src/components/DesignSystem/assets/PaymentsDuoTone.svg?react';
import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { LayoutGrid } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Header } from '@travauxlib/shared/src/features/SuiviChantier/components/Header';
import { SuiviFooter } from '@travauxlib/shared/src/features/SuiviChantier/components/SuiviFooter';
import { SummaryLotRow } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi/components/MainSuivi/SummaryLotRow';
import { FormData, SuiviLot } from '@travauxlib/shared/src/features/SuiviChantier/types';
import {
  doesSuiviContainsAChange,
  getAvancementForLots,
  parseFormDataToGetLots,
} from '@travauxlib/shared/src/features/SuiviChantier/utils';
import { computeSuiviChantierAmounts } from '@travauxlib/shared/src/features/SuiviChantier/utils/computeSuiviChantierAmounts';

import { ConfirmationDemandePaiementModal } from './ConfirmationDemandePaiementModal';

import { useSuiviChantier } from '../../api/useSuiviChantier';

export const MainSuivi: React.FC = () => {
  const { uuid: chantierUuid } = useParams<{ uuid: string }>();
  const { suiviChantier, isLoading } = useSuiviChantier(chantierUuid!);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { values } = useFormState<FormData>();
  const navigate = useNavigate();

  if (isLoading || !suiviChantier) {
    return null;
  }

  const lots = parseFormDataToGetLots(values);
  const remiseMapByDevisToken = suiviChantier
    ? suiviChantier.devisList.reduce(
        (acc, devis) => ({
          ...acc,
          [devis.token]: devis.remises,
        }),
        {},
      )
    : [];
  const computed = computeSuiviChantierAmounts({
    lots: lots,
    remisesMapByDevisToken: remiseMapByDevisToken,
    montantRemise: suiviChantier.montantRemise,
    montantDejaPaye: suiviChantier.montantDejaPaye,
    totalMontantTVA: suiviChantier.totalMontantTVA,
  });

  const groupedLots: Record<string, SuiviLot[]> = _groupBy(lots, 'label');
  const isMontantALibererPositive = computed.montantALibererTTC > 0;

  return (
    <>
      <Header
        onClick={() => navigate(`/deals/${chantierUuid}/chantier`)}
        Icon={PaymentsDuoTone}
        title={`Chantier de ${suiviChantier.customerName}`}
        subTitle="Demander un paiement"
        pourcentageAvancement={getAvancementForLots(lots)}
        shouldDisplayInfoColor={doesSuiviContainsAChange(lots)}
      />
      {!isMontantALibererPositive && (
        <AlertMessage className="shadow-ds-md" level="warning">
          <div className="sm-desktop:text-ds-b1">
            Le montant d’avancement total du chantier doit être supérieur au montant déjà payé par
            le client pour libérer des fonds supplémentaires.
          </div>
        </AlertMessage>
      )}
      <LayoutGrid className="overflow-auto h-full !mx-0 grid-rows-[max-content]">
        <div className="col-span-full sm-desktop:col-start-2 m-md sm-desktop:my-xl sm-desktop:mx-0">
          <div className="text-ds-h4 sm-desktop:text-ds-h3 font-bold text-neutral-800">
            État d'avancement
          </div>
          <div className="text-ds-h5 font-medium text-neutral-700">
            Sélectionnez un lot du chantier
          </div>
        </div>
        <div className="col-span-full sm-desktop:col-span-6 sm-desktop:col-start-2 pb-md sm-desktop:flex sm-desktop:flex-col">
          {Object.entries(groupedLots).map(([lotLabel, lots]) => (
            <SummaryLotRow
              key={lotLabel}
              label={lotLabel}
              pourcentageAvancement={getAvancementForLots(lots)}
              onClick={() => navigate(lots[0].uuid)}
              devisNumbers={lots.map(lot => lot.devisNumber)}
              containsAChange={doesSuiviContainsAChange(lots)}
            />
          ))}
        </div>
        <SuiviFooter
          lots={lots}
          computed={computed}
          action={{
            label: 'Demander le paiement',
            disabled: !isMontantALibererPositive,
            onClick: () => setShowConfirmationModal(true),
          }}
          intervenant="pro"
        >
          {({ showDetails, setShowDetails }) => (
            <>
              <div className="flex items-center justify-between font-bold">
                <div className="flex items-center gap-xxs">
                  <div className="text-ds-b1">Montant à libérer</div>
                  <IconButton
                    className="inline-block sm-desktop:hidden"
                    dataTestId="show-details"
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    {showDetails ? <ChevronUp /> : <ChevronDown />}
                  </IconButton>
                </div>
                <EURCurrency
                  className={classNames('text-ds-h5', {
                    'text-warning-800': !isMontantALibererPositive,
                  })}
                  amount={computed.montantALibererTTC}
                />
              </div>
            </>
          )}
        </SuiviFooter>
      </LayoutGrid>
      <ConfirmationDemandePaiementModal
        isOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        montantALibererTTC={computed.montantALibererTTC}
        avancementGlobal={getAvancementForLots(lots)}
        customerName={suiviChantier.customerName}
      />
    </>
  );
};
