import React from 'react';

import { Link } from 'react-router-dom';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';

type Props = {
  label: string;
  link: string;
};

export const BackLink: React.FC<Props> = ({ label, link }) => (
  <div className="sm-desktop:hover:bg-lighter sm-desktop:border-r-1 border-light flex h-full pl-md sm-desktop:pr-0 pr-md max-w-full">
    <Link
      to={link}
      className="flex max-w-full items-center sm-desktop:pr-xs sm-desktop:pr-xmd !no-underline text-dark"
    >
      <ChevronLeft className="w-xl h-xl shrink-0" />
      <div className="ml-xs truncate max-w-full">{label}</div>
    </Link>
  </div>
);
