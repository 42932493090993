import { SuiviChantierCycleHistory } from '@travauxlib/shared/src/features/SuiviChantier/types';

type Payload = {
  suiviChantierToValidate?: SuiviChantierCycleHistory;
  hasAppelsDePaiementEnAttente: boolean;
  isWithTP: boolean;
};

export const getDisabledMessageReason = ({
  suiviChantierToValidate,
  hasAppelsDePaiementEnAttente,
  isWithTP,
}: Payload): string | undefined => {
  if (hasAppelsDePaiementEnAttente) {
    return 'Une demande de paiement est déjà en attente de validation';
  } else if (isWithTP && suiviChantierToValidate && !suiviChantierToValidate?.etapes.tp) {
    return 'Une demande de paiement est en attente de validation par le Travaux Planner';
  } else if (suiviChantierToValidate && !suiviChantierToValidate?.etapes.client) {
    return 'Une demande de paiement est en attente de validation par le client';
  } else {
    return undefined;
  }
};
