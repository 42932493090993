import { UserProfile } from '@travauxlib/shared/src/types';
import { ProUser } from '@travauxlib/shared/src/types/company';

import { useGetProCompanyUsers } from 'api/useGetProCompanyUsers';
import { useProfile } from 'api/useProfile';
import { ProCompany } from 'types';

export const useMultiUser = (): {
  profile: UserProfile<ProCompany>;
  proUsers: ProUser[];
  hasMultiUsersAccess: boolean;
  isLoading: boolean;
} => {
  const { profile, isLoading: profileIsLoading } = useProfile();
  const { proUsers, isLoading: proUsersIsLoading } = useGetProCompanyUsers();
  const hasMultiUsersAccess = !!(profile?.isProCompanyAdmin && proUsers.length > 1);

  return {
    profile,
    proUsers,
    hasMultiUsersAccess,
    isLoading: profileIsLoading || proUsersIsLoading,
  };
};
