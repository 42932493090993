import React from 'react';

import dayjs from 'dayjs';

import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { BonCommande, DevisStatuses } from '@travauxlib/shared/src/types';
import { toCalendarFormatForDateTime } from '@travauxlib/shared/src/utils/time';

type Props = {
  bonCommande: BonCommande;
};

const dateAccessor = (bonCommande: BonCommande): { label: string; value?: string } => {
  switch (bonCommande.status) {
    case DevisStatuses.sent:
      return { label: 'Envoyé', value: bonCommande.sentAt };
    default:
      return { label: 'Créé', value: bonCommande.createdAt };
  }
};

export const Date: React.FC<Props> = ({ bonCommande }) => {
  const { label, value } = dateAccessor(bonCommande);

  return (
    <WithTooltip
      trigger={
        <div className="whitespace-nowrap">
          {label} {toCalendarFormatForDateTime(dayjs(value))}
        </div>
      }
    >
      <div className="p-xs">
        Modifié {toCalendarFormatForDateTime(dayjs(bonCommande.updatedAt))}
      </div>
    </WithTooltip>
  );
};
