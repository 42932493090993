import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { CONSULTATION_OFFERS_KEY } from 'features/Deals/api/useProClientsAndConsultationOffers';
import { ConsultationOffer } from 'types';
import { dashboardUrl } from 'utils/urls';

import { PRO_CLIENTS_KEY } from '../../ProClients/api/useProClients';

const CONSULTATION_OFFER_KEY = 'consultation-offer';

export type RejectionPayload = {
  rejectionReason?: string;
  dispoDevis?: Dayjs;
  dispoTravaux?: Dayjs;
  estimatedBudget?: string;
};

const handleReject = ({
  token,
  rejectionReason,
  dispoDevis,
  dispoTravaux,
  estimatedBudget,
}: RejectionPayload & { token: string }): Promise<void> => {
  const requestURL = `${APP_CONFIG.proApiURL}/consultation-offers/${token}/reject`;
  const body = JSON.stringify({
    dispoDevis: dispoDevis && formatFrenchDate(dispoDevis),
    dispoTravaux: dispoTravaux && formatFrenchDate(dispoTravaux),
    estimatedBudget: estimatedBudget,
    rejectionReason,
  });

  return request(requestURL, {
    method: 'POST',
    body,
  });
};

const handleAccept = ({
  token,
  rdvAt,
}: {
  token: string;
  rdvAt?: string;
}): Promise<{ dealUuid: string }> => {
  const requestURL = `${APP_CONFIG.proApiURL}/consultation-offers/${token}/accept`;

  return request(requestURL, {
    method: 'POST',
    body: JSON.stringify({ rdvAt }),
  });
};

export const useConsultationOffer = ({
  token,
  source,
}: {
  token: string;
  source: string | null;
}): {
  consultationOffer?: ConsultationOffer;
  isLoading: boolean;
  isUpdating: boolean;
  accept: typeof handleAccept;
  reject: typeof handleReject;
} => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<ConsultationOffer>({
    queryKey: [CONSULTATION_OFFER_KEY, token],
    queryFn: () => {
      const requestURL = `${APP_CONFIG.proApiURL}/consultation-offers/${token}`;
      return request(requestURL, {
        params: {
          source,
        },
      });
    },
  });

  const { mutateAsync: reject, isPending: isRejecting } = useMutation({
    mutationFn: handleReject,
    onSuccess: () => {
      navigate(dashboardUrl);
      queryClient.invalidateQueries({ queryKey: [CONSULTATION_OFFERS_KEY] });
      queryClient.invalidateQueries({ queryKey: [CONSULTATION_OFFER_KEY, token] });
      toast.success('La proposition a bien été refusée');
    },
  });

  const { mutateAsync: accept, isPending: isAccepting } = useMutation({
    mutationFn: handleAccept,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONSULTATION_OFFERS_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRO_CLIENTS_KEY] });

      // accepting will trigger a redirect away from the consultationOffer so this should
      // not be a problem to not invalidate the query
      // otherwise there's annoying race conditions:
      // we want the ConsultationOffer component to redirect away from the consultationOffer
      // if there's a response
      // we also want to wait for the auto generated devis to be loaded
      // to show the dossier-consultation page
      toast.success('Proposition acceptée');
    },
    onError: () => {
      navigate(dashboardUrl);
      queryClient.invalidateQueries({ queryKey: [CONSULTATION_OFFERS_KEY] });
      toast.error('Cette proposition est expirée ou déjà acceptée');
    },
  });

  return {
    consultationOffer: data,
    isLoading,
    isUpdating: isRejecting || isAccepting,
    reject,
    accept,
  };
};
