import React from 'react';

import dayjs from 'dayjs';

import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { DevisStatuses } from '@travauxlib/shared/src/types';
import { toCalendarFormatForDateTime } from '@travauxlib/shared/src/utils/time';

import { useArchiType } from 'api/profileSelectors';
import { Devis } from 'types';

type Props = {
  devis: Devis;
};

const dateAccessor = (devis: Devis): { label: string; value?: string } => {
  if (devis.signature && devis.signature.deletedAt) {
    return { label: 'Annulé', value: devis.signature.deletedAt };
  }

  switch (devis.status) {
    case DevisStatuses.sent:
      return { label: 'Envoyé', value: devis.sentAt };
    case DevisStatuses.signed:
      return { label: 'Signé', value: devis.dateSignature };
    default:
      return { label: 'Créé', value: devis.createdAt };
  }
};

export const DevisCardDate: React.FC<Props> = ({ devis }) => {
  const archiType = useArchiType();
  const labelSuffix = archiType ? 'e' : '';

  const { label, value } = dateAccessor(devis);

  return (
    <WithTooltip
      trigger={
        <span>
          {label} {labelSuffix}
          {toCalendarFormatForDateTime(dayjs(value))}
        </span>
      }
      position="bottom"
    >
      {devis.status === DevisStatuses.draft ? (
        <div className="p-xs">
          Modifié{labelSuffix} {toCalendarFormatForDateTime(dayjs(devis.lastModifiedAt))}
        </div>
      ) : undefined}
    </WithTooltip>
  );
};
