import React, { FC } from 'react';

import { Form } from 'react-final-form';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { DevisStatuses } from '@travauxlib/shared/src/types';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useIsArchitecte } from 'api/profileSelectors';
import { useDeal } from 'features/Deals/api/useDeal';
import { useMoveDevis } from 'features/Deals/api/useMoveDevis';
import { useDevisList } from 'features/Devis/api/useDevisList';
import { useProClients } from 'features/ProClients/api/useProClients';
import { Devis } from 'types';

import { ProClientAndDealSelector } from './ProClientAndDealSelector';

type FormValues = {
  proClientUuid: string;
  newDealUuid: string;
  newProjectUuid: string;
};

type WrapperProps = {
  moveOrCopy: string;
  dealUuid: string;
  proClientUuid: string;
};

const ProClientAndDealSelectorWrapper: React.FC<WrapperProps> = ({
  moveOrCopy,
  dealUuid,
  proClientUuid,
}) => {
  const { proClients, isLoading } = useProClients();
  const deals = proClients.find(proClient => proClient.uuid === proClientUuid)?.deals || [];
  const { devisList, isLoaded } = useDevisList({
    dealUuid: deals.length === 1 ? deals[0].uuid : dealUuid,
  });

  if (isLoading || !isLoaded) {
    return <LoaderWrapper />;
  }
  return (
    <ProClientAndDealSelector
      proClients={proClients}
      deals={deals}
      devisList={devisList}
      label={`${moveOrCopy} vers le client`}
    />
  );
};

type Props = {
  devisToMove: Devis;
  handleClose: () => void;
};

export const MoveDevisModal: FC<Props> = ({ devisToMove, handleClose }) => {
  const moveOrCopy = devisToMove.status === DevisStatuses.draft ? 'Déplacer' : 'Dupliquer';
  const isArchitecte = useIsArchitecte();
  const { moveDevis } = useMoveDevis(devisToMove);
  const deal = useDeal(devisToMove.dealUuid);

  return (
    <Form<FormValues>
      initialValuesEqual={() => true}
      initialValues={{ proClientUuid: deal?.proClientUuid, newDealUuid: deal?.uuid }}
      onSubmit={async values => {
        await moveDevis({
          ...values,
          newProjectUuid: values.newProjectUuid || undefined,
        });

        return handleClose();
      }}
    >
      {({ handleSubmit, submitting, values }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: `${moveOrCopy} ${devisOrProposition({ isArchitecte, prefix: 'le' })}`,
            type: 'submit',
            disabled: submitting,
          }}
        >
          <ProClientAndDealSelectorWrapper
            moveOrCopy={moveOrCopy}
            proClientUuid={values.proClientUuid}
            dealUuid={values.newDealUuid}
          />
        </ModalContent>
      )}
    </Form>
  );
};
