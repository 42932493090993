import { DevisStatuses } from '@travauxlib/shared/src/types';

export const devisSearchFilters = [
  {
    value: DevisStatuses.signed,
    label: 'Signés',
  },
  {
    value: DevisStatuses.sent,
    label: 'Envoyés',
  },
  {
    value: DevisStatuses.to_be_revised,
    label: 'À réviser',
  },
  {
    value: DevisStatuses.draft,
    label: 'Brouillons',
  },
  {
    value: DevisStatuses.rejected,
    label: 'Refusés',
  },
  {
    value: DevisStatuses.canceled,
    label: 'Annulés',
  },
];
