import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { DEVIS_LIST_KEY } from 'features/Devis/api/useDevisList';
import { Devis } from 'types';

export type TransfertDevisToAnotherProPayload = {
  newDealUuid: string;
  newProjectUuid?: string;
  shouldResetPrices?: boolean;
  shouldTransferIntroductionLetter?: boolean;
};

const handleTransferToPro =
  (devisToTransfer: Devis) => (body: TransfertDevisToAnotherProPayload) => {
    const requestURL = `${APP_CONFIG.proApiURL}/deals/${devisToTransfer.dealUuid}/devis/${devisToTransfer.token}/transfer-to-pro`;

    return request(requestURL, {
      method: 'PUT',
      body,
    });
  };

export const useTransferToPro = (
  devisToTransfer: Devis,
): { transferToPro: ReturnType<typeof handleTransferToPro>; isLoading: boolean } => {
  const queryClient = useQueryClient();

  const { mutateAsync: transferToPro, isPending } = useMutation({
    mutationFn: handleTransferToPro(devisToTransfer),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [DEVIS_LIST_KEY] });
      toast.success('Le transfert a réussi !');
    },
    onError: () => {
      toast.error("Le transfert n'a pas eu lieu");
    },
  });

  return {
    transferToPro,
    isLoading: isPending,
  };
};
