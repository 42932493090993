import { useNavigate } from 'react-router-dom';

import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import {
  ConversationInformationModal,
  ConversationInformationModalProps,
} from '../components/ConversationInformationModal';

export const useOpenConversationInfoModal = (): ((
  props: ConversationInformationModalProps,
) => void) => {
  const openModal = useOpenModal();
  const navigate = useNavigate();

  return ({ dealUuid, profile, activeTab }: ConversationInformationModalProps) => {
    if (activeTab?.category) {
      navigate({ search: activeTab.category });
    }
    openModal(ConversationInformationModal, {
      dealUuid,
      profile,
      activeTab,
      closable: true,
    });
  };
};
