import React from 'react';

import classNames from 'classnames';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import DrawOutline from '@travauxlib/shared/src/components/DesignSystem/assets/DrawOutline.svg?react';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';

type Prop = {
  isFacturationClotured: boolean;
  isDevisSigned: boolean;
};

export const FactureHeaderDevisStatus: React.FC<Prop> = ({
  isFacturationClotured,
  isDevisSigned,
}) => (
  <div className="flex items-center gap-xxs">
    <WithTooltip
      position="top"
      width="8rem"
      trigger={
        <span className="flex items-center">
          <DrawOutline
            className={classNames('text-neutral-300 relative w-lg h-lg ml-xs', {
              '!text-info ': isDevisSigned,
            })}
          />
        </span>
      }
    >
      <div className="text-ds-b2">
        {isDevisSigned ? 'Votre devis est signé' : "Votre devis n'est pas signé"}
      </div>
    </WithTooltip>
    <WithTooltip
      width="7rem"
      trigger={
        <span className="flex items-center">
          <CheckCircle
            className={classNames('text-neutral-300 w-lg h-lg', {
              '!text-success-400 ': isFacturationClotured,
            })}
          />
        </span>
      }
    >
      <div className="text-ds-b2">
        {isFacturationClotured ? 'Facturation complètée' : 'Facturation incomplète'}
      </div>
    </WithTooltip>
  </div>
);
