import React from 'react';

import { useNavigate } from 'react-router';

import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { parametresAbonnementPlanUrl } from 'utils/urls';

export const NotEnoughPermissionsModal: React.FC<{ handleClose: () => void }> = ({
  handleClose,
}) => {
  const navigate = useNavigate();

  const onClose = (): void => {
    handleClose();
    navigate(parametresAbonnementPlanUrl);
  };

  return (
    <ModalContent
      title="Fonctionnalité indisponible"
      validateAction={{
        label: 'Mettre à niveau',
        onClick: onClose,
      }}
    >
      <div className="mb-md">
        Vous n'avez pas accès à cette fonctionnalité avec votre abonnement actuel.
      </div>
      <div>Passez à l'offre supérieure pour la débloquer !</div>
    </ModalContent>
  );
};

export const useOpenNotEnoughPermissionsModal = (): (() => void) => {
  const openModal = useOpenModal();

  return () => {
    openModal(NotEnoughPermissionsModal, {
      closable: true,
    });
  };
};
