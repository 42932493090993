import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { RELANCES_KEY } from 'features/Deals/api/useRelances';
import { Devis } from 'types';

import { DEVIS_KEY } from './useDevis';
import { DEVIS_LIST_KEY } from './useDevisList';

const handleSetRelanceSeen = ({
  dealUuid,
  token,
}: {
  dealUuid: string;
  token: string;
}): Promise<Devis> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/devis/${token}/relance-seen`;
  return request(requestURL, {
    method: 'PATCH',
  });
};

export const useSetRelanceSeen = (): typeof handleSetRelanceSeen => {
  const queryClient = useQueryClient();

  const { mutateAsync: sendDevisEmail } = useMutation({
    mutationFn: handleSetRelanceSeen,
    onSuccess: (devis: Devis) => {
      queryClient.setQueryData([DEVIS_KEY, devis.dealUuid, devis.token], devis);
      queryClient.invalidateQueries({ queryKey: [DEVIS_LIST_KEY, devis.dealUuid] });
      queryClient.invalidateQueries({ queryKey: [RELANCES_KEY] });
    },
  });

  return sendDevisEmail;
};
