import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SuiviChantier } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { SUIVI_KEY } from './useGetSuiviChantier';

const handleUpdateSuiviChantier = async (suiviChantier: SuiviChantier): Promise<SuiviChantier> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${suiviChantier.dealUuid}/suivi-chantier/${suiviChantier.token}`;
  await request(requestURL, {
    method: 'PUT',
    body: suiviChantier,
  });
  return suiviChantier;
};

export const useUpdateSuiviChantier = (): {
  updateSuiviChantier: typeof handleUpdateSuiviChantier;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleUpdateSuiviChantier,
    onSuccess: ({ dealUuid, token }: SuiviChantier) => {
      queryClient.invalidateQueries({ queryKey: [SUIVI_KEY, dealUuid, token] });
    },
    onError: () => {
      toast.error("Le suivi n'a pas pu être mis à jour");
    },
  });
  return { updateSuiviChantier: mutateAsync, isLoading: isPending };
};
