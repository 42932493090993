import React from 'react';

import classNames from 'classnames';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { SocieteInfoCompany } from 'api/useSearchCompany';

export const CompanyFoundBlock: React.FC<{ companyFound: SocieteInfoCompany }> = ({
  companyFound,
}) => (
  <>
    <div
      className={classNames('mb-md px-md sm-desktop:px-0', {
        '!text-error': companyFound.alreadyRegistered,
      })}
    >
      Nous avons trouvé <b>1 entreprise</b> correspondante{' '}
      {companyFound.alreadyRegistered && <b>déjà inscrite chez nous</b>}
    </div>
    <div className="px-md sm-desktop:px-0">
      <div
        className={classNames(
          'mb-md p-md rounded w-full border-1',
          companyFound.alreadyRegistered ? 'bg-neutral-100' : 'bg-white border-primary',
        )}
      >
        <div className="flex">
          <div
            className={classNames(
              'w-lg h-lg mr-xs',
              companyFound.alreadyRegistered ? 'text-neutral-300' : 'text-primary',
            )}
          >
            <CheckCircle />
          </div>

          <div>
            <div
              className={classNames(
                'font-bold',
                companyFound.alreadyRegistered ? 'text-neutral-300' : 'text-neutral-800',
              )}
            >
              {companyFound.companyName}
            </div>
            <div
              className={classNames(
                companyFound.alreadyRegistered ? 'text-neutral-300' : 'text-neutral-700',
              )}
            >
              {(companyFound.address || companyFound.postalCode || companyFound.city) && (
                <>
                  <div>{companyFound.address}</div>
                  <div>
                    {' '}
                    {companyFound.postalCode} {companyFound.city}
                  </div>
                </>
              )}
              <div>Siret : {companyFound.siret}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="px-md sm-desktop:px-0 mb-md">
      <Button
        variant="secondary"
        fullwidth
        onClick={async () => {
          await window.$crisp.push([
            'set',
            'message:text',
            [
              "Bonjour, je n'arrive pas trouver mon entreprise à l'aide de mon SIRET. Pouvez-vous m'aider ?",
            ],
          ]);
          window.$crisp.push(['do', 'chat:open']);
        }}
      >
        Je ne vois pas mon entreprise
      </Button>
    </div>
  </>
);
