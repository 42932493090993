import fileDownload from 'js-file-download';
import flatMap from 'lodash/flatMap';

import { Ligne, Lot, TextItem } from '@travauxlib/shared/src/types';
import { formatNumber } from '@travauxlib/shared/src/utils/format';

function convertArrayOfObjectsToCSV(
  args: Array<{ [K in string]: string | number | undefined | null }>,
  fields: string[],
): string | null {
  if (!args.length) {
    return null;
  }

  const columnDelimiter = ';';
  const lineDelimiter = '\n';

  let result = '';
  result += fields.join(columnDelimiter);
  result += lineDelimiter;

  args.forEach(item => {
    let ctr = 0;
    fields.forEach(key => {
      if (ctr > 0) {
        result += columnDelimiter;
      }
      const value = item[key];
      if (value == null) {
        result += '""';
      } else {
        let content = value.toString().split('\n').join(' ');
        content = content.toString().split('"').join('""');
        result += `"${content}"`;
      }
      ctr += 1;
    });
    result += lineDelimiter;
  });

  return result;
}

export function exportLinesAsCsv(lots: Lot[], prixTotalHT: number): string {
  const items: Array<{
    numero: string;
    designation?: string;
    description?: string;
    prixHT?: number;
    quantite?: number;
    unite?: string;
    prixUnitaireHT?: number;
    tauxTVA?: number;
    montantTVA?: number;
  }> = flatMap(lots, (lot, lotIndex) => [
    { designation: lot.label, numero: `${lotIndex + 1}.0` },
    ...lot.items
      .filter(l => l.type === 'ligne' || l.type === 'text')
      .map((item: Ligne | TextItem) => {
        const itemIndex = lot.items
          .filter(l => l.type === 'ligne')
          .findIndex(l => l.uuid === item.uuid);

        return {
          ...item,
          description: item.type === 'text' ? item.text : item.description,
          numero: item.type === 'text' ? '' : `${lotIndex + 1}.${itemIndex + 1}`,
        };
      }),
  ]);
  const prixTotalLigne = {
    prixHT: `Total HT : ${prixTotalHT}`,
  };
  const itemsToExport = items.map(
    ({
      designation,
      description,
      prixHT,
      quantite,
      unite,
      prixUnitaireHT,
      numero,
      tauxTVA,
      montantTVA,
    }) => ({
      numero,
      designation,
      description,
      prixHT: prixHT && formatNumber(prixHT),
      quantite: quantite && formatNumber(quantite),
      unite: unite || null,
      prixUnitaireHT: prixUnitaireHT && formatNumber(prixUnitaireHT),
      tauxTVA,
      montantTVA: montantTVA && formatNumber(montantTVA),
    }),
  );

  let csv = convertArrayOfObjectsToCSV(
    [...itemsToExport, prixTotalLigne],
    [
      'numero',
      'designation',
      'description',
      'quantite',
      'unite',
      'prixUnitaireHT',
      'prixHT',
      'tauxTVA',
      'montantTVA',
    ],
  );

  if (csv !== null) {
    csv = `\ufeff${csv}`; // UTF-8 BOM
  }

  return csv || '';
}

export function downloadAsCsv(lots: Lot[], prixTotalHT: number, filename: string): void {
  const data = exportLinesAsCsv(lots, prixTotalHT);

  return fileDownload(data, filename);
}
