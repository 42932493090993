import { S3File } from '@travauxlib/shared/src/types';

import { DealNote, Message, MessageAttachment } from 'types';

function s3ToAttachment(s3: S3File[]): MessageAttachment[] {
  return s3.map(file => ({
    uuid: file.uuid,
    url: file.downloadUrl,
    filekey: file.key,
    filename: file.name,
  }));
}

export function notesToMessages(notes: DealNote[] = []): Message[] {
  return notes.map(note => ({
    content: note.content,
    createdAt: note.createdAt,
    attachments: s3ToAttachment(note.files),
    uuid: note.createdAt,
  }));
}
