import React from 'react';

import { Form } from 'react-final-form';

import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';

type Props = {
  configuration: Configuration;
  children: React.ReactElement;
};

export const EmptyConfigurationForm: React.FC<Props> = ({ configuration, children }) => (
  <Form
    initialValues={{ ...configuration, disabled: true, hideUnfilled: true }}
    onSubmit={() => {}}
  >
    {({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
  </Form>
);
