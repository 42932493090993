import React from 'react';

import { Form } from 'react-final-form';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import {
  useOpenModal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { required } from '@travauxlib/shared/src/utils/form';

import { Devis } from 'types';

import { useUserTemplatesList } from '../api/useUserTemplatesList';

type FormValues = {
  title: string;
};

type Props = {
  handleClose: () => void;
  devis: Devis;
};

export const SaveDevisTemplateModal: React.FC<Props> = ({ devis, handleClose }) => {
  const { create } = useUserTemplatesList();
  return (
    <Form<FormValues>
      onSubmit={async ({ title }) => {
        await create({ ...devis, title });
        handleClose();
      }}
      initialValues={{ title: devis.title }}
    >
      {({ handleSubmit, submitting, invalid }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Valider',
            loading: submitting,
            disabled: submitting || invalid,
            type: 'submit',
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: handleClose,
            disabled: submitting,
          }}
        >
          <div className="p-md">
            <InputField id="modeleName" name="title" validate={required} label="Nom du modèle" />
          </div>
        </ModalContent>
      )}
    </Form>
  );
};

export const useOpenSaveDevisAsTemplateModal = (): ((devis: Devis) => void) => {
  const openModal = useOpenModal();
  return (devis: Devis) =>
    openModal(SaveDevisTemplateModal, {
      devis,
      title: 'Quel nom voulez-vous donner à votre modèle ?',
    });
};
