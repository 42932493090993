import React, { useMemo } from 'react';

import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import {
  ActionList,
  ActionListItem,
} from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { BonCommande } from '@travauxlib/shared/src/types';

import { useArchiveBonCommande } from 'features/BonCommande/api/useArchiveBonCommande';
import { useUnarchiveBonCommande } from 'features/BonCommande/api/useUnarchiveBonCommande';
import { useDuplicateBonCommande } from 'features/Devis/api/useDuplicateBonCommande';
import { getPrintBonCommandeUrl } from 'utils/urls';

type Props = {
  bonCommande: BonCommande;
};

export const BonCommandeActions: React.FC<Props> = ({ bonCommande }) => {
  const archiveBonCommande = useArchiveBonCommande();
  const unarchiveBonCommande = useUnarchiveBonCommande();
  const { duplicateBonCommande } = useDuplicateBonCommande();

  const items: ActionListItem[] = useMemo(
    () => [
      {
        type: 'action',
        label: 'Télécharger en PDF',
        onClick: () => {
          window.open(getPrintBonCommandeUrl(bonCommande), '_blank');
        },
      },
      {
        type: 'action',
        label: 'Dupliquer pour modifier',
        onClick: () => duplicateBonCommande(bonCommande),
      },
      {
        type: 'action',
        label: bonCommande.archived ? 'Restaurer...' : 'Archiver...',
        onClick: () =>
          bonCommande.archived
            ? unarchiveBonCommande(bonCommande)
            : archiveBonCommande(bonCommande),
      },
    ],
    [bonCommande.archived],
  );

  return (
    <ActionList
      trigger={(toggle, referenceProps) => (
        <IconButton onClick={toggle} {...referenceProps} data-testid="open-bonCommande-actions">
          <DotsMenu />
        </IconButton>
      )}
      items={items}
    />
  );
};
