import React, { FC, useCallback } from 'react';

import classNames from 'classnames';

import Cross from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { getInitials } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar/getInitials';

import { ConversationParticipant } from 'types';

export type ParticipantProps = {
  participant: ConversationParticipant;
  isDisabled?: boolean;
  onClick?: (participant: ConversationParticipant) => void;
  onRemove?: (participant: ConversationParticipant) => void;
  withDetail?: boolean;
};

export const Participant: FC<ParticipantProps> = ({
  participant,
  onClick,
  onRemove,
  withDetail = false,
  isDisabled = false,
}) => {
  const isEditable = participant.uuid;
  const handleClick = useCallback(() => {
    onClick?.(participant);
  }, [participant, onClick]);

  const handleRemove = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onRemove?.(participant);
    },
    [participant, onRemove],
  );

  return (
    <div
      role="button"
      onClick={handleClick}
      onKeyPress={handleClick}
      tabIndex={0}
      key={participant.email}
      className={classNames('!relative !mr-sm', {
        '!flex !py-xs': withDetail,
        'p-0 bg-transparent !cursor-pointer': !!isDisabled,
      })}
    >
      <Avatar text={getInitials(participant.name)} size="lg" variant="green" />
      {isEditable && (
        <div
          className={classNames(
            'absolute rounded-full flex justify-center items-center z-10 w-[1.25rem] h-[1.25rem] top-0 -right-3xs',
            {
              'top-1/2 right-0 transform -translate-y-1/2': withDetail,
              'bg-neutral-200': !withDetail,
            },
          )}
        >
          <Cross className="h-lg w-lg cursor-pointer" data-testid="delete" onClick={handleRemove} />
        </div>
      )}
      {withDetail && (
        <div className="flex-grow !flex !flex-col !justify-center !text-left !pl-sm">
          <span className="!font-bold">{participant.name}</span>
          <span>{participant.email}</span>
        </div>
      )}
    </div>
  );
};
