import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { ClubProFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class SuiviDeChantierTrackingClass extends Tracker<ClubProFeatures> {
  onSuiviDeChantierCreated(properties: { uuid: string }): void {
    this.sendTracking({ event: TrackingEvents.Created, properties });
  }

  onSuiviDeChantierSent(properties: { uuid: string }): void {
    this.sendTracking({ event: TrackingEvents.Sent, properties });
  }

  onSuiviDeChantierOpened(properties: { uuid: string }): void {
    this.sendTracking({ event: TrackingEvents.Opened, properties });
  }
}

export const SuiviDeChantierTracking = new SuiviDeChantierTrackingClass(
  ClubProFeatures.SuiviDeChantier,
);
