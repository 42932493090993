import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { ProClient } from 'types';

import { PRO_CLIENT_KEY } from './useProClient';
import { PRO_CLIENTS_KEY } from './useProClients';

const handleUpdateProClient = (
  proClient: ProClient & { sameBillingAndChantierAddress?: boolean },
): Promise<ProClient> => {
  const requestURL = `${APP_CONFIG.proApiURL}/clients/${proClient.uuid}`;
  return request(requestURL, {
    method: 'PUT',
    body: proClient,
  });
};

export const useUpdateProClient = (): typeof handleUpdateProClient => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateProClient } = useMutation({
    mutationFn: handleUpdateProClient,
    onSuccess: (proClient: ProClient) => {
      queryClient.invalidateQueries({ queryKey: [PRO_CLIENTS_KEY] });
      queryClient.invalidateQueries({ queryKey: [PRO_CLIENT_KEY, proClient.uuid] });
    },
    onError: () => {
      toast.error("Le client n'a pas pu être modifié");
    },
  });

  return updateProClient;
};
