import { Ligne } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { Devis, DevisTemplate } from 'types';

export const useGetDevisFromDevisTemplate =
  () =>
  async (devisTemplate: DevisTemplate): Promise<Devis> => {
    const requestURL = `${APP_CONFIG.proApiURL}/devis-templates/${devisTemplate.uuid}`;
    const result: DevisTemplate = await request(requestURL);

    return {
      title: result.title,
      lots:
        result.lots?.map(({ label, items }) => ({
          label,
          items: items
            .filter(({ type }) => type === 'ligne')
            .map((ligne: Ligne) => ({ ...ligne, quantite: 1 })),
        })) || [],
    } as Devis;
  };
