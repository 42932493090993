import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { LAST_FACTURES_KEY } from 'features/Deals/features/Factures/api/useLastFacture';
import { Facture } from 'types';
import { FactureTracking } from 'utils/tracking/FactureTracking';

import { FACTURES_KEY } from './useFacturesList';

const handleCreateSoldeToutCompte = ({
  dealUuid,
  proDevisToken,
}: {
  dealUuid: string;
  proDevisToken: string;
}): Promise<Facture> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/factures/solde`;
  return request(requestURL, {
    method: 'POST',
    body: {
      proDevisToken,
    },
  });
};

export const useCreateSoldeToutCompte = (): {
  createSoldeToutCompte: typeof handleCreateSoldeToutCompte;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: createSoldeToutCompte, isPending } = useMutation({
    mutationFn: handleCreateSoldeToutCompte,
    onSuccess: (facture: Facture) => {
      FactureTracking.onFactureCreated({
        type: 'Totalité',
        token: facture.token,
        devisToken: facture.proDevisToken,
      });
      queryClient.invalidateQueries({ queryKey: [FACTURES_KEY, facture.dealUuid] });
      queryClient.invalidateQueries({ queryKey: [LAST_FACTURES_KEY, facture.dealUuid] });
    },
    onError: () => {
      toast.error("La facture n'a pas pu être créée");
    },
  });

  return { createSoldeToutCompte, isLoading: isPending };
};
