import { subject } from '@casl/ability';
import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { usePermissions } from 'hooks/usePermissions';
import { Notification } from 'types';

export const NOTIFICATIONS_KEY = 'notifications';

export const useNotifications = (): { notifications: Notification[]; isLoaded: boolean } => {
  const { can } = usePermissions();
  const { data: notifications = [], isLoading } = useQuery<Notification[]>({
    queryKey: [NOTIFICATIONS_KEY],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.proApiURL}/notifications`;
      const { data } = await request(requestURL);

      if (!data) {
        return [];
      }

      /**
       * While waiting for a backend implementation, we filter the data here
       * To remove when api is done
       */
      return data.filter((notification: Notification) =>
        can(
          'read',
          subject('Notification', {
            isHemea: notification.type === 'devis-seen' && notification.content.isHemea,
          }),
        ),
      );
    },
  });

  return {
    notifications,
    isLoaded: !isLoading,
  };
};
