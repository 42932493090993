import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { BaseDePrixLigne, OriginType } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { normalizeDesignationForSearch } from 'features/BaseDePrix/utils/normalizeDesignationForSearch';

import { baseDePrixBaseURL } from '../config';
import { UseGetBaseDePrixParameters } from '../types';

enum OriginEnum {
  self = 'self',
  hemea = 'hemea',
}

export const BASE_DE_PRIX_KEY = 'base-de-prix';

export const useGetBaseDePrix = ({ origins, favorites }: UseGetBaseDePrixParameters = {}): {
  lines: BaseDePrixLigne[];
  lots: string[];
  isLoading: boolean;
} => {
  const { data = [], isLoading } = useQuery<BaseDePrixLigne[]>({
    queryKey: [BASE_DE_PRIX_KEY, origins, favorites],
    queryFn: async () => {
      const allOrigins: OriginType[] = Object.values(OriginEnum);
      const { data }: { data: BaseDePrixLigne[] } = await request(baseDePrixBaseURL, {
        params: { origins: origins || allOrigins, favorites },
      });

      return data.map(item => ({
        ...item,
        normalizedForSearchDesignation: normalizeDesignationForSearch(item.designation),
      }));
    },
    staleTime: Infinity,
  });

  const lots = new Set(useMemo(() => data.map(line => line.lot), [data]));

  return {
    lines: data,
    lots: [...lots],
    isLoading,
  };
};
