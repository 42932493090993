import React from 'react';

import {
  ProgressBar,
  ProgressBarValue,
} from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { getDetailedDevisFacturationStatus } from '@travauxlib/shared/src/utils/facture/getDetailedDevisFacturationStatus';

import { Devis, Facture } from 'types';

type Props = {
  devis: Devis;
  facturesList: Facture[];
};

export const FactureProgressBars: React.FC<Props> = ({ devis, facturesList }) => {
  const {
    totalMontantDejaPayeTTC,
    percentageDejaPayeTTC,
    totalMontantEnAttentePaiementTTC,
    percentageEnAttentePaiementTTC,
    totalMontantRestantAFacturerTTC,
    percentageRestantAFacturerTTC,
    referenceAmountTTC,
    isClotured,
  } = getDetailedDevisFacturationStatus(facturesList, devis);

  let progressbarValues: ProgressBarValue[] = [];

  if (totalMontantDejaPayeTTC > 0) {
    progressbarValues = [
      ...progressbarValues,
      {
        variant: 'success',
        value: percentageDejaPayeTTC,
        text: <EURCurrency amount={totalMontantDejaPayeTTC} suffix=" payé" />,
      },
    ];
  }

  if (totalMontantEnAttentePaiementTTC > 0) {
    progressbarValues = [
      ...progressbarValues,
      {
        variant: 'warning',
        value: percentageEnAttentePaiementTTC,
        text: (
          <EURCurrency
            className="text-neutral-900"
            amount={totalMontantEnAttentePaiementTTC}
            suffix=" en attente"
          />
        ),
      },
    ];
  }

  if (totalMontantRestantAFacturerTTC > 0) {
    progressbarValues = [
      ...progressbarValues,
      {
        variant: 'info',
        value: percentageRestantAFacturerTTC,
        text: <EURCurrency amount={totalMontantRestantAFacturerTTC} suffix=" à facturer" />,
      },
    ];
  }

  return (
    <div className="flex items-center mb-xs flex-col sm-desktop:flex-row">
      <ProgressBar size="lg" className="w-full" value={progressbarValues} />
      <EURCurrency
        className="text-ds-b1 font-bold sm-desktop:ml-lg mt-xs sm-desktop:mt-0"
        amount={referenceAmountTTC}
        prefix={isClotured ? 'Total TTC : ' : 'Objectif TTC : '}
      />
    </div>
  );
};
