import { Devis, DevisTemplate } from 'types';

export const getEmptyDevis = (isArchitecte?: boolean): Partial<Devis> => ({
  title: isArchitecte ? "Proposition d'honoraires" : 'Devis',
  lots: [],
});

export const duplicateDevis = ({ token, ...devis }: Devis, devisList: Devis[]): Devis => {
  const version = Math.max(...devisList.map(({ version }) => version)) + 1;
  const title = `${devis.title.replace(/ - version \d+$/, '')} - version ${version}`;

  return {
    ...devis,
    numero: undefined,
    archived: false,
    title,
    version,
  } as any as Devis;
};

export const isDevisTemplate = (
  devisOrDevisTemplate: Devis | DevisTemplate,
): devisOrDevisTemplate is DevisTemplate =>
  (devisOrDevisTemplate as DevisTemplate).uuid !== undefined;
