import React, { useEffect } from 'react';

import classNames from 'classnames';
import _partition from 'lodash/partition';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import SearchCalendarPicto from '@travauxlib/shared/src/components/DesignSystem/assets/SearchCalendarPicto.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { SearchBar } from '@travauxlib/shared/src/components/DesignSystem/components/SearchBar';
import { DevisLocation, Ligne, Lot } from '@travauxlib/shared/src/types';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useCreateDevis } from 'features/Devis/api/useCreateDevis';
import { useUpdateDevis } from 'features/Devis/api/useUpdateDevis';
import { Devis } from 'types';
import { getDevisTSUrl } from 'utils/urls';

import { CheckboxLigneCard } from './CheckboxLigneCard';
import { CheckboxLotCard } from './CheckboxLotCard';

type Props = {
  isArchitecte?: boolean;
  dealUuid: string;
  search: string;
  setSearch: (search: string) => void;
  lotsToDisplay: Array<Omit<Lot, 'items'> & { index: number; items: Ligne[] }>;
  shouldDisplayLigne: (ligne: Ligne) => boolean;
  getLotFromCheckedLignes: (ligneUuids: string[]) => Lot[];
  handleClose: () => void;
  currentDevisTS?: Devis;
  locations: DevisLocation[];
};

type FormData = { checkedLigneUuids: string[] };

export const CreateTSDevisModalContent: React.FC<Props> = ({
  isArchitecte,
  dealUuid,
  search,
  setSearch,
  lotsToDisplay,
  shouldDisplayLigne,
  getLotFromCheckedLignes,
  handleClose,
  currentDevisTS,
  locations,
}) => {
  const { createDevis } = useCreateDevis();
  const updateDevis = useUpdateDevis({ addToHistory: true });
  const navigate = useNavigate();

  // Little hack to force reload of shadow
  useEffect(() => {
    const modalBody = document.getElementById('modal-body');

    modalBody?.scrollTo(0, 1);
  }, [lotsToDisplay]);

  const pluralSuffix = lotsToDisplay.length > 1 ? 's' : '';
  const ligneUuidsAlreadyCheckedInTS = currentDevisTS
    ? currentDevisTS.lots
        .flatMap(lot => lot.items)
        .filter(ligne => ligne.type === 'ligne' && ligne.linkedToDevisItemUuid)
        .map((ligne: Ligne) => ligne.linkedToDevisItemUuid!)
    : [];

  const handleCreateOrUpdateDevis = async ({ checkedLigneUuids }: FormData): Promise<void> => {
    const [existingUuidsInDevis, uuidsToAddInDevis] = _partition(checkedLigneUuids, uuid =>
      ligneUuidsAlreadyCheckedInTS.includes(uuid),
    );
    const uuidsToDeleteInDevis = ligneUuidsAlreadyCheckedInTS.filter(
      uuid => !uuidsToAddInDevis.includes(uuid) && !existingUuidsInDevis.includes(uuid),
    );

    const addedLots = getLotFromCheckedLignes(uuidsToAddInDevis);

    if (currentDevisTS) {
      const updatedCurrentDevisTS = {
        ...currentDevisTS,
        lots: currentDevisTS.lots
          .map(lot => ({
            ...lot,
            items: lot.items.filter(
              item =>
                item.type !== 'ligne' ||
                !item.linkedToDevisItemUuid ||
                !uuidsToDeleteInDevis.includes(item.linkedToDevisItemUuid),
            ),
          }))
          .concat(addedLots)
          .filter(lot => lot.items.length > 0),
      };

      await updateDevis({ ...updatedCurrentDevisTS, isTS: true });
    } else {
      const newDevisTS = {
        title: 'Travaux supplémentaires',
        projectTitle: 'Travaux supplémentaires',
        lots: addedLots,
        locations: locations,
        isTS: true,
      };

      await createDevis(
        { dealUuid, devis: newDevisTS },
        { onSuccess: devis => navigate(getDevisTSUrl(devis)) },
      );
    }

    handleClose();
  };
  const noLotsToDisplay = lotsToDisplay.length <= 0;

  return (
    <Form<FormData>
      onSubmit={handleCreateOrUpdateDevis}
      initialValues={{ checkedLigneUuids: ligneUuidsAlreadyCheckedInTS }}
      initialValuesEqual={() => true}
    >
      {({ handleSubmit, values: { checkedLigneUuids }, submitting, form }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            type: 'submit',
            label: 'Valider',
            disabled: submitting || checkedLigneUuids.length === 0,
            loading: submitting,
          }}
          cancelAction={
            currentDevisTS
              ? undefined
              : {
                  label: 'Passer',
                  onClick: () => {
                    form.reset();
                    form.submit();
                  },
                  disabled: submitting,
                  loading: submitting,
                }
          }
        >
          <>
            {/* minus 1px to avoid scrolling content to appear */}
            <div className="sticky top-0 bg-neutral-0 z-10 mt-[-1px]">
              <div className="hidden tablet:block text-ds-b2 text-neutral-700 mb-md">
                Sélectionnez les lots ou les prestations que vous souhaitez modifier ou supprimer
                dans{' '}
                {devisOrProposition({
                  isArchitecte,
                  prefix: 'un',
                })}{' '}
                de travaux supplémentaires.
                <br />
                {currentDevisTS
                  ? 'Vous pouvez désélectionner les prestations que vous ne souhaitez plus modifier'
                  : 'Vous pourrez dans un second temps ajouter de nouveaux lots et/ou nouvelles prestations.'}
                <br />
                <br />
                Pour toute question, consultez{' '}
                <Link
                  target="_blank"
                  href="https://hemea.crisp.help/fr/article/creation-de-devis-de-travaux-supplementaires-uj1aq9/?bust=1702653608336"
                >
                  notre guide
                </Link>
                .
              </div>
              <SearchBar
                className="mb-xxs"
                placeholder="Rechercher un lot ou une prestation"
                fullWidth
                onChange={setSearch}
                value={search}
              />
              <div className="text-ds-sm text-neutral-600 pb-md border-b">
                {lotsToDisplay.length} lot{pluralSuffix} affiché{pluralSuffix}
              </div>
            </div>
            <div
              className={classNames('flex flex-col gap-md tablet:h-[19.5rem]', {
                'justify-center': noLotsToDisplay,
              })}
            >
              <Field
                id="checkedLigneUuids"
                name="checkedLigneUuids"
                render={({ input: { onChange, value } }) =>
                  noLotsToDisplay ? (
                    <EmptyState
                      illustration={<SearchCalendarPicto />}
                      iconIllustrationSize="md"
                      description="Vous ne trouvez pas une prestation ? Vérifiez l'orthographe du mot saisi."
                    />
                  ) : (
                    lotsToDisplay.map(lot => {
                      const lotIndexLabel = (lot.index + 1).toString();
                      const lignesToDisplay = lot.items.filter(ligne => shouldDisplayLigne(ligne));

                      return (
                        <div key={lot.uuid}>
                          <CheckboxLotCard
                            lot={lot}
                            indexLabel={lotIndexLabel}
                            lignesToDisplay={lignesToDisplay}
                            onChange={onChange}
                            checkedLigneUuids={checkedLigneUuids}
                          />
                          <div className="flex flex-col gap-xs">
                            {lignesToDisplay.map((ligne, index) => (
                              <CheckboxLigneCard
                                key={ligne.uuid}
                                ligne={ligne}
                                index={`${lotIndexLabel}.${index + 1}`}
                                onChange={() =>
                                  checkedLigneUuids.includes(ligne.uuid)
                                    ? onChange(
                                        checkedLigneUuids.filter(uuid => uuid !== ligne.uuid),
                                      )
                                    : onChange([...value, ligne.uuid])
                                }
                                checked={checkedLigneUuids.includes(ligne.uuid)}
                              />
                            ))}
                          </div>
                        </div>
                      );
                    })
                  )
                }
              />
            </div>
          </>
        </ModalContent>
      )}
    </Form>
  );
};
