import React from 'react';

import { Routes, Route } from 'react-router-dom';

import { VerifyEmail } from 'components/VerifyEmail';
import { CheckAlmaPayment } from 'features/MonCompte/components/CkeckAlmaPayment';

import { AuthenticatedRoutes } from './authenticated';
import { UnauthenticatedRoutes } from './unauthenticated';

export const MainRoutes: React.FC<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => (
  <Routes>
    <Route path="/verify-email" element={<VerifyEmail />} />
    <Route path="/validate-alma-payment" element={<CheckAlmaPayment />} />
    <Route
      path="*"
      element={isAuthenticated ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
    />
  </Routes>
);
