import React from 'react';

import { ProUser } from '@travauxlib/shared/src/types/company';

import { Deal, ProClient } from 'types';

import { DealInformationBackLink } from './DealInformationBackLink';

type Props = {
  proClient: ProClient;
  deal?: Deal;
  proUser?: ProUser;
};

export const DealInformation: React.FC<Props> = ({ proClient, deal }) => {
  const clientName = deal?.customerName || proClient.name;
  const showBackLink = deal && proClient.deals.length > 1;

  return (
    <>
      {showBackLink && <DealInformationBackLink {...proClient} />}
      <>
        <h3 className="!font-bold">{clientName}</h3>
        {deal?.title && <h4 className="!font-bold !mb-xs">{deal.title}</h4>}
      </>
    </>
  );
};
