import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { ProClient } from 'types';

export const PRO_CLIENT_KEY = 'pro-client';

export const useProClient = (proClientUuid?: string): ProClient | undefined => {
  const { data: proClient, isLoading } = useQuery<ProClient>({
    queryKey: [PRO_CLIENT_KEY, proClientUuid],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.proApiURL}/clients/${proClientUuid}`;
      if (!proClientUuid) {
        return null;
      }

      return request(requestURL);
    },
  });

  if (!proClient || isLoading) {
    return undefined;
  }

  return proClient || undefined;
};
