import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserProfile } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { ProCompany } from 'types';

import { AUTH_KEY } from './useProfile';

type CreateOrUpdateSeenModalsPayload = {
  devisTsAnnouncement?: string;
};

const handleCreateOrUpdateSeenModals = (
  payload: CreateOrUpdateSeenModalsPayload,
): Promise<UserProfile<ProCompany>> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro/users/seen-modals`;

  return request(requestURL, {
    method: 'POST',
    body: payload,
  });
};

export const useCreateOrUpdateSeenModals = (): {
  createOrUpdateSeenModals: typeof handleCreateOrUpdateSeenModals;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: createOrUpdateSeenModals, isPending } = useMutation({
    mutationFn: handleCreateOrUpdateSeenModals,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });
    },
  });

  return { createOrUpdateSeenModals, isLoading: isPending };
};
