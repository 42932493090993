import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Devis } from 'types';

export const DEVIS_KEY = 'devis';

export const handleGetDevis = async ({
  token,
  dealUuid,
}: {
  token?: string;
  dealUuid?: string;
}): Promise<Devis> => {
  if (!token || !dealUuid) {
    throw new Error('Impossible de récupérer un devis sans son token ou le deal auquel il est lié');
  }
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/devis/${token}`;
  return request(requestURL);
};

export const useDevis = ({
  token,
  dealUuid,
}: {
  token?: string;
  dealUuid?: string;
}): { devis?: Devis; isLoading: boolean; error: unknown } => {
  const { data, isLoading, fetchStatus, error } = useQuery<Devis | undefined>({
    queryKey: [DEVIS_KEY, dealUuid, token],
    queryFn: async () => {
      if (!token) {
        return;
      }
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/devis/${token}`;
        return await request(requestURL);
      } catch (err) {
        toast.error("Le devis n'a pas pu être récupéré");
        throw err;
      }
    },
    enabled: !!(dealUuid && token),
  });
  return { devis: data, isLoading: isLoading && fetchStatus !== 'idle', error };
};
