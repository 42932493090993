import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { ClubProFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class BonDeCommandeTrackingClass extends Tracker<ClubProFeatures> {
  onBonDeCommandeCreated(properties: { uuid: string; type: 'Vierge' | 'Duplication' }): void {
    this.sendTracking({ event: TrackingEvents.Created, properties });
  }

  onBonDeCommandeSent(properties: { uuid: string }): void {
    this.sendTracking({ event: TrackingEvents.Sent, properties }); // SENDED
  }
}

export const BonDeCommandeTracking = new BonDeCommandeTrackingClass(ClubProFeatures.BonDeCommande);
