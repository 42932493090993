import React from 'react';

import { StickyNavBar } from 'components/StickyNavBar';
import { reassuranceColumnConfig } from 'features/Registration/utils';

export const Header: React.FC = () => (
  <>
    <StickyNavBar calendlySource={reassuranceColumnConfig.calendlySource} />
    <div className="sm-desktop:text-left text-center sm-desktop:bg-white bg-neutral-100 sm-desktop:px-0 sm-desktop:py-0 px-md py-lg mb-xl">
      <div className="font-bold sm-desktop:text-ds-h3 text-ds-h4 text-neutral-800 sm-desktop:mb-xs mb-xxs">
        Renseignez votre siret pour tester le logiciel hemea
      </div>
      <div className="sm-desktop:text-ds-h5 text-ds-b1 text-neutral-700">
        Merci pour vos informations, notre équipe vous contactera prochainement.
      </div>
    </div>
  </>
);
