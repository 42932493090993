import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { CreateFactureModal } from 'features/Deals/features/Factures/components/CreateFactureModal';
import { useDevisList } from 'features/Devis/api/useDevisList';
import { useFacturesList } from 'features/Factures/api/useFacturesList';
import { FactureTracking, FactureModalSource } from 'utils/tracking/FactureTracking';

export const useHandleClickCreateNewFacture = (
  dealUuid: string,
): ((source: FactureModalSource) => void) => {
  const { devisList } = useDevisList({ dealUuid });
  const { facturesList } = useFacturesList(dealUuid);
  const openModal = useOpenModal();
  const isTabletOrMobile = useIsTabletOrMobile();

  return source => {
    const devisSignes = devisList
      .filter(devis => devis.signe)
      .filter(
        devis =>
          !facturesList.some(facture => facture.isCloture && facture.proDevisToken === devis.token),
      );

    FactureTracking.onFactureModalOpened({ source });
    openModal(CreateFactureModal, {
      title: 'Créer une facture',
      closable: true,
      dealUuid,
      devisSignes,
      isScrollable: isTabletOrMobile,
    });
  };
};
