import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { isValidPhoneNumber } from './utils';

import { Props as InputProps } from '../Input';

import { PhoneInput, PhoneNumber } from '.';

export type { PhoneNumber } from '.';

export const validatePhoneNumber = (value?: PhoneNumber): string | undefined => {
  if (!value?.number || !value?.countryCode) {
    return 'Champ obligatoire';
  }
  if (!isValidPhoneNumber(value.countryCode, value.number)) {
    return 'Numéro de téléphone invalide';
  }
};

const PhoneInputWrapper: React.FC<FieldRenderProps<PhoneNumber>> = ({
  input: { value, onChange, onBlur, onFocus, type },
  meta,
  id,
  ...rest
}) => {
  const { touched } = meta;
  const error =
    (!meta.active && meta.touched && meta.error) ||
    (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <PhoneInput
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={error && touched ? error : undefined}
      type={type}
      {...rest}
    />
  );
};

type Props = {
  name: string;
} & UseFieldConfig<PhoneNumber> &
  Omit<InputProps, 'value'>;

export const PhoneInputField: React.FC<Props> = props => (
  <Field component={PhoneInputWrapper} {...props} />
);
