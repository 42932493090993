import PaymentsOutline from '@travauxlib/shared/src/components/DesignSystem/assets/PaymentsOutline.svg?react';
import Percentage from '@travauxlib/shared/src/components/DesignSystem/assets/Percentage.svg?react';
import RequestQuoteOutline from '@travauxlib/shared/src/components/DesignSystem/assets/RequestQuoteOutline.svg?react';
import { FormControlListOption } from '@travauxlib/shared/src/components/DesignSystem/components/FormControl/commons';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useProCompany } from 'api/profileSelectors';
import { useDeal } from 'features/Deals/api/useDeal';
import { useFacturesList } from 'features/Factures/api/useFacturesList';
import { Devis, SelectedPaymentType } from 'types';

type Option = FormControlListOption<string> & {
  centerIcon: JSX.Element;
  value: SelectedPaymentType;
};

export const useGetFacturationMethodOptions = (devis: Devis): Option[] => {
  const deal = useDeal(devis.dealUuid);
  const { isArchitecte } = useProCompany();
  const { facturesList } = useFacturesList(devis.dealUuid);
  const filteredFactureList = facturesList.filter(facture => facture.proDevisToken === devis.token);
  const hasGeneratedFacture = filteredFactureList.length > 0;
  const hasGeneratedFactureSituation = filteredFactureList.some(facture => facture.isSituation);
  const hasGeneratedFactureSolde = filteredFactureList.some(facture => facture.isCloture);

  const labelTotal = `La totalité
  ${devisOrProposition({
    isArchitecte,
    prefix: 'du',
  })}`;

  return [
    {
      centerIcon: <Percentage />,
      value: 'modalites' as const,
      label: 'Par modalités de paiement',
      disabled: hasGeneratedFacture,
    },
    {
      centerIcon: <PaymentsOutline />,
      value: 'acompte' as const,
      label: 'Par acompte',
      disabled: hasGeneratedFactureSituation,
    },
    {
      centerIcon: <RequestQuoteOutline />,
      value: 'total' as const,
      label: labelTotal,
      disabled: hasGeneratedFactureSolde,
      tooltip: hasGeneratedFactureSolde
        ? 'Une facture de solde a déjà été générée, vérifiez vos factures archivées si vous ne la voyez pas.'
        : undefined,
    },
    {
      centerIcon: <RequestQuoteOutline />,
      value: 'situation' as const,
      label: 'Par situation',
      disabled: deal?.isHemea || !(hasGeneratedFactureSituation || !hasGeneratedFacture),
    },
  ];
};
