import { AbilityBuilder, createMongoAbility } from '@casl/ability';

import { ProCompany } from 'types';

import { abilityMap, getPlanType } from './abilityMap';
import { AppAbility } from './entities/Ability';

export function defineAbilityFor(proCompany?: ProCompany, isHemeaEmployee?: boolean): AppAbility {
  const abilityBuilder = new AbilityBuilder<AppAbility>(createMongoAbility);

  const planType = getPlanType(proCompany);

  const Ability = abilityMap[planType];
  const factory = new Ability(abilityBuilder);
  factory.defineAbility(proCompany, isHemeaEmployee);
  return factory.build();
}
