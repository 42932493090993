import React from 'react';

import { BonCommande } from '@travauxlib/shared/src/types';

import { BonCommandeTableHeader } from './BonCommandeTableHeader';
import { BonCommandeTableRow } from './BonCommandeTableRow';

type Props = {
  bonCommandeList: BonCommande[];
};

export const BonCommandeTable: React.FC<Props> = ({ bonCommandeList }) => (
  <>
    <table className="table border-separate border-spacing-0 mb-xs">
      <thead>
        <BonCommandeTableHeader />
      </thead>
      <tbody>
        {bonCommandeList.map(bonCommande => (
          <React.Fragment key={bonCommande.token}>
            <tr className="h-[0.5rem]" />
            <BonCommandeTableRow key={bonCommande.token} bonCommande={bonCommande} />
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </>
);
