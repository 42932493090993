import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request, isConflict } from '@travauxlib/shared/src/utils/request';

import { ProCompany } from 'types';

import { AUTH_KEY } from './useProfile';

const handleUpdateProCompany = (proCompany: ProCompany): Promise<ProCompany> => {
  const requestUrl = `${APP_CONFIG.apiURL}/pro/company-profile`;

  return request(requestUrl, {
    method: 'PUT',
    body: JSON.stringify(proCompany),
  });
};

export const useUpdateProCompany = (): {
  updateProCompany: typeof handleUpdateProCompany;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateProCompany, isPending } = useMutation({
    mutationFn: handleUpdateProCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });

      toast.success('Les modifications ont été prises en compte');
    },
    onError: (error: Error) => {
      const message = isConflict(error)
        ? "L'email est déjà utilisé par une autre entreprise."
        : "Une erreur est survenue, les informations n'ont pas pu être mises à jour.";
      toast.error(message);
    },
  });
  return {
    updateProCompany,
    isLoading: isPending,
  };
};
