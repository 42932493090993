import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { BonCommande } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const BON_COMMANDE_KEY = 'bon-commande';

export const useGetBonCommande = ({
  token,
  dealUuid,
}: {
  token: string;
  dealUuid: string;
}): { bonCommande?: BonCommande; isLoading: boolean } => {
  const { data, isLoading } = useQuery<BonCommande>({
    queryKey: [BON_COMMANDE_KEY, dealUuid, token],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/bon-commande/${token}`;
        return await request(requestURL);
      } catch (err) {
        toast.error("Le bon de commande n'a pas pu être récupéré");
        throw err;
      }
    },
  });
  return { bonCommande: data, isLoading };
};
