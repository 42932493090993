import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Facture } from 'types';

export const FACTURES_KEY = 'factures';

export const useFacturesList = (
  dealUuid: string,
): { facturesList: Facture[]; isLoaded: boolean; isLoading: boolean } => {
  const { data, isLoading } = useQuery<{ data: Facture[] }>({
    queryKey: [FACTURES_KEY, dealUuid],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/factures`;
        return await request(requestURL);
      } catch (err) {
        toast.error("Les factures n'ont pas pu être récupérées");
        throw err;
      }
    },
  });

  return {
    facturesList: data?.data || [],
    isLoaded: !isLoading,
    isLoading,
  };
};
