import { useEffect } from 'react';

import { isValidNumber } from '@travauxlib/shared/src/utils/form';

import { useProfile } from './useProfile';

export const useSendProfileToGtm = (): void => {
  const { profile } = useProfile();
  const isLabellisee =
    profile?.company?.status && ['LABELLISEE', 'ACTIVEE'].includes(profile.company.status);
  const isClubPro = profile?.company?.isClubPro;

  useEffect(() => {
    window?.dataLayer?.push({
      event: 'user.profile',
      email: profile.email,
      phone:
        profile.phoneNumber && isValidNumber(profile.phoneNumber) ? profile.phoneNumber : undefined,
      company: profile.company?.name,
      nickname: profile.fullName,
      isHemeaEmployee: profile.isHemeaEmployee,
      isClubPro: isClubPro,
      isLabellisee: isLabellisee,
    });
  }, [
    profile.email,
    profile.phoneNumber,
    profile.company,
    profile.fullName,
    profile.company?.name,
    profile.isHemeaEmployee,
    isClubPro,
    isLabellisee,
  ]);
};
