import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SuiviChantier } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const SUIVI_KEY = 'suivi';

export const useGetSuiviChantier = ({
  token,
  dealUuid,
}: {
  token: string;
  dealUuid: string;
}): { suiviChantier?: SuiviChantier; isLoading: boolean } => {
  const { data, isLoading } = useQuery<SuiviChantier>({
    queryKey: [SUIVI_KEY, dealUuid, token],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/suivi-chantier/${token}`;
        return await request(requestURL);
      } catch (err) {
        toast.error("Le suivi n'a pas pu être récupéré");
        throw err;
      }
    },
  });
  return { suiviChantier: data, isLoading };
};
