import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

const handleCreateAppelDePaiement = async (payload: {
  dealUuid: string;
  montant: number;
}): Promise<void> => {
  const { dealUuid, montant } = payload;
  const requestURL = `${APP_CONFIG.apiURL}/pro/deals/${dealUuid}/chantier/appel-de-paiement`;
  await request(requestURL, {
    method: 'POST',
    body: { value: montant },
  });
};

export const useCreateAppelDePaiement = (): {
  isLoading: boolean;
  isSuccess: boolean;
  createAppelDePaiement: typeof handleCreateAppelDePaiement;
} => {
  const queryClient = useQueryClient();
  const { isPending, isSuccess, mutateAsync } = useMutation({
    mutationFn: handleCreateAppelDePaiement,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dealChantier'] });
    },
  });

  return { isLoading: isPending, isSuccess, createAppelDePaiement: mutateAsync };
};
