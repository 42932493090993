import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

const handleResendEmail = (payload: { userEmail: string }): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro-registrations/resend-email/${payload.userEmail}`;

  return request(requestURL, {
    method: 'POST',
  });
};

export const useResendConfirmationEmail = (): {
  isLoading: boolean;
  resendConfirmationEmail: typeof handleResendEmail;
} => {
  const { mutateAsync: resendConfirmationEmail, isPending } = useMutation({
    mutationFn: handleResendEmail,
    onSuccess: () => {
      toast.success('Un email de confirmation a bien été renvoyé à votre adresse e-mail');
    },
    onError: () => {
      toast.error(
        "Une erreur est survenue, cet e-mail n'a pas été utilisé pour s'inscrire chez hemea",
      );
    },
  });

  return { resendConfirmationEmail, isLoading: isPending };
};
