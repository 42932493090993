import { useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { DownloadableFile, UploadFilesPayload } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

const handleUploadTempFiles = async ({
  files,
  onUpdate,
  onSuccess,
  onError,
}: UploadFilesPayload): Promise<DownloadableFile[]> => {
  const result = await Promise.all(
    files.map(async (file, i) => {
      const requestUrl = `${APP_CONFIG.apiURL}/pro/temporary-file?filename=${file.name}`;
      try {
        const result = await request(requestUrl, {
          method: 'POST',
          headers: {
            'Content-Type': file.type,
          },
          body: file.body,
          onUploadProgress: (event: ProgressEvent) => onUpdate(event, i),
        });
        onSuccess(i);
        return result;
      } catch {
        onError(i);
      }
    }),
  );

  return result.filter(item => item !== undefined);
};

export const useUploadTempS3Files = (): {
  uploadFiles: typeof handleUploadTempFiles;
  isLoading: boolean;
} => {
  const { mutateAsync: uploadFiles, isPending } = useMutation({
    mutationFn: handleUploadTempFiles,
    onError: () => {
      toast.error("Une erreur est survenue lors de l'envoi de votre fichier");
    },
  });

  return { uploadFiles, isLoading: isPending };
};
