import React from 'react';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';

type Props = {
  isOpen: boolean;
  handleConfirm: () => void;
  closeModal: () => void;
};

export const DevisModalWarningStatusSigned: React.FC<Props> = ({
  isOpen,
  handleConfirm,
  closeModal,
}) => (
  <FeedbackMessages
    variant="warning"
    title="Changement de statut du devis"
    message="Une fois le devis changé en statut signé vous ne pourrez modifier son statut que pour l'annuler."
    isOpen={isOpen}
    cancelAction={{
      label: 'Retour',
      onClick: closeModal,
    }}
    validateAction={{
      label: 'Valider',
      onClick: handleConfirm,
    }}
  />
);
