import React from 'react';

import { Form } from 'react-final-form';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import {
  useOpenModal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { TvaPickerChipsField } from '@travauxlib/shared/src/components/TvaPicker/Field';
import { composeValidators, required } from '@travauxlib/shared/src/utils/form';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useActivatedMarginCalculation } from 'api/profileSelectors';
import { DEFAULT_MARGIN } from 'config';
import { useCreateDevis } from 'features/Devis/api/useCreateDevis';
import { getDevisUrl } from 'utils/urls';

import { getEmptyDevis } from '../utils/devis';

type OwnProps = {
  isArchitecte?: boolean;
  projectUuid?: string;
  dealUuid: string;
  devisNumeros: string[];
  title?: string;
  isRGE?: boolean;
};

type Props = OwnProps & {
  handleClose: () => void;
};

type FormValues = {
  title: string;
  numero: string;
  defaultTauxTVA: number;
  defaultMargin?: number;
};

export const CreateDevisFromStartModal: React.FC<Props> = ({
  isArchitecte,
  dealUuid,
  handleClose,
  devisNumeros,
  title,
  isRGE,
  projectUuid,
}) => {
  const activatedMarginCalculation = useActivatedMarginCalculation();
  const { createDevis } = useCreateDevis({ redirectFc: getDevisUrl });

  const handleCreateDevis = async (values: FormValues): Promise<void> => {
    const emptyDevis = getEmptyDevis(isArchitecte);

    const devis = {
      ...emptyDevis,
      ...values,
      projectTitle: values.title,
      projectUuid,
      isRGE: isRGE,
    };

    await createDevis({ dealUuid, devis });
    handleClose();
  };

  const validateNumeroIsNotAlreadyUsed = (value: string): string | undefined =>
    devisNumeros.includes(value) ? 'Ce numéro est déjà utilisé' : undefined;

  return (
    <Form<FormValues>
      onSubmit={handleCreateDevis}
      initialValues={{
        numero: (devisNumeros.length + 101).toString(),
        defaultTauxTVA: 10,
        defaultMargin: DEFAULT_MARGIN,
      }}
    >
      {({ handleSubmit, submitting }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            'data-testid': 'create-devis-from-start',
            label: 'Créer',
            loading: submitting,
            type: 'submit',
            disabled: submitting,
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: handleClose,
          }}
          title={title}
        >
          <div className="mb-md">
            <InputField
              id="title"
              name="title"
              maxLength={250}
              label={`Titre ${devisOrProposition({ isArchitecte, prefix: 'du' })}`}
              validate={required}
            />
          </div>
          <div className="mb-md">
            <InputField
              id="numero"
              name="numero"
              label={`Numéro ${devisOrProposition({ isArchitecte, prefix: 'du' })}`}
              validate={composeValidators(required, validateNumeroIsNotAlreadyUsed)}
            />
          </div>
          {activatedMarginCalculation && (
            <NumberInputField
              max={100}
              id="defaultMargin"
              name="defaultMargin"
              label="Marge par défaut"
              suffix="%"
            />
          )}
          <div className="!mb-xmd mt-md">
            <TvaPickerChipsField name="defaultTauxTVA" label="TVA par défaut" validate={required} />
          </div>
        </ModalContent>
      )}
    </Form>
  );
};

const getTitle = (isArchitecte: boolean, projectUuid?: string): string => {
  if (projectUuid) {
    return `Nouvelle version ${devisOrProposition({ isArchitecte, prefix: 'du' })}`;
  }

  return `${isArchitecte ? 'Nouvelle proposition' : 'Nouveau devis'}`;
};

export const useOpenCreateDevisFromStartModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(CreateDevisFromStartModal, {
      title: `${getTitle(!!props.isArchitecte, props.projectUuid)} à partir de zéro`,
      closable: true,
      size: 'md',
      ...props,
    });
};
