import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { BonCommande } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

export const BON_COMMANDE_LIST_KEY = 'bon-commande-list';

export const useGetBonCommandeList = (
  dealUuid: string,
): { bonCommandeList: BonCommande[]; isLoading: boolean } => {
  const { data, isLoading } = useQuery<{ data: BonCommande[] }>({
    queryKey: [BON_COMMANDE_LIST_KEY, dealUuid],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/bon-commande`;
        return request(requestURL);
      } catch (err) {
        toast.error("Le bon de commande n'a pas pu être récupéré");
        throw err;
      }
    },
  });
  return { bonCommandeList: data?.data || [], isLoading };
};
