import React from 'react';

import { BonCommande } from '@travauxlib/shared/src/types';

type Props = {
  subTitle: string;
  mainBonCommande: BonCommande;
};

export const Header: React.FC<Props> = ({ subTitle, mainBonCommande }) => (
  <div className="px-xmd pt-xmd bg-primarylighter rounded-md md-desktop:rounded-none">
    <div className="!font-bold pb-md text-ds-h5 !bg-primarylighter">{mainBonCommande.title}</div>
    <div className="font-bold md-desktop:hidden mb-sm">{subTitle}</div>
    <div className="flex justify-between items-center flex-wrap mb-md">
      <span className="hidden md-desktop:block">{subTitle}</span>
    </div>
    <div className="hidden md-desktop:block border-solid border-b border-t-0 border-x-0 border-primary" />
  </div>
);
