import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SUIVI_CHANTIERS_KEY } from '@travauxlib/shared/src/features/SuiviChantier/api/createSuiviChantierHistoryList';
import { SuiviLot } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { useDeleteSuiviChantierDraft } from './useDeleteSuiviChantierDraft';

type Payload = {
  chantierUuid: string;
  lots: SuiviLot[];
  montantALibererTTC: number;
  globalPourcentageAvancement: number;
};

type APIResponse = {
  customerName: string;
};

const handleCreateSuiviChantier = ({ chantierUuid, ...rest }: Payload): Promise<APIResponse> => {
  const requestURL = `${APP_CONFIG.proApiURL}/chantiers/${chantierUuid}/suivi-chantier`;

  return request(requestURL, {
    method: 'POST',
    body: rest,
  });
};

export const useCreateSuiviChantier = (): typeof handleCreateSuiviChantier => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const deleteSuiviChantierDraft = useDeleteSuiviChantierDraft();

  const { mutateAsync: createSuiviChantier } = useMutation({
    mutationFn: handleCreateSuiviChantier,
    onSuccess: ({ customerName }, { chantierUuid, montantALibererTTC }) => {
      toast.success(
        <>
          La demande de libération de fonds de <EURCurrency amount={montantALibererTTC} /> pour le
          chantier {customerName} a bien été envoyée.
        </>,
      );
      deleteSuiviChantierDraft(chantierUuid);
      queryClient.invalidateQueries({ queryKey: [SUIVI_CHANTIERS_KEY] });
      navigate(`/deals/${chantierUuid}/chantier`);
    },
    onError: () => {
      toast.error("Il y a eu un problème. La demande de libération de fonds n'a pas été envoyée.");
    },
  });

  return createSuiviChantier;
};
