import { useMutation } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { DemandeChantierFormData } from '../types';

const handleCreateDemandeChantier = (
  payload: DemandeChantierFormData,
): Promise<{ token: string }> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro-registrations`;

  return request(requestURL, {
    method: 'POST',
    body: { ...payload, isDemandeChantier: true },
  });
};

export const useCreateDemandeChantier = (): typeof handleCreateDemandeChantier => {
  const { mutateAsync: createDemandeChantier } = useMutation({
    mutationFn: handleCreateDemandeChantier,
  });

  return createDemandeChantier;
};
