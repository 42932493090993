import React from 'react';

import classNames from 'classnames';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';

type Props = {
  montantAvancement: number;
  onClick: () => void;
};

export const AvancementFooter: React.FC<Props> = ({ montantAvancement, onClick }) => {
  const isBelowSmDesktop = useIsBelowBreakpoint('sm-desktop');

  return (
    <div
      className={classNames(
        'col-span-full bg-neutral-0 flex items-center px-md py-sm mt-auto justify-between shadow-ds-xl gap-md sticky bottom-0',
        'sm-desktop:col-span-3 sm-desktop:shadow-ds-xxs sm-desktop:top-0 sm-desktop:mt-0 sm-desktop:justify-normal sm-desktop:mb-auto sm-desktop:rounded-xxs sm-desktop:flex-col sm-desktop:items-start',
      )}
    >
      <div className="text-ds-b2 font-bold">
        <div>Montant d'avancement du lot</div>
        <EURCurrency className="text-ds-h5" amount={montantAvancement} />
      </div>
      <Button onClick={onClick} fullwidth={!isBelowSmDesktop}>
        Valider
      </Button>
    </div>
  );
};
