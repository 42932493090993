import React, { FC } from 'react';

import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { useFeatureFlag } from '@travauxlib/shared/src/hooks/useFeatureFlag';

import { MessageAttachmentList } from 'features/Deals/components/MessageAttachmentList';
import { Deal } from 'types';

import { PersonalNotes } from '../PersonalNotes';

type RightColumnTabProps = {
  deal: Deal;
};

export const RightColumnTab: FC<RightColumnTabProps> = ({ deal }) => {
  const { conversationUuid, notes } = deal;
  const showNotes = useFeatureFlag('notes');
  return (
    <>
      <Tabs
        fullWidth
        items={[
          {
            label: 'Documents partagés',
            isActive: () => !showNotes,
            to: {
              search: 'documents',
            },
          },
          {
            label: 'Notes',
            isActive: () => showNotes,
            to: {
              search: 'notes',
            },
          },
        ]}
      />
      {showNotes ? (
        <PersonalNotes buttonClassName="!mb-xmd" deal={deal} />
      ) : (
        <div className="!overflow-auto !flex !flex-col !max-h-full flex-grow">
          <MessageAttachmentList conversationUuid={conversationUuid} notes={notes} />
        </div>
      )}
    </>
  );
};
