import React from 'react';

import { useNavigate } from 'react-router-dom';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { BonCommande } from '@travauxlib/shared/src/types';

import { useContractors } from 'features/Contacts/api/useContractors';
import { getBonCommandePreviewUrl, getBonCommandeRootUrl } from 'utils/urls';

import { BonCommandeActions } from './BonCommandeActions';
import { Date } from './Date';
import { Status } from './Status';

export const BonCommandeTableRow: React.FC<{ bonCommande: BonCommande }> = ({ bonCommande }) => {
  const navigate = useNavigate();
  const { contractors } = useContractors();
  const bonCommandeLink = getBonCommandeRootUrl(bonCommande);
  const currentContractorName = contractors.flatMap(
    contractor => contractor.uuid === bonCommande.contractorUuid && contractor.name,
  );
  return (
    <tr
      className="!cursor-pointer hover:bg-primarylight bg-primarylighter"
      onClick={() => {
        if (bonCommande.status === 'sent') {
          return window.open(getBonCommandePreviewUrl(bonCommande));
        }
        navigate(bonCommandeLink);
      }}
    >
      <td className="!py-xmd !align-middle !pl-xmd rounded-tl-xxs rounded-bl-xxs">
        {bonCommande.title}
      </td>
      <td className="!py-xmd !align-middle">{currentContractorName}</td>
      <td className="!py-xmd !align-middle">
        <EURCurrency amount={bonCommande.prixTotalHT} />
      </td>
      <td className="!py-xmd !align-middle">{bonCommande.marge} %</td>
      <td className="!py-xmd !align-middle">
        <Status status={bonCommande.status} />
      </td>
      <td className="!py-xmd !align-middle">
        <Date bonCommande={bonCommande} />
      </td>
      <td
        className="!py-xmd !align-middle rounded-tr-xxs rounded-br-xxs"
        onClick={event => event.stopPropagation()}
      >
        <BonCommandeActions bonCommande={bonCommande} />
      </td>
    </tr>
  );
};
