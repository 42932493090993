import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request, isNotFound } from '@travauxlib/shared/src/utils/request';

export type SocieteInfoCompany = {
  siret: string;
  companyName: string;
  address: string;
  postalCode: string;
  alreadyRegistered?: boolean;
  city: string;
};

export type AlreadyRegisteredCompany = {
  alreadyRegistered: boolean;
  companyName: string;
  siret: string;
  postalCode: string;
  city: string;
  address: string;
};

export const SEARCH_COMPANY_KEY = 'search-company';
export const useSearchCompany = (
  removeSiret: () => void,
  siret?: number,
): { companyFound?: SocieteInfoCompany; isLoading: boolean } => {
  const { data, error, isLoading, fetchStatus } = useQuery<
    { data: SocieteInfoCompany },
    AxiosError
  >({
    queryKey: [SEARCH_COMPANY_KEY],
    queryFn: async () => {
      try {
        return await request(`${APP_CONFIG.apiURL}/pro-registrations/search-companies/${siret}`);
      } catch (error) {
        if (isNotFound(error as AxiosError)) {
          toast.error('Aucune entreprise trouvée pour ce SIRET');
          removeSiret();
        }
        throw error;
      }
    },
    staleTime: Infinity,
    enabled: !!siret,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const alreadyRegisteredCompany = error?.response?.status === 409 && {
    ...(error.response.data as AlreadyRegisteredCompany),
    alreadyRegistered: true,
  };

  return {
    companyFound: data?.data || alreadyRegisteredCompany || undefined,
    isLoading: isLoading && fetchStatus !== 'idle',
  };
};
