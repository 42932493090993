import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  prixTotalHT: number;
  prixTotalTTC: number;
  shouldDisplayMontantHT: boolean;
};

export const Totaux: React.FC<Props> = ({
  prixTotalHT,
  prixTotalTTC,
  shouldDisplayMontantHT = true,
}) => (
  <div className="!text-right">
    {shouldDisplayMontantHT ? (
      <div>
        Total HT :&nbsp;
        <strong className="text-ds-h4">
          <EURCurrency amount={prixTotalHT} />
        </strong>
      </div>
    ) : null}
    <div>
      Total TTC :&nbsp;
      <strong className="text-ds-h4">
        <EURCurrency amount={prixTotalTTC} />
      </strong>
    </div>
  </div>
);
