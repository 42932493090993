import React, { useState } from 'react';

import ReactDropzone, { Accept } from 'react-dropzone';

import Spinner from '@travauxlib/shared/src/components/DesignSystem/assets/Spinner.svg?react';
import Upload from '@travauxlib/shared/src/components/DesignSystem/assets/Upload.svg?react';
import { toast } from '@travauxlib/shared/src/components/Notifications';

import { formatFileSize } from '../../utils/format';
import { DEFAULT_SIZE_LIMIT } from '../FileUpload';

export type FileComponentProps<T> = {
  index: number;
  file: T;
  handleRemoveFile: (removedIndex: number) => void;
  onChange?: (newFiles: T[]) => void;
  files: T[];
};
export type Props<T> = {
  files: T[];
  onChange?: (newFiles: T[]) => void;
  onRemoveFile?: (removedFile: T) => void;
  fileComponent: React.FC<FileComponentProps<T>>;
  handleUploadFile: (files: File[]) => Promise<any>;
  label?: string;
  containerClassName?: string;
  id?: string;
  multiple?: boolean;
  sizeLimit?: number;
  error?: string;
  accept?: Accept;
};

export const Dropzone = <T extends object>({
  handleUploadFile,
  onChange,
  onRemoveFile,
  files = [],
  label = 'Télécharger une pièce jointe',
  fileComponent,
  containerClassName = '',
  id = 'dropzone',
  multiple = true,
  sizeLimit = DEFAULT_SIZE_LIMIT,
  error,
  accept,
}: Props<T>): JSX.Element => {
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const handleRemoveFile = (removedIndex: number): void => {
    const removedFile = files.find((_, index) => index === removedIndex);

    if (onChange) {
      onChange(files.filter((_, index) => removedIndex !== index));
    }
    return onRemoveFile && removedFile && onRemoveFile(removedFile);
  };

  const onDrop = async (droppedFiles: File[]): Promise<void> => {
    setIsUploadingFile(true);
    const result = await handleUploadFile(droppedFiles);
    setIsUploadingFile(false);

    return onChange && onChange([...files, ...result.files]);
  };

  const errorMessages: { [key: string]: string } = {
    'file-too-large': `Fichier trop gros - ${formatFileSize(DEFAULT_SIZE_LIMIT)} maximum`,
    'file-invalid-type': `Fichier invalide - type de fichiers non autorisé`,
    'file-too-small': 'Fichier trop petit',
    'too-many-files': "Trop de fichiers d'un coup",
  };

  return (
    <>
      {files.length !== 0 && (
        <div className={containerClassName}>
          {files.map((file, index) => (
            <React.Fragment key={index}>
              {fileComponent({
                file,
                index,
                handleRemoveFile,
                onChange,
                files,
              })}
            </React.Fragment>
          ))}
        </div>
      )}
      <ReactDropzone
        accept={accept}
        onDrop={onDrop}
        multiple={multiple}
        maxSize={sizeLimit}
        onDropRejected={events => {
          const errors = events.flatMap(event => event.errors);
          errors.forEach(error => {
            const errorMessage =
              errorMessages[error.code] || 'Erreur inconnue - Veuillez contacter notre support.';
            toast.error(errorMessage);
          });
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="border-dashed hover:bg-primary hover:text-white border-[2px] font-bold text-primary border-primary !rounded !p-xs !flex !justify-center !items-center !flex-col !cursor-pointer"
          >
            {/* Attention, ici lorsqu'on met l'id ça fait que parfois la modal s'ouvre 2 fois de suite */}
            {/* ça vient peut-être de react-dropzone */}
            <input data-testid={id} {...getInputProps()} />
            {isUploadingFile ? (
              <Spinner className="w-xl h-xl mr-sm animate-spin" data-testid="loader" />
            ) : (
              <>
                <Upload className="w-xl h-xl mb-md" />
                <label className="!mb-0 !cursor-pointer" htmlFor={id}>
                  {label}
                </label>
              </>
            )}
          </div>
        )}
      </ReactDropzone>
      {error && <div className="text-danger text-ds-sm !block">{error}</div>}
    </>
  );
};
