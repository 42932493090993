import { useCreateDeal } from 'features/Deals/api/useCreateDeal';
import { useUpdateDeal } from 'features/Deals/api/useUpdateDeal';
import { useCreateProClient } from 'features/ProClients/api/useCreateProClient';
import { useUpdateProClient } from 'features/ProClients/api/useUpdateProClient';
import { Deal, ProClientAndChantierAddress } from 'types';

export const useHandleSubmitDeal = (): ((deal: Deal) => void) => {
  const createDeal = useCreateDeal();
  const { updateDeal } = useUpdateDeal();

  return async (deal: Deal) => {
    if (deal.uuid) {
      await updateDeal(deal);
    } else {
      createDeal(deal);
    }
  };
};

type HandleSubmitProClientPayload = ProClientAndChantierAddress & {
  sameBillingAndChantierAddress: boolean;
};

export const useHandleSubmitProClient = (): ((
  payload: HandleSubmitProClientPayload,
) => Promise<void>) => {
  const createProClient = useCreateProClient();
  const updateProClient = useUpdateProClient();

  return async (proClient: HandleSubmitProClientPayload) => {
    if (proClient.uuid) {
      await updateProClient(proClient);
    } else {
      createProClient(proClient);
    }
  };
};
