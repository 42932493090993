import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { TransferDevisModal } from 'components/TransferDevisModal';
import { Devis } from 'types';

export const useOpenTransferDevisModal = (): ((devisToTransfer: Devis, title: string) => void) => {
  const openModal = useOpenModal();

  return (devisToTransfer: Devis, title: string) =>
    openModal(TransferDevisModal, {
      devisToTransfer,
      closable: true,
      title,
    });
};
