import React from 'react';

import { baseImgixOnboardingUrl } from 'utils/constants';

export const TrustPilotStars: React.FC = () => (
  <div className="!flex !mb-md" data-testid="trustpilotStars">
    {[...Array(5)].map((_, index) => (
      <img
        src={`${baseImgixOnboardingUrl}/trustpilot-star.png`}
        width="25px"
        height="25px"
        key={index}
        alt="*"
      />
    ))}
  </div>
);
