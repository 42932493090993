import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { DevisTemplate } from 'types';

export const useTemplatesList = (
  isArchitecte: boolean,
): { isLoaded: boolean; devisTemplatesItems: DevisTemplate[] } => {
  const { data, isLoading, fetchStatus } = useQuery<{ data: DevisTemplate[] }>({
    queryKey: ['devis-templates'],
    queryFn: () => {
      const requestURL = `${APP_CONFIG.proApiURL}/devis-templates`;
      return request(requestURL);
    },
    enabled: isArchitecte,
  });

  return {
    isLoaded: !isLoading || fetchStatus === 'idle',
    devisTemplatesItems: data?.data || [],
  };
};
