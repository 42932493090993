import React from 'react';

import { NavLink } from 'react-router-dom';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';

import { ProClient } from 'types';
import { getProClientRootUrl } from 'utils/urls';

export const DealInformationBackLink: React.FC<Pick<ProClient, 'uuid' | 'name'>> = ({
  uuid: proClientUuid,
  name: proClientName,
}) => (
  <>
    <NavLink to={getProClientRootUrl(proClientUuid)} className="!flex !items-center -ml-xxs !mb-md">
      <ChevronLeft className="w-lg h-lg mr-xxs" />
      {proClientName}
    </NavLink>
  </>
);
