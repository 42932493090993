import React, { useState } from 'react';

import { ArchivedToggle } from '@travauxlib/shared/src/components/ArchivedToggle';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { useGetBonCommandeList } from 'features/Deals/api/useGetBonCommandeList';

import { BonCommandeBlock } from './BonCommandeBlock';

import { groupAndSortBonCommande } from '../utils/utils';

type Props = {
  dealUuid: string;
};

export const BonCommandeList: React.FC<Props> = ({ dealUuid }) => {
  const { bonCommandeList, isLoading } = useGetBonCommandeList(dealUuid);
  const [showArchivedBlocks, setShowArchivedBlocks] = useState(false);
  const sortedBonCommandeGroups = groupAndSortBonCommande(
    bonCommandeList.filter(bonCommande => !bonCommande.archived),
  );
  const sortedArchivedBonCommandeGroups = groupAndSortBonCommande(
    bonCommandeList.filter(bonCommande => bonCommande.archived),
  );

  if (isLoading) {
    return <LoaderWrapper />;
  }

  return (
    <div className="py-md sm-desktop:pb-xmd">
      <div className="mb-md text-right flex justify-between items-center">
        <ArchivedToggle
          id="bon-commande"
          setShowArchived={setShowArchivedBlocks}
          showArchived={showArchivedBlocks}
        />
      </div>
      {showArchivedBlocks
        ? sortedArchivedBonCommandeGroups.map(({ bonCommandeList }, index) => (
            <div className="pb-xl" key={`${bonCommandeList[0].title}-${index}`}>
              <BonCommandeBlock
                bonCommandeList={bonCommandeList}
                mainBonCommande={bonCommandeList[0]}
              />
            </div>
          ))
        : sortedBonCommandeGroups.map(({ bonCommandeList }, index) => (
            <div className="pb-xl" key={`${bonCommandeList[0].title}-${index}`}>
              <BonCommandeBlock
                bonCommandeList={bonCommandeList}
                mainBonCommande={bonCommandeList[0]}
              />
            </div>
          ))}
    </div>
  );
};
