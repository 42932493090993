import React from 'react';

import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { useTemplatesList } from 'features/Devis/api/useTemplatesList';
import { useUserTemplatesList } from 'features/Devis/api/useUserTemplatesList';

import { CreateDevisModalContent } from '../components/CreateDevisModalContent';

type OwnProps = {
  dealUuid: string;
  projectUuid?: string;
  isArchitecte?: boolean;
  devisNumeros: string[];
  isDealFromHemea?: boolean;
};

type Props = OwnProps & {
  handleClose: () => void;
};

export const CreateDevisModal: React.FC<Props> = props => {
  const { isArchitecte } = props;
  const { devisTemplatesItems } = useTemplatesList(!!isArchitecte);
  const { devisUserTemplatesItems } = useUserTemplatesList();

  return (
    <CreateDevisModalContent
      {...props}
      devisTemplatesItems={devisTemplatesItems}
      devisUserTemplatesItems={devisUserTemplatesItems}
    />
  );
};

export const useOpenCreateDevisModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();
  const isTabletOrMobile = useIsTabletOrMobile();

  return (props: OwnProps) =>
    openModal(CreateDevisModal, {
      title: `${props.isArchitecte ? 'Nouvelle proposition' : 'Nouveau devis'}`,
      closable: true,
      size: 'md',
      ...props,
      isScrollable: isTabletOrMobile,
      isDealFromHemea: props.isDealFromHemea,
    });
};
