import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { ClubProFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class ParrainageTrackingClass extends Tracker<ClubProFeatures> {
  onParrainageViewed(): void {
    this.sendTracking({ event: TrackingEvents.Viewed });
  }
}

export const ParrainageTracking = new ParrainageTrackingClass(ClubProFeatures.Parrainage);
