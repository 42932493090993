import React, { useState } from 'react';

import { useFormState } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

import { LayoutGrid } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { LotIcon } from '@travauxlib/shared/src/features/DevisDisplay/components/LotIcon';
import { Header } from '@travauxlib/shared/src/features/SuiviChantier/components/Header';
import { SuiviProByDevis } from '@travauxlib/shared/src/features/SuiviChantier/components/ProDisplay/SuiviProByDevis';
import { FormData, SuiviLot } from '@travauxlib/shared/src/features/SuiviChantier/types';
import {
  doesSuiviContainsAChange,
  getAvancementForLots,
  getMontantHTByAvancementForLots,
  parseFormDataToGetLots,
} from '@travauxlib/shared/src/features/SuiviChantier/utils';

import { AvancementFooter } from './AvancementFooter';

export const LotAvancement: React.FC = () => {
  const navigate = useNavigate();
  const { firstLotUuidInGroup } = useParams<{ firstLotUuidInGroup: string }>();
  const { values, initialValues } = useFormState<FormData>();
  const lots = parseFormDataToGetLots(values);
  const firstLotInGroup = lots.find(lot => lot.uuid === firstLotUuidInGroup);
  const lotsInGroup = lots.filter(
    lot => lot.label === firstLotInGroup?.label && lot.lignes.length > 0,
  );
  const initialLots = parseFormDataToGetLots(initialValues as Record<string, SuiviLot>).filter(
    lot => lot.label === firstLotInGroup?.label,
  );

  // Because of potential sc draft, we need initialLotAmount to be computed with avancement from previous cycle, not with current avancement
  const [initialLotAmount] = useState<number>(getMontantHTByAvancementForLots(initialLots, true));
  const montantAvancement =
    getMontantHTByAvancementForLots(Object.values(lotsInGroup)) - initialLotAmount;

  return (
    <>
      <Header
        onClick={() => navigate('..')}
        title="Vous consultez"
        Icon={props => <LotIcon lotLabel={lotsInGroup[0].label} {...props} />}
        subTitle={lotsInGroup[0].label}
        pourcentageAvancement={getAvancementForLots(lotsInGroup)}
        shouldDisplayInfoColor={doesSuiviContainsAChange(lotsInGroup)}
      />
      <LayoutGrid>
        <div className="col-span-full sm-desktop:col-start-2 text-ds-h4 sm-desktop:text-ds-h3 font-bold mt-md mb-xxs sm-desktop:my-xl text-neutral-800">
          État d'avancement
          <div className="text-ds-h5 font-medium text-neutral-700">
            Renseignez l'avancement des prestations
          </div>
        </div>
      </LayoutGrid>
      <LayoutGrid className="overflow-auto h-full !mx-0">
        <div className="col-span-full sm-desktop:col-start-2 sm-desktop:col-span-6 flex flex-col gap-md pb-md">
          <SuiviProByDevis suiviLots={lotsInGroup} />
        </div>
        <AvancementFooter montantAvancement={montantAvancement} onClick={() => navigate('..')} />
      </LayoutGrid>
    </>
  );
};
