import React from 'react';

import DocumentOutline from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentOutline.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';

import { DocumentCard } from '../DocumentCard';

type Props = {
  files: {
    downloadUrl: string;
    name: string;
    key: string;
  }[];
};

export const Documents: React.FC<Props> = ({ files }) => {
  if (files.length === 0) {
    return null;
  }

  return (
    <>
      <div className="flex items-center mb-md">
        <ColoredCircle className="mr-xs" size="sm">
          <DocumentOutline />
        </ColoredCircle>
        <h3 className="font-bold text-ds-h4 mb-0">Document du chantier</h3>
      </div>
      <div className="flex items-start justify-center md-desktop:justify-start mb-xl flex-wrap gap-md">
        {files.map((file: { downloadUrl: string; name: string }, index) => (
          <DocumentCard key={index} downloadUrl={file.downloadUrl} name={file.name} />
        ))}
      </div>
    </>
  );
};
