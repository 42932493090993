import { ProCompany } from 'types';

import {
  Essentiel,
  Pro,
  Expert,
  LegacyEssentiel,
  LegacyPlus,
  LegacyPremium,
  Unsubscribed,
  TrialPeriod,
  UnlimitedFreeAccount,
} from '.';

export const abilityMap = {
  essentiel: Essentiel,
  pro: Pro,
  expert: Expert,
  legacy_essentiel: LegacyEssentiel,
  legacy_plus: LegacyPlus,
  legacy_premium: LegacyPremium,
  unsubscribed: Unsubscribed,
  trial_period: TrialPeriod,
  unlimited_free_account: UnlimitedFreeAccount,
};

export function getPlanType(proCompany: ProCompany | undefined): AbilityMapKeys {
  if (proCompany?.clubProStatus === 'unsubscribed') {
    return 'unsubscribed';
  }

  if (proCompany?.clubProStatus === 'unlimited_free_account') {
    return 'unlimited_free_account';
  }

  const isInTrialPeriod =
    proCompany?.clubProStatus === 'trial_period_activated' ? 'trial_period' : null;
  return isInTrialPeriod ?? (proCompany?.clubProPlanType || 'unsubscribed');
}

export type AbilityMapKeys = keyof typeof abilityMap;
