import React from 'react';

import { Link } from 'components/Link';
import { Devis } from 'types';
import { getDevisUrl } from 'utils/urls';

type Props = {
  devis: Devis;
  label: string;
};

export const DevisLink: React.FC<Props> = ({ devis, label }) =>
  ['sent', 'signed'].includes(devis.status) ? (
    <a
      href={`${APP_CONFIG.appURL}/pro/devis/${devis.token}?isAuthor`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {label}
    </a>
  ) : (
    <Link to={getDevisUrl(devis)}>{label}</Link>
  );
