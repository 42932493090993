import React from 'react';

import classNames from 'classnames';

import SquareFoot from '@travauxlib/shared/src/components/DesignSystem/assets/SquareFoot.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';

type Props = {
  onClick: () => void;
  text: string;
  selected: boolean;
};

export const SelectionCard: React.FC<Props> = ({ onClick, text, selected }) => (
  <Card
    className={classNames('border-1 cursor-pointer', {
      'bg-primary-400': selected,
    })}
    onClick={onClick}
  >
    <div className="flex justify-center">
      <ColoredCircle variant={selected ? 'secondary' : 'primary'} children={<SquareFoot />} />
    </div>
    <div className={classNames('mt-md', { 'text-neutral-0': selected })}>{text}</div>
  </Card>
);
