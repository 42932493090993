import React, { FC } from 'react';

import { DevisFacturationStatus, DevisStatuses } from '@travauxlib/shared/src/types';

import { DevisLink } from 'features/DevisFactures/components/DevisFactureList/DevisLink';
import { Devis, Facture } from 'types';

import { FactureHeaderDevisStatus } from './FactureHeaderDevisStatus';
import { FactureProgressBars } from './FactureProgressBars';

type Props = {
  devis?: Devis;
  facturesList: Facture[];
  facturationStatus?: DevisFacturationStatus;
  showProgressBar?: boolean;
};
export const FacturesBlockHeader: FC<Props> = ({
  devis,
  facturesList,
  facturationStatus,
  showProgressBar = true,
}) => (
  <div>
    <div className="pb-xxs mb-xs sm-desktop:mb-0">
      {devis ? (
        <>
          <div className="text-ds-h5 font-bold mt-xs sm-desktop:mt-0 pb-md flex items-center">
            {`Devis n°${devis.numero}`}&nbsp;-&nbsp;
            <DevisLink devis={devis} label={devis.title} />
            &nbsp;
            <FactureHeaderDevisStatus
              isFacturationClotured={!!facturationStatus?.isClotured}
              isDevisSigned={devis.status === DevisStatuses.signed}
            />
          </div>
          {showProgressBar && <FactureProgressBars devis={devis} facturesList={facturesList} />}
        </>
      ) : (
        <div className="text-ds-h5 font-bold mt-xs sm-desktop:mt-0">Factures indépendantes</div>
      )}
      <div className="hidden sm-desktop:block border-b border-primary mt-md" />
    </div>
  </div>
);
