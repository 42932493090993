import React, { useState } from 'react';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useDevisList } from 'features/Devis/api/useDevisList';
import { Modalites } from 'features/Devis/components/Modalites';
import { Deal } from 'types';
import { getIsFirstDevisSignedImported } from 'utils/getIsFirstDevisSignedImported';

import { Informations } from './Informations';
import { Totaux } from './Totaux';

type Props = {
  deal: Deal;
  submitting: boolean;
  isArchitecte: boolean;
  onPrevious: () => void;
  montantsTVA?: object[];
  prixTotalHT: number;
  prixTotalTTC: number;
  dirtySinceLastSubmit: boolean;
  submitError?: string;
  handleSubmit: (e: React.FormEvent) => void;
};

export const Step2: React.FC<Props> = props => {
  const {
    dirtySinceLastSubmit,
    submitError,
    deal,
    onPrevious,
    submitting,
    isArchitecte,
    montantsTVA = [],
    prixTotalHT,
    prixTotalTTC,
    handleSubmit,
  } = props;

  const [showModalitesPaiement, setShowModalitesPaiement] = useState(false);
  const { devisList } = useDevisList({ dealUuid: deal.uuid, showArchived: true });
  const isFirstDevisSignedImported = getIsFirstDevisSignedImported(devisList);

  return (
    <ModalContent
      cancelAction={{
        label: 'Précédent',
        onClick: onPrevious,
      }}
      validateAction={{
        label: 'Suivant',
        type: 'submit',
        disabled: submitting,
      }}
      handleSubmit={handleSubmit}
    >
      <div>
        <Informations
          isArchitecte={isArchitecte}
          allowNegativeValues={isFirstDevisSignedImported}
        />
        <div className="mb-xs">
          <Checkbox
            id="showModalitesPaiement"
            label="Changer les modalités de paiement"
            onChange={() =>
              setShowModalitesPaiement(showModalitesPaiement => !showModalitesPaiement)
            }
            checked={showModalitesPaiement}
          />
        </div>
        {showModalitesPaiement && (
          <Modalites canEditModalites={!deal.isHemea} prixTotalTTC={prixTotalTTC} />
        )}
        <div className="mb-xs">
          <CheckboxField name="isRGE" id="isRGE" label="Ce devis est un devis RGE" />
        </div>
        <Totaux
          prixTotalHT={prixTotalHT}
          prixTotalTTC={prixTotalTTC}
          shouldDisplayMontantHT={montantsTVA.length > 1}
        />
        {!dirtySinceLastSubmit && submitError && (
          <div className="!text-danger text-right">{submitError}</div>
        )}
      </div>
    </ModalContent>
  );
};
