import React from 'react';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbol.svg?react';
import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

type OwnProps = {
  archived: boolean;
  handleAccept: () => Promise<void>;
  label: string;
};

type Props = OwnProps & {
  handleClose: () => void;
};

const ArchiveDealModal: React.FC<Props> = ({ archived, handleAccept, handleClose, label }) => (
  <ModalContent>
    <div className="flex justify-between items-center font-bold">
      {archived ? 'Désarchiver ' : 'Archiver '} {label}
      <div className="flex">
        <IconButton
          variant="success"
          onClick={async () => {
            await handleAccept();
            handleClose();
          }}
          data-testid="confirm-archive-deal"
        >
          <CheckSymbol />
        </IconButton>
        <IconButton variant="error" onClick={handleClose}>
          <CrossSymbol />
        </IconButton>
      </div>
    </div>
  </ModalContent>
);

export const useOpenArchiveDealModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return ({ archived, handleAccept, label }: Omit<Props, 'handleClose'>) =>
    openModal(ArchiveDealModal, {
      label,
      archived,
      handleAccept,
      size: 'md',
    });
};
