import React from 'react';

import { Form } from 'react-final-form';

import PlayCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlayCircle.svg?react';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { FileDropzoneField } from '@travauxlib/shared/src/components/DesignSystem/components/FileDropzone/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { Contractor } from '@travauxlib/shared/src/types/';
import {
  required,
  requiredValidSiret,
  validateEmail,
  validateSiret,
} from '@travauxlib/shared/src/utils/form';

import { useUploadTempS3Files } from 'api/useUploadTempS3Files';
import { AddressField } from 'features/Deals/components/ClientDetails/AddressField';

import { useCreateContractor } from '../../api/useCreateContractor';
import { useUpdateContractor } from '../../api/useUpdateContractor';

export type ContractorModalProps = {
  proContractor?: Contractor;
  onSave?: () => void;
  handleClose?: () => void;
  defaultCheckedRGE?: boolean;
};

type FormValues = Contractor & {
  disabledBillingGoogleMaps?: boolean;
  isRGE?: boolean;
};

export const ContractorDetails: React.FC<ContractorModalProps> = ({
  proContractor,
  handleClose,
  onSave,
  defaultCheckedRGE,
}) => {
  const { createContractor } = useCreateContractor();
  const { updateContractor } = useUpdateContractor();
  const { uploadFiles } = useUploadTempS3Files();

  const customValidateSiret = (value: string, allValues: FormValues): string | undefined =>
    !!allValues.isRGE ? requiredValidSiret(value) : validateSiret(value);

  return (
    <Form<FormValues>
      initialValues={{
        ...proContractor,
        isRGE: !!proContractor?.certificationsRGE?.length || defaultCheckedRGE,
      }}
      onSubmit={async rawValues => {
        const values = {
          ...rawValues,
          billingGoogleMapsAddress: rawValues.disabledBillingGoogleMaps
            ? undefined
            : rawValues.billingGoogleMapsAddress,
          certificationsRGE: rawValues.certificationsRGE || [],
        };
        if (values.uuid) {
          await updateContractor(values);
        } else {
          await createContractor(values);
        }
        handleClose?.();
        onSave?.();
      }}
    >
      {({ handleSubmit, submitting, values }) => (
        <ModalContent
          data-testid="contractor-form"
          handleSubmit={handleSubmit}
          validateAction={{
            label: proContractor ? 'Valider' : 'Créer un nouveau prestataire',
            type: 'submit',
            disabled: submitting,
          }}
        >
          <div className="mb-xmd">
            <div className="mb-md">
              <InputField
                label="Nom"
                placeholder="Exemple : Jean Dupont ou SARL Dupuis"
                id="contractor-name"
                name="name"
                validate={required}
              />
            </div>
            <div className="mb-md">
              <InputField
                label="Adresse e-mail"
                id="contractor-email"
                placeholder="Exemple : jean.dupont@mail.com"
                name="email"
                type="email"
                validate={validateEmail}
              />
            </div>
            <div className="mb-md">
              <InputField
                id="contractor-phone-number"
                label="Numéro de téléphone"
                placeholder="Exemple : 06 12 34 56 78"
                name="phoneNumber"
              />
            </div>
            <AddressField
              label="Adresse"
              formattedAddressFieldName="billingFormattedAddress"
              disabledFieldName="disabledBillingGoogleMaps"
              googleMapsFieldName="billingGoogleMapsAddress"
              addressComplementFieldName="billingAddressComplement"
              legacyFieldName="billingAddress"
              isRequired
            />
            <div className="mb-md">
              <InputField
                label="Nom complet du propriétaire"
                placeholder="Exemple : Jean Dupont"
                id="fullname"
                name="fullname"
              />
            </div>
            <div className="mb-md">
              <CheckboxField
                id="isRGE"
                name="isRGE"
                label="Entreprise RGE"
                data-testid="checkbox-rge"
                disabled={defaultCheckedRGE}
              />
              {values.isRGE && (
                <>
                  <div className="mt-sm">Ajoutez la ou les certifications ci-dessous : </div>
                  <div className="mt-sm">
                    <FileDropzoneField
                      name="certificationsRGE"
                      uploadFiles={uploadFiles}
                      value={proContractor?.certificationsRGE}
                      multiple
                      validate={required}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="mb-md">
              <InputField
                name="siret"
                id="siret"
                label="SIRET"
                validate={customValidateSiret}
                placeholder="813776416XXXXX"
                parse={value => value?.replace(/ /g, '')}
                data-testid="siret"
              />
            </div>
            <a
              className="bg-transparent hover:underline !text-dark !font-bold !flex !items-center !mb-xmd"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.pappers.fr"
            >
              <PlayCircle className="text-primary mr-xs w-lg h-lg" />
              Vous ne trouvez pas le numero de SIRET ?
            </a>
            <div className="mb-md">
              <NumberInputField
                name="capital"
                id="capital"
                label="Capital"
                placeholder="500"
                suffix="€"
              />
            </div>
          </div>
        </ModalContent>
      )}
    </Form>
  );
};
