import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { useUpdateTypePrestationArchi } from 'features/Deals/api/useUpdateTypePrestationArchi';
import { Deal } from 'types';

const typePrestationArchi = [
  'Livrable suite à un rendez-vous conseil',
  'Mission partielle',
  'Mission complète',
  'Aucune',
];

type Props = {
  dealUuid: string;
};

export const TypePrestationArchi: React.FC<Props> = ({ dealUuid }) => {
  const { updateTypePrestationArchi, isLoading } = useUpdateTypePrestationArchi();

  const handleUpdateTypePrestationArchi = (typePrestationArchi: string): Promise<Deal> =>
    updateTypePrestationArchi({
      typePrestationArchi,
      dealUuid,
    });

  return (
    <div className="!text-center">
      <div className="!mb-xmd">Quel type de prestation allez-vous effectuer pour le client ?</div>
      <div className="inline-flex flex-col items-start !text-left">
        {typePrestationArchi.map(typePrestationArchi => (
          <Button
            size="sm"
            key={typePrestationArchi}
            className="!mb-xs"
            disabled={isLoading}
            onClick={() => handleUpdateTypePrestationArchi(typePrestationArchi)}
          >
            {typePrestationArchi}
          </Button>
        ))}
      </div>
    </div>
  );
};
