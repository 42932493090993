import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Devis } from 'types';

import { DEVIS_KEY } from './useDevis';
import { DEVIS_LIST_KEY } from './useDevisList';

const handleSendRelanceEmail = ({
  devis,
  emailInfo,
}: {
  devis: Devis;
  emailInfo: object;
}): Promise<Devis> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${devis.dealUuid}/devis/${devis.token}/relance-mail`;
  return request(requestURL, {
    method: 'POST',
    body: emailInfo,
  });
};

export const useSendRelanceEmail = (): typeof handleSendRelanceEmail => {
  const queryClient = useQueryClient();

  const { mutateAsync: sendDevisEmail } = useMutation({
    mutationFn: handleSendRelanceEmail,
    onSuccess: (devis: Devis) => {
      queryClient.setQueryData([DEVIS_KEY, devis.dealUuid, devis.token], devis);
      queryClient.invalidateQueries({ queryKey: [DEVIS_LIST_KEY, devis.dealUuid] });
      toast.success('La relance a bien été envoyée');
    },
    onError: () => {
      toast.error('Il y a eu une erreur, merci de réessayer');
    },
  });

  return sendDevisEmail;
};
