import dayjs from 'dayjs';

import { Devis } from 'types';

export const wasAtLeastOneDevisCreatedBeforeDate = (
  devisList: Devis[],
  date: string | undefined,
): boolean => {
  if (!date) {
    return false;
  }

  return devisList.some(devis => dayjs(devis.createdAt).diff(dayjs(date)) < 0);
};
