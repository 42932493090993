import React from 'react';

import ChantierPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ChantierPicto.svg?react';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

const DemandeChantierFinishedModal: React.FC = () => (
  <ModalContent
    validateAction={{
      label: 'Retourner sur le site hemea',
      onClick: () => window.location.replace(`${APP_CONFIG.wwwURL}/fr/pro/tarifs`),
    }}
  >
    <div className="flex sm-desktop:flex-row flex-col items-center">
      <div className="mr-lg mb-md sm-desktop:mb-0">
        <ChantierPicto className="w-[80px] h-[80px] sm-desktop:w-[100px] sm-desktop:h-[100px]" />
      </div>
      <div className="flex flex-col text-neutral-600 text-ds-b2">
        <span className="mb-xxs">
          Notre équipe étudie vos informations pour vérifier l’éligibilité de votre activité. Nous
          vous recontacterons dans les plus brefs délais.
        </span>
        <span className="mb-xxs">
          En attendant, nous vous invitons à retourner sur notre site pour découvrir le Club Pro
          hemea : le logiciel BTP qui vous fait gagner du temps et de l’argent.
        </span>
      </div>
    </div>
  </ModalContent>
);

export const useOpenDemandeChantierFinishedModal = (): (() => void) => {
  const openModal = useOpenModal();

  return () =>
    openModal(DemandeChantierFinishedModal, {
      closable: false,
      title: 'Nous avons bien reçu votre demande !',
    });
};
