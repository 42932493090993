import React from 'react';

import { centraleAchatSideBarTabs } from 'utils/constants';

import { SideBarLink } from '../SideBarLink';

export const CentraleAchatSideBar: React.FC = () => (
  <div className="!flex !flex-col !h-full !pb-sm">
    <h3 className="!mb-0 !pb-md !font-bold !px-sm">Centrale d'achat</h3>
    <div className="!flex !flex-col !h-full !overflow-auto">
      {centraleAchatSideBarTabs.map(item => (
        <SideBarLink label={item.label} path={item.to} key={item.label} />
      ))}
    </div>
  </div>
);
