import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Deal } from 'types';

import { PRO_CLIENTS_KEY } from '../../ProClients/api/useProClients';

const handleCreateDeal = (deal: Deal): Promise<Deal> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals`;
  return request(requestURL, {
    method: 'POST',
    body: deal,
  });
};

export const useCreateDeal = (): typeof handleCreateDeal => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutateAsync: createDeal } = useMutation({
    mutationFn: handleCreateDeal,
    onSuccess: (result: Deal) => {
      navigate(`/deals/${result.uuid}`);
      queryClient.invalidateQueries({ queryKey: [PRO_CLIENTS_KEY] });
    },
    onError: () => {
      toast.error("Le client n'a pas pu être créé");
    },
  });
  return createDeal;
};
