import React from 'react';

import dayjs from 'dayjs';
import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';

import InfoCircle from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircle.svg?react';

import { RejectionPayload } from 'features/ConsultationOffer/api/useConsultationOffer';
import { SlotComponent } from 'features/ConsultationOffer/components/RDVSelector/Slot';
import { Deal, ConsultationOffer } from 'types';

import { RejectComponent } from './components/Reject';

type Props = {
  slotsRendezVous: string[];
  handleAccept: (rdvAt: string) => Promise<ConsultationOffer | Deal>;
  handleReject?: (payload: RejectionPayload) => Promise<void>;
  submitting: boolean;
  dateDebutEstimee?: string;
};

export const RdvSelector: React.FC<Props> = ({
  slotsRendezVous,
  handleAccept,
  handleReject,
  submitting,
  dateDebutEstimee,
}) => {
  const slotsGroupedByDay = _groupBy(slotsRendezVous, date => dayjs(date).format('ddd DD MMM'));

  return (
    <>
      <div>Rendez-vous client</div>
      <div className="text-neutral-700 text-ds-b1">
        Ce chantier vous intéresse ? Choississez le créneau qui vous arrange parmi les
        disponibilités du client.
      </div>
      <div className="flex justify-center mb-md flex-wrap">
        {_keys(slotsGroupedByDay).map(day => (
          <div className="ml-xs max-w-[150px]" key={day}>
            <SlotComponent
              day={day}
              onChooseSlot={handleAccept}
              hourSlots={slotsGroupedByDay[day]}
              submitting={submitting}
              dateDebutEstimeeTravaux={dateDebutEstimee}
            />
          </div>
        ))}
      </div>
      <div className="text-ds-b1 mb-xl text-info-800 flex items-start">
        <div className="flex mr-xs">
          <InfoCircle className="w-lg" />
        </div>
        <div>
          Si aucun créneau ne convient, contactez le chef de projet qui s’occupe du projet pour lui
          communiquer vos disponibilités.
        </div>
      </div>
      {handleReject && (
        <div className="text-center">
          <RejectComponent handleReject={handleReject} />
        </div>
      )}
    </>
  );
};
