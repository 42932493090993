import { useArchiType } from 'api/profileSelectors';
import { Deal } from 'types';

export const useShouldShowFeedbackQuestions = (deal: Deal): boolean => {
  const { typePrestationArchi, feedbackRDV, chaleurDeal, meeting, isHemea, status } = deal;
  const archiType = useArchiType();

  const isFeedbackDone =
    feedbackRDV !== undefined &&
    ((archiType && typePrestationArchi) || (!archiType && chaleurDeal !== undefined));

  return !isFeedbackDone && !!meeting?.hasOccurred && isHemea && status === 'open';
};
