import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { request } from '@travauxlib/shared/src/utils/request';

const fetchChantier = async ({
  queryKey: [, dealUuid],
}: QueryFunctionContext<[string, string]>): Promise<Chantier | null> => {
  try {
    const result = await request(`${APP_CONFIG.apiURL}/pro/deals/${dealUuid}/chantier`);
    return result;
  } catch {
    return null;
  }
};

export const useDealChantier = (
  dealUuid: string,
): { isLoading: boolean; chantier: Chantier | undefined } => {
  const { isLoading, data } = useQuery<Chantier | null>({
    queryKey: ['dealChantier', dealUuid],
    queryFn: fetchChantier,
    retry: false,
  });

  return { isLoading, chantier: data || undefined };
};
