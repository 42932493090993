import React from 'react';

import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { SuiviChantier } from '@travauxlib/shared/src/types';
import { required } from '@travauxlib/shared/src/utils/form';

import { useProClient } from 'features/ProClients/api/useProClient';
import { getDealRootUrl } from 'utils/urls';

import { useSendSuivi } from '../api/useSendSuivi';

type Props = {
  suivi: SuiviChantier;
  handleClose?: () => void;
};

const placeholderText = `Bonjour,

Voici le suivi que nous vous proposons pour la réalisation du chantier.
Nous vous remercions pour votre confiance.

Cordialement`;

export const SendSuiviModalContent: React.FC<Props> = ({ handleClose, suivi }) => {
  const navigate = useNavigate();
  const sendSuivi = useSendSuivi();
  const client = useProClient(suivi.clientUuid);

  return (
    <Form<{ emailSubject: string; emailContent: string; clientEmail: string }>
      onSubmit={async body => {
        await sendSuivi({
          suivi,
          body,
        });
        navigate(getDealRootUrl(suivi.dealUuid));
      }}
    >
      {({ handleSubmit, submitting }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Envoyer',
            disabled: submitting,
            loading: submitting,
            type: 'submit',
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: handleClose,
          }}
        >
          <div className="grid grid-cols-12 gap-md w-full">
            <div className="mb-md col-span-full sm-desktop:col-span-6">
              <InputField
                id="clientEmail"
                name="clientEmail"
                label="Email du client"
                initialValue={client?.email}
                validate={required}
              />
            </div>
            <div className="mb-md col-span-full sm-desktop:col-span-6">
              <InputField id="emailSubject" name="emailSubject" label="Objet" validate={required} />
            </div>
          </div>
          <TextareaField
            id="emailContent"
            name="emailContent"
            label="Contenu"
            validate={required}
            initialValue={placeholderText}
            rows={5}
          />
        </ModalContent>
      )}
    </Form>
  );
};
