import React from 'react';

import { useFormState } from 'react-final-form';

import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { DropdownMultiField } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { PhoneInputField } from '@travauxlib/shared/src/components/DesignSystem/components/PhoneInput/Field';
import { optionActivity } from '@travauxlib/shared/src/types';
import { departmentsWithFullNames } from '@travauxlib/shared/src/utils/departments';
import { required, requiredValidEmail } from '@travauxlib/shared/src/utils/form';
import { parseDateInput } from '@travauxlib/shared/src/utils/time';

import { optionChiffreAffaires } from 'features/DemandeChantier/utils';

import { LegalConditionsLinks } from '../LegalConditionsLinks';

export const FormBody: React.FC = () => {
  const {
    values: { activity },
  } = useFormState();
  const isArchiOrBureauEtudes =
    activity === 'archiInterieur' || activity === 'archiHMONP' || activity === 'bureauEtudes';

  return (
    <div className="flex flex-col pb-xl pt-xl px-md">
      <div className="mb-md">
        <InputField
          id="companyName"
          name="companyName"
          label="Nom de l'entreprise"
          validate={required}
        />
      </div>
      <div className="flex flex-col sm-desktop:flex-row">
        <div className="w-full sm-desktop:mr-xs mb-md">
          <InputField
            id="lastName"
            name="lastName"
            label="Nom"
            placeholder="Dupont"
            validate={required}
          />
        </div>
        <div className="w-full sm-desktop:ml-xs mb-md">
          <InputField
            id="firstName"
            name="firstName"
            label="Prénom"
            placeholder="Jonathan"
            validate={required}
          />
        </div>
      </div>
      <div className="mb-md">
        <PhoneInputField
          id="phoneNumberObj"
          name="phoneNumberObj"
          label="Numéro de téléphone"
          className="w-full"
          helperText="Il sera utilisé pour vous contacter au sujet du logiciel hemea"
          validate={required}
        />
      </div>
      <div className="mb-md">
        <InputField
          id="email"
          name="email"
          label="Email"
          placeholder="nom@email.com"
          validate={requiredValidEmail}
        />
      </div>
      <div className="mb-md">
        <DropdownField
          id="activity"
          name="activity"
          label="Activité"
          helperText="Sélectionnez votre activité, pour que hemea réponde mieux à votre besoin"
          validate={required}
          options={optionActivity}
          disableInput
        />
      </div>
      {!isArchiOrBureauEtudes && (
        <>
          <div className="mb-md">
            <DatePickerField
              id="companyCreationDate"
              name="companyCreationDate"
              label="Date de création de l'entreprise"
              validate={required}
              parse={parseDateInput}
            />
          </div>
          <div className="mb-md">
            <DropdownField
              id="chiffreAffairesDeclare"
              name="chiffreAffairesDeclare"
              label="Chiffre d'affaires"
              disableInput
              options={optionChiffreAffaires}
              validate={required}
            />
          </div>
          <div className="mb-md">
            <DropdownMultiField
              id="interventionZone"
              name="interventionZone"
              label="Zone d'intervention"
              helperText="Sélectionnez tous les départements où vous intervenez"
              options={departmentsWithFullNames}
              validate={required}
            />
          </div>
        </>
      )}
      <div className="mb-xs">
        <LegalConditionsLinks />
      </div>
    </div>
  );
};
