import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { DEVIS_LIST_KEY } from 'features/Devis/api/useDevisList';
import { Devis } from 'types';

type MoveDevisPayload = {
  newDealUuid: string;
  newProjectUuid?: string;
};
const handleMoveDevis =
  (devisToMove: Devis) =>
  ({ newDealUuid, newProjectUuid }: MoveDevisPayload) => {
    const requestURL = `${APP_CONFIG.proApiURL}/deals/${devisToMove.dealUuid}/devis/${devisToMove.token}/move`;

    return request(requestURL, {
      method: 'PUT',
      body: { newDealUuid, newProjectUuid },
    });
  };

export const useMoveDevis = (
  devisToMove: Devis,
): { moveDevis: ReturnType<typeof handleMoveDevis>; isLoading: boolean } => {
  const queryClient = useQueryClient();

  const { mutateAsync: moveDevis, isPending } = useMutation({
    mutationFn: handleMoveDevis(devisToMove),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [DEVIS_LIST_KEY] });
      toast.success('Le devis a bien été déplacé');
    },
    onError: () => {
      toast.error("Un problème est survenu, le devis n'a pas été déplacé");
    },
  });

  return {
    moveDevis,
    isLoading: isPending,
  };
};
