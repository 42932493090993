import _groupBy from 'lodash/groupBy';

import { Lot } from '@travauxlib/shared/src/types';

export const mergeLots = (lots: Lot[]): Lot[] => {
  const groupedLots = _groupBy(lots, lot => lot.label);

  return Object.entries(groupedLots).map(([label, lots]) => ({
    label,
    uuid: lots[0].uuid,
    items: lots
      .flatMap(lot => lot.items)
      .map(item => ({
        ...item,
        lotUuid: lots[0].uuid,
      })),
  }));
};
