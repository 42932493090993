import React, { useCallback } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

type Props = {
  isArchitecte: boolean;
  onClickNew: () => void;
};

export const NoDevisBlock: React.FC<Props> = ({ onClickNew, isArchitecte }) => {
  const onClick = useCallback((): void => {
    onClickNew();
  }, [onClickNew]);

  return (
    <div className="flex flex-col items-center justify-center h-full py-md">
      <div className="bg-primarylight rounded-full px-xl py-xmd mb-xl">
        <ImgLazy url={`${baseImgixUrl}/static/create-new-devis.png`} width={90} />
      </div>
      <Button
        data-testid="create-devis-or-proposition"
        className="mb-xmd"
        type="button"
        onClick={onClick}
      >
        Créer {devisOrProposition({ isArchitecte, prefix: 'un' })}
      </Button>
      <span className="text-center">
        Vous n'avez pas encore de {devisOrProposition({ isArchitecte })} pour ce chantier.
      </span>
    </div>
  );
};
