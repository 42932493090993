import dayjs from 'dayjs';

export const calculateExpirationRatio = (
  createdDate: string,
  expirationDate: string,
): {
  ratio: number;
  time: string;
} => {
  // date as type zonedDateTime
  const expiration = new Date(expirationDate);
  const dateCreated = new Date(createdDate);
  const current = new Date();
  // Calcul de la durée totale en millisecondes
  const totalDuration = expiration.getTime() - dateCreated.getTime();
  const elapsedTime = expiration.getTime() - current.getTime();

  // Calcul du ratio d'écoulement en pourcentage
  const ratio = (1 - elapsedTime / totalDuration) * 100;

  // Calcul du temps écoulé en heures et minutes
  const elapsedHours = Math.floor(elapsedTime / (1000 * 60 * 60)); // hours
  const elapsedMinutes = Math.floor((elapsedTime / (1000 * 60 * 60) - elapsedHours) * 60); // minutes

  // contain elapsed time in hours and minutes formatted as string with dayJS
  const dayTime = dayjs().hour(elapsedHours).minute(elapsedMinutes).format('LT');

  return {
    ratio: parseInt(ratio.toFixed(2)),
    time: dayTime,
  };
};
