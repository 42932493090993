import React, { useState } from 'react';

import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { tvaValues } from '@travauxlib/shared/src/utils/constants';

import { getTvaOptions } from './utils/utils';

import { Chip } from '../DesignSystem/components/Chips';
import { FormLabel } from '../FormLabel';

export type Props = {
  selectedTauxTVA?: number;
  onChange: (tauxTVA?: number) => void;
  label?: string;
};

export const TvaPicker: React.FC<Props> = ({ selectedTauxTVA, onChange, label }) => {
  const [isInputActive, setIsInputActive] = useState(false);

  const handleClick = (value: number | 'custom'): void => {
    if (typeof value === 'number') {
      onChange(value);
      setIsInputActive(false);
    } else {
      setIsInputActive(true);
    }
  };

  const hasCustomValue =
    !!selectedTauxTVA &&
    Object.values(tvaValues).find(({ value }) => value === selectedTauxTVA) == undefined;
  const tvaOptions = getTvaOptions(hasCustomValue ? selectedTauxTVA : undefined);

  return (
    <>
      {label && <FormLabel className="font-bold mb-xs" label={label} />}
      <div className="flex items-center py-sm tablet:py-0  overflow-x-auto gap-xs rounded">
        {tvaOptions.map(({ label, value }, index) => {
          const customSelected = value === 'custom' && (isInputActive || hasCustomValue);
          const selected = !isInputActive && value === selectedTauxTVA;

          return (
            <div key={value} className="min-w-fit">
              <Chip
                selected={selected || customSelected}
                data-testid={`radio_${index}_${value}`}
                onClick={() => handleClick(value)}
                label={label}
              />
            </div>
          );
        })}
      </div>
      {isInputActive && (
        <NumberInput
          id="custom"
          data-testid="custom"
          label="Valeur"
          className="w-full mt-md"
          value={selectedTauxTVA}
          onChange={value => value !== undefined && onChange(value)}
          onKeyPress={e => e.stopPropagation()}
          suffix="%"
        />
      )}
    </>
  );
};
