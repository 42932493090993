import React, { useState } from 'react';

import dayjs from 'dayjs';

import {
  useOpenModal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Meeting } from '@travauxlib/shared/src/types';

import { useCancelDateRDV } from 'features/Deals/api/useCancelDateRDV';
import { EditRdv } from 'features/Deals/components/Activities/EditRdv';
import { Deal } from 'types';

type Props = {
  deal: Pick<Deal, 'conversationUuid' | 'uuid'>;
  meeting: Meeting;
  handleClose: () => void;
};

const RDVModal: React.FC<Props> = ({ deal, meeting, handleClose }) => {
  const [editingRDV, setEditingRDV] = useState(false);
  const { cancelDateRDV, isLoading } = useCancelDateRDV();

  if (editingRDV) {
    return (
      <EditRdv
        meetingDate={dayjs(meeting.startAt)}
        conversationUuid={deal.conversationUuid}
        dealUuid={deal.uuid}
        onClose={() => setEditingRDV(false)}
      />
    );
  }

  return (
    <ModalContent
      validateAction={{
        label: ' Modifier la date',
        type: 'button',
        onClick: () => setEditingRDV(true),
      }}
      cancelAction={{
        label: 'Annuler ce rdv',
        disabled: isLoading,
        type: 'button',
        onClick: async () => {
          await cancelDateRDV({ dealUuid: deal.uuid, meetingUuid: meeting.uuid });
          handleClose();
        },
      }}
    />
  );
};

type OpenRdvModalPayload = {
  deal: Pick<Deal, 'meeting' | 'conversationUuid' | 'uuid'>;
};

export const useOpenRDVModal = (): ((payload: OpenRdvModalPayload) => void) => {
  const openModal = useOpenModal();
  return (payload: OpenRdvModalPayload) =>
    openModal(RDVModal, {
      title: 'Modifier ou annuler le RDV',
      ...payload,
      meeting: payload.deal.meeting!,
      closable: true,
      size: 'md',
      isScrollable: false,
    });
};
