import { ProCompany } from 'types';
import { shouldNavigateToCharte } from 'utils/shouldNavigateToCharte';

import { Ability } from './Ability';

export class Essentiel extends Ability {
  defineAbility(company?: ProCompany, isHemeaEmployee?: boolean): void {
    const { can, cannot } = this.abilityBuilder;

    can('manage', 'BaseDePrix');
    can('manage', 'ProClient');
    can('manage', 'Devis');
    can('manage', 'Factures');
    can('manage', 'Contacts');
    can('manage', 'Deal');
    can('manage', 'Dashboard');
    can('manage', 'DevisCustomization');

    if (shouldNavigateToCharte(this.isLabellisee(company?.status), company, isHemeaEmployee)) {
      cannot('manage', 'Deal', { isHemea: true });
    }

    if (this.isLabellisee(company?.status) || this.isDelabellisee(company?.status)) {
      can('manage', 'SuiviChantier', { isHemea: true });
      can('manage', 'BonCommande', { isHemea: true });
      can('manage', 'Notification', { isHemea: { $in: [true, undefined] } });
    }

    if (this.isLabellisee(company?.status) || this.isLabellisationEnCours(company?.status)) {
      can('manage', 'Labellisation');
    }
  }
}
