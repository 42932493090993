import React from 'react';

import { CounterField } from '@travauxlib/shared/src/components/DesignSystem/components/Counter/Field';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';

import { getMaxedPenalties } from 'features/Chantiers/utils';

type Props = {
  chantierMontantHT: number;
  nbDaysOfDelay: number;
  restePaiementsAAppeler: number;
};

export const PenaltiesModalContent: React.FC<Props> = ({
  chantierMontantHT,
  nbDaysOfDelay,
  restePaiementsAAppeler,
}) => (
  <div className="gap-md flex flex-col">
    <div className="flex">
      <CounterField id="nbDaysOfDelay" name="nbDaysOfDelay" validate={value => value === 0} />
    </div>
    <div>
      <div className="font-bold text-ds-h5">Montant total</div>
      <div className="text-ds-sm text-neutral-600">
        Calcul: Montant du chantier HT / 1000 par jour. <br /> La somme ne doit pas dépasser 5% du
        montant du chantier
      </div>
    </div>
    <div>
      <Input
        suffix="€"
        disabled
        id="penaltiesTotal"
        value={getMaxedPenalties(
          chantierMontantHT,
          nbDaysOfDelay,
          restePaiementsAAppeler,
        ).toString()}
      />
    </div>
  </div>
);
