import React from 'react';

import { useForm } from 'react-final-form';

import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  montantALibererTTC: number;
  avancementGlobal: number;
  customerName: string;
};

export const ConfirmationDemandePaiementModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  montantALibererTTC,
  avancementGlobal,
  customerName,
}) => {
  const form = useForm();
  const { submitting } = form.getState();

  return (
    <SimpleModal
      title="Demande de paiement"
      isOpen={isOpen}
      handleClose={handleClose}
      validateAction={{
        label: 'Confirmer la demande',
        onClick: () => form.submit(),
        disabled: submitting,
        loading: submitting,
      }}
      cancelAction={{ label: "Modifier l'avancement", onClick: handleClose }}
    >
      <div className="text-ds-b2 text-neutral-600">
        Confirmer la demande de libération de fonds à hauteur de{' '}
        <EURCurrency className="font-bold text-neutral-700" amount={montantALibererTTC} />{' '}
        correspondant à un avancement global de{' '}
        <span className="font-bold text-neutral-700">{avancementGlobal}%</span> du chantier pour le
        chantier de <span className="font-bold text-neutral-700">{customerName}</span>
      </div>
    </SimpleModal>
  );
};
