import React from 'react';

import classNames from 'classnames';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { DevisActions } from 'components/DevisActions';
import { StatusTagsDevis } from 'components/StatusTagsDevis';
import { DevisCardDate } from 'features/Deals/features/Devis/components/DevisList/components/DevisCard/DevisCardDate';
import { DevisViewCount } from 'features/Deals/features/Devis/components/DevisViewCount';
import { useNavigateToDevis } from 'features/Deals/features/Devis/hooks/useNavigateToDevis';
import { Devis } from 'types';

type Props = {
  devis: Devis;
  devisList: Devis[];
  isHemea: boolean;
};
export const DevisCard: React.FC<Props> = ({ devis, devisList, isHemea }) => {
  const navigateToDevis = useNavigateToDevis();

  const { numero, archived, viewCount, prixTotalHT, title, isHighlighted } = devis;

  return (
    <Card
      className={classNames(
        'rounded-md mb-xs cursor-pointer transition duration-[3000ms] bg-primarylighter hover:bg-primarylight',
        {
          '!bg-lighter': archived,
          '!bg-primarylight': isHighlighted,
        },
      )}
      onClick={event => navigateToDevis(devis, event)}
    >
      <div className="flex justify-between items-center mb-md">
        <div className="font-bold">{title}</div>
        <div className="flex ignore-move-to-devisfacture">
          <DevisActions devis={devis} devisList={devisList} />
        </div>
      </div>
      {(numero !== undefined || viewCount !== 0) && (
        <div className="flex justify-between mb-md">
          {numero ? <div>Numéro {numero}</div> : null}
          <DevisViewCount viewCount={viewCount} />
        </div>
      )}
      <div className="mb-md font-bold">
        <EURCurrency amount={prixTotalHT} />
      </div>
      <div className="flex justify-between">
        <DevisCardDate devis={devis} />
        <StatusTagsDevis devis={devis} disabled={isHemea ?? false} />
      </div>
    </Card>
  );
};
