import dayjs from 'dayjs';

import { Devis } from 'types';

export const getInitialDevis = (devisList: Devis[]): Devis => {
  let [initialDevis, ...otherDevis] = devisList;

  otherDevis.forEach(devis => {
    if (dayjs(devis.dateSignature).diff(dayjs(initialDevis.dateSignature)) < 0) {
      initialDevis = devis;
    }
  });

  return initialDevis;
};
