import { subject } from '@casl/ability';

import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { useOpenClubProPaywall } from 'hooks/useOpenClubProPaywall';
import { usePermissions } from 'hooks/usePermissions';
import { ProClientAndChantierAddress } from 'types';

import { ModalProClientDetailsUnprotected } from '../components/ClientDetails/ModalProClientDetails';

export const useOpenProClientDetailsModal = (): ((
  proClient?: ProClientAndChantierAddress,
) => void) => {
  const openModal = useOpenModal();
  const isTabletOrMobile = useIsTabletOrMobile();

  const openClubProPaywallModal = useOpenClubProPaywall();

  const { can } = usePermissions();

  return (proClient?: ProClientAndChantierAddress): void => {
    const isAllowed = can('manage', subject('ProClient', { isHemea: proClient?.isHemea }));
    if (!isAllowed) {
      openClubProPaywallModal();
    } else {
      openModal(ModalProClientDetailsUnprotected, {
        title: 'Informations du client',
        proClient,
        closable: true,
        isScrollable: isTabletOrMobile,
      });
    }
  };
};
