import React, { useEffect } from 'react';

import { Form } from 'react-final-form';

import { ReassuranceColumn } from 'components/ReassuranceColumn';
import { useHandleCreateCompanyWithSiret } from 'features/Registration/hooks/useHandleCreateCompanyWithSiret';
import { reassuranceColumnConfig } from 'features/Registration/utils';
import { sendEventToTrackingModules } from 'utils/sendEventToTrackingModules';

import { Header } from './Header';
import { SearchCompany } from './SearchCompany';

export const SiretStep: React.FC = () => {
  const handleCreateCompanyWithSiret = useHandleCreateCompanyWithSiret();

  useEffect(() => {
    sendEventToTrackingModules({ event: 'pro.siret.started' });
  }, []);

  return (
    <Form<{ siret: number }>
      onSubmit={async values => {
        await handleCreateCompanyWithSiret(values);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="!h-full !min-h-screen">
          <div className="flex -mx-md h-full min-h-screen">
            <ReassuranceColumn config={reassuranceColumnConfig} />
            <div className="sm-desktop:p-md bg-white w-full sm-desktop:right-0 sm-desktop:min-w-7/12 sm-desktop:pl-[7.25rem] sm-desktop:pr-[9.25rem] sm-desktop:pt-xxl">
              <Header />
              <SearchCompany />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
