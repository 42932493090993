import { ProCompany } from 'types';
import { shouldNavigateToCharte } from 'utils/shouldNavigateToCharte';

import { Ability } from './Ability';

export class Expert extends Ability {
  defineAbility(company?: ProCompany, isHemeaEmployee?: boolean): void {
    const { can, cannot } = this.abilityBuilder;

    can('manage', 'SuiviChantier');
    can('manage', 'Notification');
    can('activate', 'HemeaPay');
    can('manage', 'BatiPrix');
    can('manage', 'BonCommande');
    can('manage', 'BaseDePrix');
    can('manage', 'ProClient');
    can('manage', 'Devis');
    can('manage', 'Deal');
    can('manage', 'Factures');
    can('manage', 'Contacts');
    can('manage', 'DevisRGE');
    can('manage', 'Dashboard');
    can('manage', 'DevisCustomization');

    if (shouldNavigateToCharte(this.isLabellisee(company?.status), company, isHemeaEmployee)) {
      cannot('manage', 'Deal', { isHemea: true });
    }
    if (this.isLabellisee(company?.status) || this.isLabellisationEnCours(company?.status)) {
      can('manage', 'Labellisation');
    }
  }
}
