import React from 'react';

import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { SuiviChantier } from '@travauxlib/shared/src/types';

import { SendSuiviModalContent } from '../components/SendSuiviModalContent';

type Props = {
  suivi: SuiviChantier;
  handleClose?: () => void;
};

const SendSuiviModal: React.FC<Props> = props => <SendSuiviModalContent {...props} />;

export const useOpenSendSuiviModal = (): ((props: Props) => void) => {
  const openModal = useOpenModal();

  return (props: Props) =>
    openModal(SendSuiviModal, {
      closable: true,
      size: 'lg',
      title: props.suivi.title,
      ...props,
    });
};
