import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { ReassuranceColumnConfig } from 'components/ReassuranceColumn';

export const reassuranceColumnConfig: ReassuranceColumnConfig = {
  reassuranceSentences: [
    'Révolutionnez votre quotidien avec 15 jours d’essai gratuit.',
    'Gagnez 10h par mois et signez +30% de devis.',
    'Bénéficiez de notre accompagnement, au quotidien.',
    'Impressionnez vos clients avec des devis pro et design.',
    'Signez plus grâce au suivi d’ouverture des devis.',
  ],
  cartouchesTopData: [
    {
      label: 'Dashboard',
      imgUrl: `${baseImgixUrl}/static/onboarding-pro/Dashboard.png`,
      position: 'top-left',
      size: 'md',
    },
    {
      label: 'Devis',
      imgUrl: `${baseImgixUrl}/static/onboarding-pro/Devis.png`,
      position: 'bottom-left',
      size: 'sm',
    },
  ],
  cartouchesBottomData: [
    {
      label: 'Base de prix',
      imgUrl: `${baseImgixUrl}/static/onboarding-pro/BaseDePrix.png`,
      position: 'bottom-right',
      size: 'sm',
    },
    {
      label: 'Espace chantier',
      imgUrl: `${baseImgixUrl}/static/onboarding-pro/EspaceChantier.png`,
      position: 'top-right',
      size: 'md',
    },
  ],
  calendlySource: 'exit-during-new-registration',
};
