import React from 'react';

import classNames from 'classnames';

type Props = {
  className?: string;
  title: string;
};

export const Title: React.FC<Props> = ({ className, title }) => (
  <span className={classNames(className, 'font-bold text-ds-h3')}>{title}</span>
);
