import React, { useState } from 'react';

import { Form } from 'react-final-form';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { validateConditions } from '@travauxlib/shared/src/utils/form';

import { compensationDevis, getMaxedPenalties, penaltiesDevis } from 'features/Chantiers/utils';
import { useCreateDevis } from 'features/Devis/api/useCreateDevis';
import { getDevisEnvoiUrl } from 'utils/urls';

import { CompensationModalContent } from './CompensationModalContent';
import { PenaltiesModalContent } from './PenaltiesModalContent';
import { SelectionCard } from './SelectionCard';

type Props = {
  onClose: () => void;
  chantierMontantHT: number;
  dealUuid: string;
  isOpen: boolean;
  restePaiementsAAppeler: number;
};

export const PenaltiesModal: React.FC<Props> = ({
  onClose,
  chantierMontantHT,
  isOpen,
  dealUuid,
  restePaiementsAAppeler,
}) => {
  const [displayPenaltiesContent, setDisplayPenaltiesContent] = useState(true);
  const { createDevis, isLoading } = useCreateDevis({
    redirectFc: getDevisEnvoiUrl,
  });

  return (
    <Modal isOpen={isOpen} title="Motif du remboursement" handleClose={onClose} size="md">
      <>
        <Form<{
          compensationAmount: number;
          compensationDescription: string;
          nbDaysOfDelay: number;
        }>
          onSubmit={async ({ nbDaysOfDelay, compensationAmount, compensationDescription }) => {
            const devisToCreate = displayPenaltiesContent
              ? penaltiesDevis({
                  prixTotal: getMaxedPenalties(
                    chantierMontantHT,
                    nbDaysOfDelay,
                    restePaiementsAAppeler,
                  ),
                  nbDaysOfDelay,
                })
              : compensationDevis({
                  compensationAmount,
                  compensationDescription,
                });

            await createDevis({
              devis: devisToCreate,
              dealUuid: dealUuid,
            });
          }}
          initialValues={{
            compensationAmount: 0,
            nbDaysOfDelay: 0,
          }}
        >
          {({ handleSubmit, invalid, values, submitting }) => (
            <ModalContent
              validateAction={{
                label: 'Envoyer',
                loading: isLoading || submitting,
                type: 'submit',
                disabled: invalid || submitting,
              }}
              cancelAction={{ label: 'Annuler', onClick: onClose }}
              handleSubmit={handleSubmit}
            >
              <div className="flex gap-md mb-md">
                <SelectionCard
                  onClick={() => setDisplayPenaltiesContent(true)}
                  text="Pénalités de retard"
                  selected={displayPenaltiesContent}
                />
                <SelectionCard
                  onClick={() => setDisplayPenaltiesContent(false)}
                  text="Dédommagement"
                  selected={!displayPenaltiesContent}
                />
              </div>
              {displayPenaltiesContent ? (
                <PenaltiesModalContent
                  chantierMontantHT={chantierMontantHT}
                  nbDaysOfDelay={values.nbDaysOfDelay}
                  restePaiementsAAppeler={restePaiementsAAppeler}
                />
              ) : (
                <CompensationModalContent restePaiementsAAppeler={restePaiementsAAppeler} />
              )}
              <div className="mt-md">
                <CheckboxField
                  label={
                    `Je confirme avoir vu avec le pro et vérifié les informations renseignées. ` +
                    `J'ai conscience que les informations que je viens de rentrer ne peuvent être modifiées.`
                  }
                  name="acceptConditions"
                  validate={validateConditions}
                />
              </div>
            </ModalContent>
          )}
        </Form>
      </>
    </Modal>
  );
};
