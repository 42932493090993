import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Facture } from 'types';

type Props = {
  startDate?: string;
  endDate?: string;
  numero?: string;
  fetchArchived?: boolean;
  referenceDate?: 'dateEmission' | 'lastModifiedAt';
};

export const FACTURES_BY_PERIOD_KEY = 'factures-by-period';
export const useFacturesByPeriod = ({
  startDate,
  endDate,
  numero,
  fetchArchived,
  referenceDate,
}: Props): {
  isLoading: boolean;
  factures: Facture[];
} => {
  const { data, isLoading } = useQuery<{
    data: Facture[];
  }>({
    queryKey: [FACTURES_BY_PERIOD_KEY, startDate, endDate, numero, fetchArchived, referenceDate],

    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/factures/by-period`;
        if (!startDate || !endDate) {
          return {
            data: [],
          };
        }
        return await request(requestURL, {
          params: {
            startDate,
            endDate,
            numero,
            fetchArchived,
            filterBy: referenceDate,
          },
        });
      } catch (err) {
        toast.error("Les factures n'ont pas pu être récupérées");
        throw err;
      }
    },
  });
  return {
    factures: data?.data || [],
    isLoading,
  };
};
