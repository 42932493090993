import React from 'react';

import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useOpenContractorDetailsModal } from 'features/Contacts/hooks/useOpenContractorDetailsModal';

export const NoContractorModal: React.FC<{
  handleClose: (() => void) | undefined;
  openCreateBonCommandeModal: () => void;
}> = ({ handleClose, openCreateBonCommandeModal }) => {
  const openContractorDetailsModal = useOpenContractorDetailsModal();

  return (
    <ModalContent
      validateAction={{
        label: 'Ajouter un prestataire',
        type: 'submit',
        onClick: () =>
          openContractorDetailsModal({
            onSave: openCreateBonCommandeModal,
          }),
      }}
      cancelAction={{
        label: 'Retour',
        onClick: handleClose,
      }}
    >
      <div className="flex justify-start items-center pt-xs">
        Vous n'avez aucun prestataire enregistré.
      </div>
      <div className="flex justify-start items-center pt-xs pb-xl">
        Il vous en faut au moins un pour générer un bon de commande.
      </div>
    </ModalContent>
  );
};
