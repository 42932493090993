import React from 'react';

import dayjs from 'dayjs';

import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import { ProUser } from '@travauxlib/shared/src/types/company';
import { shortDateTimeFormat } from '@travauxlib/shared/src/utils/time';

import { Deal } from 'types';

import { useOpenRDVModal } from '../../hooks/useOpenRDVModal';

type Props = {
  clientEmail?: string;
  clientPhoneNumber?: string;
  clientFormattedAddress?: string;
  deal?: Deal;
  proUser?: ProUser;
};

export const ProClientInfo: React.FC<Props> = ({
  clientEmail,
  clientPhoneNumber,
  clientFormattedAddress,
  deal,
}) => {
  const openRDVModal = useOpenRDVModal();

  return (
    <>
      {clientFormattedAddress && (
        <div className="!flex !items-center !mb-xs text-ds-sm">
          <Location className="w-sm h-sm mr-sm" /> {clientFormattedAddress}
        </div>
      )}
      {clientPhoneNumber && (
        <div className="!flex !items-center !mb-xs text-ds-sm">
          <Phone className="w-sm h-sm mr-sm" /> {clientPhoneNumber}
        </div>
      )}
      {clientEmail && (
        <div className="!flex !items-center !mb-xs text-ds-sm">
          <Envelope className="w-sm h-sm mr-sm" /> {clientEmail}
        </div>
      )}
      {deal?.meeting && !deal.meeting.hasOccurred && (
        <div className="!flex !items-center !mb-xs !text-ds-sm">
          <Calendar className="w-sm h-sm mr-sm" />
          <span className="!mr-sm">
            Rendez-vous le {shortDateTimeFormat(dayjs(deal.meeting.startAt))}
          </span>
          <Edit className="w-sm h-sm cursor-pointer" onClick={() => openRDVModal({ deal })} />
        </div>
      )}
    </>
  );
};
