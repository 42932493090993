import React from 'react';

import SwitchDown from '@travauxlib/shared/src/components/DesignSystem/assets/SwitchDown.svg?react';
import SwitchIndeterminate from '@travauxlib/shared/src/components/DesignSystem/assets/SwitchIndeterminate.svg?react';
import SwitchUp from '@travauxlib/shared/src/components/DesignSystem/assets/SwitchUp.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

type Props = {
  columnName: string;
  sortedBy: {
    columnName: string;
    order: 'asc' | 'desc';
  };
};

export const Chevron: React.FC<Props> = ({ columnName, sortedBy }) => {
  const shouldDisplayChevron = (columnName: string, direction: 'asc' | 'desc'): boolean =>
    sortedBy.columnName === columnName && sortedBy.order === direction;

  const isSortedByAsc = columnName ? shouldDisplayChevron(columnName, 'asc') : true;
  const isSortedByDesc = columnName ? shouldDisplayChevron(columnName, 'desc') : false;
  const isUnsorted = !isSortedByAsc && !isSortedByDesc;

  return (
    <IconButton className="flex-shrink-0" size="sm">
      {isSortedByAsc && <SwitchUp />}
      {isSortedByDesc && <SwitchDown />}
      {isUnsorted && <SwitchIndeterminate />}
    </IconButton>
  );
};
