import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { useProfile } from 'api/useProfile';
import { DealHeader } from 'features/Deals/components/DealHeader';
import { useOpenDealClientDetailsModal } from 'features/Deals/hooks/useOpenDealClientDetailsModal';
import { Deal } from 'types';
import { getDealRootUrl } from 'utils/urls';

import { useProClient } from './api/useProClient';
import { DealCard } from './components/DealCard';
import { ProClientActions } from './components/ProClientActions';

export const ProClients: React.FC = () => {
  const { profile } = useProfile();
  const { uuid } = useParams();
  const proClient = useProClient(uuid);
  const navigate = useNavigate();
  const isTabletOrMobile = useIsTabletOrMobile();
  const openDealClientDetailsModal = useOpenDealClientDetailsModal();

  const deals = profile?.isProCompanyAdmin
    ? proClient?.deals
    : proClient?.deals.filter(deal => deal.proUserUuid === profile.uuid);

  useEffect(() => {
    if (proClient?.deals?.length === 1) {
      const firstDeal = proClient.deals[0];
      navigate(getDealRootUrl(firstDeal.uuid), { replace: true });
    }
  }, [proClient?.deals?.length, proClient?.deals?.[0].uuid]);

  if (!proClient) {
    return <LoaderWrapper isFullHeightScreen />;
  }

  return (
    <div className="!overflow-auto !bg-white !flex !flex-col sm-desktop:!py-xmd">
      <DealHeader
        proClient={proClient}
        actions={<ProClientActions proClient={proClient} />}
        isTabletOrMobile={isTabletOrMobile}
        deal={proClient.deals.length === 1 ? proClient.deals[0] : undefined}
      />
      <div className="grid grid-cols-12 gap-xl">
        <div className="col-span-full sm-desktop:col-span-6">
          <Card
            className="border-[0.125rem] flex items-center border-transparent hover:border-primary bg-primarylighter h-full"
            onClick={() =>
              openDealClientDetailsModal({
                deal: {
                  customerName: proClient.name,
                  customerPhoneNumber: proClient.phoneNumber,
                  customerEmail: proClient.email,
                  proClientUuid: proClient.uuid,
                } as Deal,
              })
            }
          >
            <PlusCircle className="text-primary w-xxl h-xxl" />
          </Card>
        </div>
        {deals?.map(deal => (
          <div className="col-span-full sm-desktop:col-span-6" key={deal.uuid}>
            <DealCard deal={deal} />
          </div>
        ))}
      </div>
    </div>
  );
};
