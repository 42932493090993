import React, { useEffect, useState } from 'react';

import { CheckboxList } from '@travauxlib/shared/src/components/DesignSystem/components/CheckboxList';
import { EditableText } from '@travauxlib/shared/src/components/DesignSystem/components/EditableText';
import { DevisStatuses } from '@travauxlib/shared/src/types';

import { useUpdateProjectTitle } from 'features/Deals/features/Devis/api/useUpdateProjectTitle';

type Props = {
  dealUuid: string;
  projectUuid: string;
  projectTitle: string;
  searchFilters: Array<{
    value: string;
    label: string;
  }>;
  selectedFilters: Array<DevisStatuses | 'archived'>;
  handleUpdateFilters: (values: string[]) => void;
  subTitle: string;
  isBlockTS: boolean;
};

export const Header: React.FC<Props> = ({
  searchFilters,
  selectedFilters,
  handleUpdateFilters,
  subTitle,
  projectTitle,
  projectUuid,
  dealUuid,
  isBlockTS,
}) => {
  const [inputValue, setInputValue] = useState<string>(projectTitle);
  const updateProjectTitle = useUpdateProjectTitle();
  const blockTitle = isBlockTS ? 'Devis de travaux supplémentaires' : 'Devis initial';

  useEffect(() => {
    if (projectTitle !== inputValue) {
      const timeout = window.setTimeout(
        () => updateProjectTitle({ title: inputValue, dealUuid, projectUuid }),
        1000,
      );
      return () => window.clearTimeout(timeout);
    }

    return undefined;
  }, [inputValue]);

  return (
    <div className="rounded p-xmd bg-primarylighter">
      <div className="text-ds-b2 text-neutral-700 font-bold">{blockTitle}</div>
      <EditableText
        value={inputValue}
        onChange={setInputValue}
        id="project-title"
        autoFocus
        dense="md"
        textClassName="text-ds-h5 font-bold"
        wrapperClassName="mb-xs"
      />
      <div className="font-bold sm-desktop:hidden mb-sm">{subTitle}</div>
      <div className="flex justify-between items-center flex-wrap mb-md">
        <span className="hidden sm-desktop:block">{subTitle}</span>
        <CheckboxList
          className="flex flex-wrap"
          id={projectUuid}
          value={selectedFilters}
          options={searchFilters}
          onChange={values => handleUpdateFilters(values)}
          inline
        />
      </div>
      <div className="hidden sm-desktop:block border-b border-primary" />
    </div>
  );
};
