import React from 'react';

import { useIsArchitecte } from 'api/profileSelectors';
import { Devis } from 'types';

import { TransferDevisForm } from './TransferDevisForm';

type Props = {
  devisToTransfer: Devis;
  handleClose: () => void;
};

export const TransferDevisModal: React.FC<Props> = ({ devisToTransfer, handleClose }) => {
  const isArchitecte = useIsArchitecte();

  return (
    <TransferDevisForm
      devisToTransfer={devisToTransfer}
      isArchitecte={isArchitecte}
      onSubmitSuccess={handleClose}
    />
  );
};
