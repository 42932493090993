import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { DevisStatuses } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { Devis } from 'types';

export const DEVIS_LIST_KEY = 'devis_list';

export const useDevisList = ({
  dealUuid,
  showArchived = false,
  showOnlySigned = false,
}: {
  dealUuid: string;
  showArchived?: boolean;
  showOnlySigned?: boolean;
}): { hasArchived: boolean; devisList: Devis[]; isLoaded: boolean } => {
  const { data: devisList = [], isLoading } = useQuery<Devis[]>({
    queryKey: [DEVIS_LIST_KEY, dealUuid],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/devis`;
      const { data } = await request(requestURL);
      return data;
    },
  });

  const filteredDevisList = useMemo(
    () =>
      devisList
        .filter(devis => showArchived || !devis.archived)
        .filter(devis => {
          if (showOnlySigned) {
            return devis.status === DevisStatuses.signed;
          }
          return true;
        }),
    [devisList, showArchived, showOnlySigned],
  );

  const hasArchived = devisList.some(({ archived }) => archived);

  return {
    hasArchived,
    devisList: filteredDevisList,
    isLoaded: !isLoading,
  };
};
