import React, { useMemo } from 'react';

import _partition from 'lodash/partition';
import { useNavigate } from 'react-router-dom';

import Document from '@travauxlib/shared/src/components/DesignSystem/assets/Document.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { getDetailedDevisFacturationStatus } from '@travauxlib/shared/src/utils/facture/getDetailedDevisFacturationStatus';

import { useOpenFacturerDevisModal } from 'features/Devis/hooks/useOpenFacturerDevisModal';
import { Devis, Facture } from 'types';
import { getFactureSituationUrl } from 'utils/urls';

import { DashedActionButton } from './DashedActionButton';
import { FactureBlockContainer } from './FactureBlockContainer';
import { FacturesBlockHeader } from './FacturesBlockHeader';
import { FacturesList } from './FacturesList';

import { useHandleClickCreateNewFacture } from '../../hooks/useHandleClickCreateNewFacture';

type Props = {
  facturesList: Facture[];
  devis?: Devis;
  dealUuid: string;
  isArchived?: boolean;
};

export const FacturesBlock: React.FC<Props> = ({ facturesList, devis, dealUuid, isArchived }) => {
  const navigate = useNavigate();
  const [archivedFactures, factures] = _partition(facturesList, 'archived');

  const facturesToDisplay = isArchived ? archivedFactures : factures;

  const facturationStatus = useMemo(
    () => devis && getDetailedDevisFacturationStatus(factures, devis),
    [devis, factures],
  );

  const hasGeneratedFactureSituation = factures.some(facture => facture.isSituation);
  const openFacturerDevisModal = useOpenFacturerDevisModal();
  const handleClickCreateNewFacture = useHandleClickCreateNewFacture(dealUuid);

  return (
    <>
      {facturesToDisplay.length > 0 && (
        <FactureBlockContainer>
          <FacturesBlockHeader
            facturationStatus={facturationStatus}
            facturesList={facturesToDisplay}
            devis={devis}
            showProgressBar={!isArchived}
          />
          <FacturesList facturationStatus={facturationStatus} facturesList={facturesToDisplay} />
          {!facturationStatus?.isClotured && devis && !isArchived && (
            <DashedActionButton
              label="Ajouter une facture"
              onClick={() => {
                if (hasGeneratedFactureSituation) {
                  navigate(getFactureSituationUrl(devis));
                } else {
                  openFacturerDevisModal(devis);
                }
              }}
            />
          )}
          {!devis && !facturationStatus && !isArchived && (
            <DashedActionButton
              label="Ajouter une facture"
              onClick={() => handleClickCreateNewFacture('Ajouter une facture')}
            />
          )}
        </FactureBlockContainer>
      )}
      {facturesList.length === 0 && !isArchived && (
        <FactureBlockContainer>
          <FacturesBlockHeader
            facturationStatus={facturationStatus}
            facturesList={facturesToDisplay}
            devis={devis}
            showProgressBar={!isArchived}
          />
          <div className="w-full flex justify-center my-md">
            <EmptyState
              inline
              icon={<Document />}
              iconIllustrationSize="md"
              title={
                devis ? 'Aucune facture trouvée pour ce devis' : 'Créer une facture indépendante'
              }
              description={
                devis
                  ? 'Vous pouvez commencer dès à présent sa facturation'
                  : 'Ces factures ne seront reliée à aucun devis'
              }
            />
          </div>
          {devis && !isArchived && (
            <DashedActionButton
              label="Ajouter une facture"
              onClick={() => {
                if (hasGeneratedFactureSituation) {
                  navigate(getFactureSituationUrl(devis));
                } else {
                  openFacturerDevisModal(devis);
                }
              }}
            />
          )}
          {!devis && !isArchived && (
            <DashedActionButton
              label="Ajouter une facture"
              onClick={() => handleClickCreateNewFacture('Ajouter une facture')}
            />
          )}
        </FactureBlockContainer>
      )}
    </>
  );
};
