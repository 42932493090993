import {
  sendEventToTrackingModules as sendEvent,
  TrackingEndpoint,
  TrackingInfo,
} from '@travauxlib/shared/src/api/tracking';

const requestUrl: TrackingEndpoint = `${APP_CONFIG.apiURL}/pro/tracking`;

export const sendEventToTrackingModules = ({
  event,
  userInfo,
  data,
}: Omit<TrackingInfo, 'endpointUrl'>): void =>
  sendEvent({ event, endpointUrl: requestUrl, userInfo, data });
