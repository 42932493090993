import React, { useRef, useState } from 'react';

import classNames from 'classnames';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import Eye from '@travauxlib/shared/src/components/DesignSystem/assets/Eye.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';
import { Attachment } from '@travauxlib/shared/src/types';

import { getFileIconByUrl } from './utils/getFileIconByUrl';

type Props = {
  hideSave?: boolean;
  attachment: Attachment;
  index: number;
  handleRemoveAttachment: (removedIndex: number) => void;
};

export const AttachmentItem: React.FC<Props> = ({
  attachment,
  index,
  handleRemoveAttachment,
  hideSave,
}: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (): void => {
    setIsEditing(false);
  };

  useOnClickOutside(containerRef, handleClickOutside);

  return (
    <>
      <div ref={containerRef} className="!flex !items-center">
        {getFileIconByUrl(attachment.fileUrl)}
        <div className="relative w-full px-md !pl-xxs">
          <InputField
            name={`attachments.${index}.label`}
            id={`attachments.${index}.label`}
            className={classNames(!isEditing && 'border-white !bg-white')}
            disabled={!isEditing}
            autoFocus
          />
        </div>
        <div className="flex items-center gap-xs">
          <IconButton onClick={() => setIsEditing(true)} size="sm">
            <Edit />
          </IconButton>
          <IconButtonLink
            target="_blank"
            rel="noreferrer noopener"
            href={attachment.fileUrl}
            size="sm"
          >
            <Eye />
          </IconButtonLink>
          <IconButton onClick={() => handleRemoveAttachment(index)} size="sm">
            <Delete />
          </IconButton>
        </div>
      </div>
      {!hideSave && (
        <CheckboxField
          id={`attachments.${index}.isDefaultAttachment`}
          name={`attachments.${index}.isDefaultAttachment`}
          label="Joindre automatiquement à mes prochains devis"
        />
      )}
    </>
  );
};
