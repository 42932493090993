import { useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Facture } from 'types';

import { FACTURES_KEY } from './useFacturesList';

const handleArchiveFacture = ({ dealUuid, token }: Facture): Promise<Facture> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/factures/${token}/archive`;
  return request(requestURL, {
    method: 'PATCH',
  });
};

const handleUnarchiveFacture = ({ dealUuid, token }: Facture): Promise<Facture> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/factures/${token}/unarchive`;
  return request(requestURL, {
    method: 'PATCH',
  });
};

export const useArchiveFacture = (): {
  archiveFacture: typeof handleArchiveFacture;
  unarchiveFacture: typeof handleUnarchiveFacture;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: archiveFacture } = useMutation({
    mutationFn: handleArchiveFacture,
    onSuccess: (facture: Facture) => {
      queryClient.invalidateQueries({ queryKey: [FACTURES_KEY, facture.dealUuid] });
      toast.success('La facture a bien été archivée');
    },
    onError: () => {
      toast.error("La facture n'a pas pu être archivée");
    },
  });

  const { mutateAsync: unarchiveFacture } = useMutation({
    mutationFn: handleUnarchiveFacture,
    onSuccess: (facture: Facture) => {
      queryClient.invalidateQueries({ queryKey: [FACTURES_KEY, facture.dealUuid] });
      toast.success('La facture a bien été désarchivée');
    },
    onError: () => {
      toast.error("La facture n'a pas pu être désarchivée");
    },
  });

  return { archiveFacture, unarchiveFacture };
};
