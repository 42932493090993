import React from 'react';

import { useFormState } from 'react-final-form';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { useCanSubmitForm } from '@travauxlib/shared/src/utils/form';

import { CGUBlock } from 'components/CGUBlock';
import { LoginLink } from 'components/LoginLink';

export const FormFooter: React.FC = () => {
  const { submitErrors, submitting } = useFormState();
  const canSubmit = useCanSubmitForm();

  return (
    <div className="px-md">
      {submitErrors?.email && (
        <AlertMessage
          className="mb-sm"
          title="Vous avez déjà un compte avec cette adresse mail"
          level="error"
          to="/login"
          actionLabel="Connexion"
        />
      )}
      <Button
        type="submit"
        data-tracking-group="Inscription_Pro"
        fullwidth
        data-tracking-item="Account"
        className="mb-lg"
        disabled={!canSubmit}
        loading={submitting}
      >
        Créer mon compte
      </Button>
      <div className="text-center mb-xl">
        <LoginLink />
      </div>
      <CGUBlock />
    </div>
  );
};
