import React from 'react';

import { InsuranceForm } from '@travauxlib/shared/src/components/InsuranceForm';
import { InsuranceFormType } from '@travauxlib/shared/src/utils/constants';

import { useUploadInsurance } from 'api/useUploadInsurance';
import { useUploadTempS3Files } from 'api/useUploadTempS3Files';
import { ProCompany } from 'types';

export const DecennaleInsuranceForm: React.FC<{ company: ProCompany }> = ({ company }) => {
  const uploadInsurance = useUploadInsurance();
  const { uploadFiles } = useUploadTempS3Files();
  const hasDecennale = company.validInsurances.find(insurance => insurance.label === 'Décennale');

  return (
    <>
      {hasDecennale ? (
        <div className="whitespace-nowrap">Vous avez une assurance décennale valide.</div>
      ) : (
        <InsuranceForm
          insuranceType={InsuranceFormType.decennale}
          uploadInsurance={uploadInsurance}
          uploadFiles={uploadFiles}
        />
      )}
    </>
  );
};
