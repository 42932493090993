import React from 'react';

import { ButtonGroupField } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup/Field';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { SliderField } from '@travauxlib/shared/src/components/DesignSystem/components/Slider/Field';
import { LigneStatus } from '@travauxlib/shared/src/types';

import { SuiviLigne } from '../../types';

type Props = {
  ligne: SuiviLigne;
  namePrefix: string;
  withStep: boolean;
};

export const LigneAvancement: React.FC<Props> = ({ ligne, namePrefix, withStep }) => (
  <>
    {ligne.prixFournituresHT !== 0 && (
      <div className="flex items-center justify-between gap-md mb-xxs">
        <div className="text-ds-b2 text-neutral-700">Fourniture commandée et réceptionnée</div>
        <ButtonGroupField
          name={`${namePrefix}.hasFournitures`}
          options={[
            { value: false, label: 'Non', disabled: ligne.previousCycleHasFournitures },
            { value: true, label: 'Oui' },
          ]}
        />
      </div>
    )}
    <div className="mb-xs text-neutral-700 text-ds-b2">Indiquez l'avancement de la prestation</div>
    <SliderField
      name={`${namePrefix}.pourcentageAvancement`}
      id={`${namePrefix}.pourcentageAvancement`}
      step={withStep ? 25 : 1}
      minValue={ligne.previousCyclePourcentageAvancement}
      initialValue={undefined}
      displayedValue={withStep ? 'step' : 'bound'}
    />
    {ligne.status === LigneStatus.Free && (
      <InlineAlert level="light">
        Pour les prestations offertes, l'avancement n'impacte pas le montant à libérer
      </InlineAlert>
    )}
  </>
);
