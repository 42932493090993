import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SuiviChantier } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { SUIVI_LIST_KEY } from 'features/Deals/api/useGetSuiviChantierList';
import { SuiviDeChantierTracking } from 'utils/tracking/SuiviDeChantierTracking';

type SendSuiviPayload = {
  emailSubject: string;
  emailContent: string;
  clientEmail: string;
};
type Props = {
  suivi: SuiviChantier;
  body: SendSuiviPayload;
};

const handleSendSuivi = ({ suivi, body }: Props): Promise<SuiviChantier> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${suivi.dealUuid}/suivi-chantier/${suivi.token}/envoi-mail`;
  return request(requestURL, {
    method: 'POST',
    body,
  });
};

export const useSendSuivi = (): typeof handleSendSuivi => {
  const queryClient = useQueryClient();

  const { mutateAsync: sendSuivi } = useMutation({
    mutationFn: handleSendSuivi,
    onSuccess: (suivi: SuiviChantier) => {
      SuiviDeChantierTracking.onSuiviDeChantierSent({ uuid: suivi.uuid });
      toast.success('Le suivi de chantier a bien été envoyé');
      queryClient.invalidateQueries({ queryKey: [SUIVI_LIST_KEY, suivi.dealUuid] });
    },
    onError: () => {
      toast.error("Le suivi de chantier n'a pas pu être envoyé");
    },
  });
  return sendSuivi;
};
