import React from 'react';

import classNames from 'classnames';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ModalitePaiement } from '@travauxlib/shared/src/types';
import { computeTotalPourcentageModalites } from '@travauxlib/shared/src/utils/computeTotalPourcentageModalites';
import { removeItemAtIndex, replaceItemAtIndex } from '@travauxlib/shared/src/utils/listMethods';

type Props = {
  prixTotalTTC: number;
  modalites: ModalitePaiement[];
  canEditModalites: boolean;
  onChange: (modalites: Array<Omit<ModalitePaiement, 'montant'>>) => void;
  onBlur: () => void;
  error?: string;
};

export const ModalitesComponent: React.FC<Props> = ({
  prixTotalTTC,
  modalites,
  canEditModalites,
  onChange,
  onBlur,
  error,
}) => {
  const totalPercentage = computeTotalPourcentageModalites(modalites);
  const hasOnlyFirstModaliteNotEditable =
    modalites.length === 1 && modalites[0].pourcentage !== 100;
  const enableAddModaliteButton =
    canEditModalites || modalites.some(v => v.isValueEditable) || hasOnlyFirstModaliteNotEditable;

  return (
    <>
      <div className="pr-md">
        {modalites.map((modalite, index) => (
          <div key={index} tabIndex={-1} className="flex relative flex-wrap">
            <div className="pr-md mb-md w-7/12">
              <Input
                data-testid={`modalites-${index}`}
                id={`modalites-${index}`}
                error={!modalite.label.trim() ? error : undefined}
                label="Motif du versement"
                value={modalite.label}
                onBlur={onBlur}
                disabled={!canEditModalites && !modalite.isValueEditable}
                onChange={(label: string) =>
                  onChange(replaceItemAtIndex(modalites, { ...modalite, label }, index))
                }
              />
            </div>
            <div className="pr-md lg-desktop:w-2/12 sm-desktop:w-5/12 sm-desktop:w-2/12 w-5/12">
              <NumberInput
                id={`pourcentage-${index}`}
                data-testid="number-input"
                value={modalite.pourcentage}
                onBlur={onBlur}
                disabled={!canEditModalites && !modalite.isValueEditable}
                onChange={(pourcentage?: number) =>
                  pourcentage !== undefined &&
                  onChange(replaceItemAtIndex(modalites, { ...modalite, pourcentage }, index))
                }
                suffix="%"
                min={0}
                max={100}
              />
            </div>
            <div className="lg-desktop:w-2/12 sm-desktop:w-4/12 sm-desktop:w-2/12 w-4/12 mb-md">
              <div className="text-gray-600 text-ds-sm">Total</div>
              <EURCurrency
                className="text-neutral-800 font-medium"
                amount={(modalite.pourcentage * prixTotalTTC) / 100}
              />
            </div>
            {(canEditModalites || modalite.isValueEditable) && (
              <div className="absolute top-sm -right-lg">
                <IconButton onClick={() => onChange(removeItemAtIndex(modalites, index))}>
                  <Delete className="w-md h-md" />
                </IconButton>
              </div>
            )}
          </div>
        ))}
        {enableAddModaliteButton && (
          <Button
            className="w-full font-bold p-xs mb-sm flex justify-center items-center"
            variant="primary"
            onClick={() => {
              onChange([
                ...modalites,
                {
                  label: 'Versement',
                  pourcentage: 1,
                  isLabelEditable: true,
                  isValueEditable: true,
                },
              ]);
            }}
          >
            <PlusCircle className="w-md h-md shrink-0" />
            <span className="ml-xs">Ajouter une échéance</span>
          </Button>
        )}
      </div>

      <div
        data-testid="total"
        className={classNames('flex justify-between', {
          'text-danger': totalPercentage !== 100,
          'text-gray-600': totalPercentage === 100,
        })}
      >
        <div className="text-neutral-800 font-medium w-7/12 text-ds-h5">
          Total des versements{' '}
          <span className="text-neutral-600 text-ds-b1">({totalPercentage}&nbsp;%)</span>
        </div>
        <EURCurrency
          className="text-neutral-800 font-bold w-5/12 text-center"
          amount={(totalPercentage * prixTotalTTC) / 100}
        />
      </div>
      {error && <div className="text-left text-danger">{error}</div>}
    </>
  );
};
