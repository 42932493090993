import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  ClubProFeatures,
  NotificationSubFeatures,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

type NotificationType = 'Ouverture Devis' | 'Révision Devis';

class NotificationTrackingClass extends Tracker<ClubProFeatures> {
  onNotificationTabOpened(): void {
    this.sendTracking({ subFeature: NotificationSubFeatures.Tab, event: TrackingEvents.Opened });
  }

  onNotificationClicked(properties: { type: NotificationType; action: string }): void {
    this.sendTracking({ event: TrackingEvents.Clicked, properties });
  }

  onNotificationArchived(properties: { type: NotificationType }): void {
    this.sendTracking({ event: TrackingEvents.Archived, properties });
  }

  onNotificationAllArchived(): void {
    this.sendTracking({ subFeature: NotificationSubFeatures.All, event: TrackingEvents.Archived });
  }
}

export const NotificationTracking = new NotificationTrackingClass(ClubProFeatures.Notification);
