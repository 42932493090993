import { ChiffreAffairesDeclare } from '@travauxlib/shared/src/types';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { ReassuranceColumnConfig } from 'components/ReassuranceColumn';

export const reassuranceColumnConfig: ReassuranceColumnConfig = {
  reassuranceSentences: [
    "Gagnez jusqu'à +30% de chiffre d'affaires.",
    'Concentrez-vous enfin sur votre métier.',
    'Bénéficiez de notre accompagnement, au quotidien.',
    'Réalisez des projets qualifiés et structurés.',
    "Disposez d'une assistance légale et administrative.",
  ],
  cartouchesTopData: [
    {
      label: "Bureau d'études",
      imgUrl: `${baseImgixUrl}/static/demande-chantier/BureauEtudes.png`,
      size: 'md',
      position: 'top-left',
    },
    {
      label: 'Professionnel du bâtiment',
      imgUrl: `${baseImgixUrl}/static/demande-chantier/ProDuBatiment.png`,
      size: 'sm',
      position: 'bottom-left',
    },
  ],
  cartouchesBottomData: [
    {
      label: 'Architecte HMONP',
      imgUrl: `${baseImgixUrl}/static/demande-chantier/ArchiHMONP.png`,
      size: 'sm',
      position: 'bottom-right',
    },
    {
      label: "Architecte d'intérieur",
      imgUrl: `${baseImgixUrl}/static/demande-chantier/ArchiInterieur.png`,
      size: 'md',
      position: 'top-right',
    },
  ],
  calendlySource: 'exit-during-demande-chantier',
};

export const optionChiffreAffaires: {
  value: ChiffreAffairesDeclare;
  label: string;
}[] = [
  {
    value: 'less_100K',
    label: 'Moins de 100 000€',
  },
  {
    value: '100_400K',
    label: 'Entre 100 000€ et 400 000€',
  },
  {
    value: '400_800K',
    label: 'Entre 400 000€ et 800 000€',
  },
  {
    value: 'more_800K',
    label: 'Plus de 800 000€',
  },
];
