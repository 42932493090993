import { useQuery } from '@tanstack/react-query';

import { request } from '@travauxlib/shared/src/utils/request';

import { Devis } from 'types';

export const TRANSFER_DEVIS_KEY = 'transfer-devis';

export const useProCompanyDevisList = ({
  proCompanySlug,
  dealUuid,
}: {
  proCompanySlug: string;
  dealUuid: string;
}): {
  devisList: Devis[];
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery<{ data: Devis[] }>({
    queryKey: [TRANSFER_DEVIS_KEY, dealUuid],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/admin/pro-companies/${proCompanySlug}/deals/${dealUuid}/devis`),
  });

  const devisList = data?.data || [];

  return { devisList, isLoading };
};
