import React, { useRef, useState } from 'react';

import { useFormState } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ProgressBar } from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { SuiviChantier } from '@travauxlib/shared/src/types';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';

import { BackLink } from 'components/DevisFacturesHeader/BackLink';
import { useDeal } from 'features/Deals/api/useDeal';
import { getDealRootUrl } from 'utils/urls';

import { useOpenSendSuiviModal } from '../hooks/useOpenSendSuiviModal';

type Props = {
  suivi: SuiviChantier;
  dealUuid: string;
  lastUpdatedAt: string;
  completionPercentage: number;
  completionAmount: number;
};

export const SuiviHeader: React.FC<Props> = ({
  suivi,
  dealUuid,
  lastUpdatedAt,
  completionPercentage,
  completionAmount,
}) => {
  const deal = useDeal(dealUuid);
  const isTabletOrMobile = useIsTabletOrMobile();
  const { submitting, dirty, hasValidationErrors } = useFormState();
  const openSendSuiviModal = useOpenSendSuiviModal();
  const [isTooltipDisabled, setIsTooltipDisabled] = useState<boolean>(true);

  const withTooltipRef = useRef<HTMLDivElement>(null);

  const [offsetX, setOffsetX] = useState(0);
  const handleMouseMove = (event: React.MouseEvent): void => {
    const progressBarRect = withTooltipRef.current?.getBoundingClientRect();
    if (!progressBarRect) {
      return;
    }
    setIsTooltipDisabled(false);
    const { width, left } = progressBarRect;
    const centerProgressBar = left + width / 2;

    setOffsetX(event.clientX - centerProgressBar);
  };

  return (
    <>
      <div className="flex fixed w-full border-solid border-b-1 border-light h-[4.5rem] pr-md bg-white z-20 items-center top-0">
        <div className="h-full w-2/12 sm-desktop:max-w-full">
          <BackLink label={deal?.customerName || ''} link={getDealRootUrl(dealUuid)} />
        </div>
        <div className="hidden w-4/12 lg-desktop:w-3/12 md-desktop:flex !items-center truncate">
          <InputField
            id="title"
            name="title"
            label="Suivi de chantier"
            className="px-xs truncate"
          />
        </div>
        <div className="flex pl-md md-desktop:pl-0 sm-desktop:w-full grow items-center pr-md justify-center">
          <div className="mr-md hidden tablet:block">État d'avancement</div>
          <div className="mr-md tablet:hidden">Avancement</div>
          <div className="block sm-desktop:hidden whitespace-nowrap font-bold text-neutral-800">
            {completionPercentage}%
          </div>
          <div className="grow hidden sm-desktop:block" ref={withTooltipRef}>
            <WithTooltip
              trigger={
                <div className="grow">
                  <ProgressBar
                    onMouseMove={handleMouseMove}
                    size="lg"
                    value={completionPercentage}
                    className="grow"
                  />
                </div>
              }
              disabled={isTooltipDisabled}
              position="bottom"
              width="10rem"
              height="2rem"
              offsetX={offsetX}
            >
              <EURCurrency amount={completionAmount} />
            </WithTooltip>
          </div>
        </div>
        <div className="text-gray-600 text-italic sm-desktop:flex flex-row whitespace-nowrap pr-xmd justify-end hidden">
          {(!hasValidationErrors && dirty) || submitting
            ? 'Enregistrement en cours...'
            : `Dernière modification le ${dateTimeFormat(lastUpdatedAt)}`}
        </div>
        <Button
          size={isTabletOrMobile ? 'sm' : 'md'}
          disabled={(!hasValidationErrors && dirty) || submitting}
          onClick={() => openSendSuiviModal({ suivi })}
        >
          Partager
        </Button>
      </div>
    </>
  );
};
