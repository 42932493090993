export const getBonCommandeTableColumns: () => Array<{
  label: string;
  columnName: string;
}> = () => [
  { label: 'Nom du bon de commande', columnName: 'title' },
  { label: 'Société', columnName: 'societe' },
  { label: 'Montant HT', columnName: 'prixHT' },
  { label: 'Marge dégagée', columnName: 'montantMargeDegagee' },
  { label: 'Statut', columnName: 'status' },
  { label: 'Date', columnName: 'lastModifiedAt' },
];
