import { CountryCode } from 'libphonenumber-js';

import Algerie from '../../assets/Flags/Algeria.svg?react';
import Bahrein from '../../assets/Flags/Bahrain.svg?react';
import Belgium from '../../assets/Flags/Belgium.svg?react';
import Benin from '../../assets/Flags/Benin.svg?react';
import BurkinaFaso from '../../assets/Flags/Burkina-Faso.svg?react';
import CoteDIvoire from '../../assets/Flags/Cote_dIvoire.svg?react';
import France from '../../assets/Flags/France.svg?react';
import Germany from '../../assets/Flags/Germany.svg?react';
import Guadeloupe from '../../assets/Flags/Guadeloupe.svg?react';
import Irlande from '../../assets/Flags/Ireland.svg?react';
import Israel from '../../assets/Flags/Israel.svg?react';
import Italy from '../../assets/Flags/Italy.svg?react';
import Luxembourg from '../../assets/Flags/Luxembourg.svg?react';
import Martinique from '../../assets/Flags/Martinique.svg?react';
import Mayotte from '../../assets/Flags/Mayotte.svg?react';
import Maroc from '../../assets/Flags/Morocco.svg?react';
import PaysBas from '../../assets/Flags/Netherlands.svg?react';
import Palestine from '../../assets/Flags/Palestine.svg?react';
import Portugal from '../../assets/Flags/Portugal.svg?react';
import Qatar from '../../assets/Flags/Qatar.svg?react';
import Reunion from '../../assets/Flags/Reunion.svg?react';
import SaintBarthelemy from '../../assets/Flags/Saint-barthelemy.svg?react';
import SaintMartin from '../../assets/Flags/Saint-martin.svg?react';
import Senegal from '../../assets/Flags/Senegal.svg?react';
import Spain from '../../assets/Flags/Spain.svg?react';
import Suisse from '../../assets/Flags/Switzerland.svg?react';
import Tunisie from '../../assets/Flags/Tunisia.svg?react';
import UAE from '../../assets/Flags/United Arab Emirates.svg?react';
import UK from '../../assets/Flags/United kingdom.svg?react';
import USA from '../../assets/Flags/USA.svg?react';

export const phoneInputCountries: Array<{
  label: string;
  value: CountryCode;
  icon: React.ComponentType<React.SVGProps<SVGElement>>;
}> = [
  {
    label: 'Algérie',
    value: 'DZ',
    icon: Algerie,
  },
  {
    label: 'Allemagne',
    value: 'DE',
    icon: Germany,
  },
  {
    label: 'Bahreïn',
    value: 'BH',
    icon: Bahrein,
  },
  {
    label: 'Belgique',
    value: 'BE',
    icon: Belgium,
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
    icon: BurkinaFaso,
  },
  {
    label: 'Bénin',
    value: 'BJ',
    icon: Benin,
  },
  {
    label: "Côte d'Ivoire",
    value: 'CI',
    icon: CoteDIvoire,
  },
  {
    label: 'Espagne',
    value: 'ES',
    icon: Spain,
  },
  {
    label: 'États-Unis',
    value: 'US',
    icon: USA,
  },
  {
    label: 'France',
    value: 'FR',
    icon: France,
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
    icon: Guadeloupe,
  },
  {
    label: 'Irlande',
    value: 'IE',
    icon: Irlande,
  },
  {
    label: 'Israël',
    value: 'IL',
    icon: Israel,
  },
  {
    label: 'Italie',
    value: 'IT',
    icon: Italy,
  },
  {
    label: 'La Réunion',
    value: 'RE',
    icon: Reunion,
  },
  {
    label: 'Luxembourg',
    value: 'LU',
    icon: Luxembourg,
  },
  {
    label: 'Maroc',
    value: 'MA',
    icon: Maroc,
  },
  {
    label: 'Martinique',
    value: 'MQ',
    icon: Martinique,
  },
  {
    label: 'Mayotte',
    value: 'YT',
    icon: Mayotte,
  },
  {
    label: 'Palestine',
    value: 'PS',
    icon: Palestine,
  },
  {
    label: 'Pays-Bas',
    value: 'NL',
    icon: PaysBas,
  },
  {
    label: 'Portugal',
    value: 'PT',
    icon: Portugal,
  },
  {
    label: 'Qatar',
    value: 'QA',
    icon: Qatar,
  },
  {
    label: 'Royaume-Uni',
    value: 'GB',
    icon: UK,
  },
  {
    label: 'Saint-Barthélemy',
    value: 'BL',
    icon: SaintBarthelemy,
  },
  {
    label: 'Saint-Martin',
    value: 'MF',
    icon: SaintMartin,
  },
  {
    label: 'Suisse',
    value: 'CH',
    icon: Suisse,
  },
  {
    label: 'Sénégal',
    value: 'SN',
    icon: Senegal,
  },
  {
    label: 'Tunisie',
    value: 'TN',
    icon: Tunisie,
  },
  {
    label: 'Émirats arabes unis',
    value: 'AE',
    icon: UAE,
  },
];
