import React from 'react';

import { useNavigate } from 'react-router-dom';

import InfoCircle from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { useOpenCalendly } from '@travauxlib/shared/src/hooks/useOpenCalendly';

import { useProfile } from 'api/useProfile';
import { parametresAbonnementUrl } from 'utils/urls';

export const TrialPeriodBanner: React.FC<{ trialPeriodRemainingDays: number }> = ({
  trialPeriodRemainingDays,
}) => {
  const navigate = useNavigate();
  const openCalendly = useOpenCalendly();
  const { profile } = useProfile();

  return (
    <div className="top-0 bg-info-50 z-50 w-full sm-desktop:h-xxl sm-desktop:flex-row flex flex-col justify-between items-center sm-desktop:px-xl px-md sticky py-xs">
      <div className="flex mb-xs sm-desktop:mb-0">
        <div className="w-lg h-lg text-info-800 mr-xs flex items-center">
          <InfoCircle />
        </div>
        <div className="text-ds-b2 text-neutral-800 flex items-center">
          Il vous reste {trialPeriodRemainingDays} jour{trialPeriodRemainingDays > 1 && 's'} de
          période d'essai pour révolutionner votre quotidien !
        </div>
      </div>
      <div className="w-full sm-desktop:w-auto flex">
        <div>
          <Button
            variant="tertiary"
            size="sm"
            className="mr-sm"
            onClick={() =>
              openCalendly({
                email: profile.email,
                phoneNumber: profile.phoneNumber,
                firstName: profile.firstName,
                lastName: profile.lastName,
                calendlyLink: APP_CONFIG.calendlyClubProUrl,
                calendlySource: 'trial-period-banner',
              })
            }
          >
            Obtenir une démo
          </Button>
        </div>
        <div>
          <Button
            variant="secondary"
            size="sm"
            className="bg-info-50 hover:bg-info-100 active:bg-info-200 hover:text-dark w-full sm-desktop:w-auto flex justify-center"
            onClick={() => navigate(parametresAbonnementUrl)}
          >
            Souscrire à un abonnement
          </Button>
        </div>
      </div>
    </div>
  );
};
