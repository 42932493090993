import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { InsurancePayload } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { AUTH_KEY } from './useProfile';

const handleUploadInsurance = (insurance: InsurancePayload): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro/insurances`;
  return request(requestURL, {
    method: 'POST',
    body: insurance,
  });
};

export const useUploadInsurance = (): typeof handleUploadInsurance => {
  const queryClient = useQueryClient();
  const { mutateAsync: uploadInsurance } = useMutation({
    mutationFn: handleUploadInsurance,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });
      toast.success('Votre assurance a bien été ajoutée.');
    },
    onError: () => {
      toast.error("Une erreur est survenue, l'assurance n'a pas pu être envoyée.");
    },
  });

  return uploadInsurance;
};
