import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { Attachment } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { DEVIS_KEY } from './useDevis';

export type UploadFilesPayload = {
  token: string;
  dealUuid: string;
  files: File[];
  fileType: string;
};

const handleUploadFiles = async ({
  token,
  dealUuid,
  files,
  fileType,
}: UploadFilesPayload): Promise<{ token: string; dealUuid: string; files: Attachment[] }> => {
  const fileTypePath = fileType === 'devisAttachment' ? 'attachment' : 'fourniture-image';
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/devis/${token}/${fileTypePath}`;

  const apiCalls = files.map(file => {
    const formData = new FormData();
    formData.append(fileType, file);

    return request(requestURL, {
      method: 'POST',
      body: formData,
    });
  });
  const newFiles = await Promise.all(apiCalls);
  return { token, dealUuid, files: newFiles };
};

export const useUploadFiles = (): typeof handleUploadFiles => {
  const queryClient = useQueryClient();
  const { mutateAsync: uploadFiles } = useMutation({
    mutationFn: handleUploadFiles,
    onSuccess: ({ token, dealUuid }) => {
      queryClient.invalidateQueries({ queryKey: [DEVIS_KEY, dealUuid, token] });
    },
    onError: () => {
      toast.error("La pièce jointe n'a pas pu être ajoutée à votre devis");
    },
  });
  return uploadFiles;
};
