import { FC, useCallback, useMemo } from 'react';

import classNames from 'classnames';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { Carousel } from '@travauxlib/shared/src/components/DesignSystem/components/Carousel';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { UserProfile } from '@travauxlib/shared/src/types';

import { useParticipants, useRemoveParticipant } from 'features/Deals/api/useParticipants';
import { ConversationParticipant, Deal, ProCompany } from 'types';

import { AddParticipantButton } from './AddParticipantButton';
import { Participant } from './Participant';

type ParticipantListProps = {
  deal: Deal;
  profile?: UserProfile<ProCompany>;
  onParticipantClick?: (participant: ConversationParticipant) => void;
  onParticipantRemove?: (participant: ConversationParticipant) => void;
  onPlusClick?: () => void;
  withDetail?: boolean;
};

export const ParticipantList: FC<ParticipantListProps> = ({
  deal,
  profile,
  onParticipantClick,
  onParticipantRemove,
  onPlusClick,
  withDetail = false,
}) => {
  const { conversationUuid } = deal;
  const { participants, isLoading } = useParticipants(deal);
  const { remove } = useRemoveParticipant();

  const isCurrentUserInConversation = useMemo(
    () => participants.find(participant => participant.email === profile?.email) !== undefined,
    [participants, profile],
  );

  const handleParticipantRemove = useCallback(
    (participant: ConversationParticipant) => {
      onParticipantRemove?.(participant);
      remove({ conversationUuid, uuid: participant.uuid! });
    },
    [remove, onParticipantRemove],
  );

  const renderParticipant = (participant: ConversationParticipant): React.ReactElement => (
    <Participant
      key={participant.uuid || participant.email}
      onClick={onParticipantClick}
      onRemove={handleParticipantRemove}
      participant={participant}
      withDetail={withDetail}
    />
  );

  const carouselItems = useMemo(() => {
    if (isLoading) {
      return [];
    }

    let items: ((ConversationParticipant & { isCurrentUser?: boolean }) | 'addButton')[] =
      onPlusClick ? ['addButton'] : [];
    items = items.concat(participants);

    if (profile && !isCurrentUserInConversation) {
      items.push({
        name: profile.fullName,
        email: profile.email,
        isCurrentUser: true,
      });
    }

    return items;
  }, [isLoading, onPlusClick, participants, profile, isCurrentUserInConversation]);

  const renderCarouselItem = (item: ConversationParticipant | 'addButton'): React.ReactElement => {
    if (item === 'addButton') {
      return (
        <AddParticipantButton
          key="addButton"
          onClick={() => onPlusClick?.()}
          withDetail={withDetail}
        />
      );
    }

    if ('isCurrentUser' in item) {
      return (
        <WithTooltip
          key="currentUser"
          trigger={
            <div className="!relative">
              <Participant
                isDisabled
                withDetail={withDetail}
                participant={{ name: item.name, email: item.email }}
              />
              <div
                className={classNames(
                  'absolute flex',
                  withDetail ? 'top-xxs -left-xxs' : 'top-0 right-xxs',
                )}
              >
                <HelpCircle className="w-md h-md text-primary" />
              </div>
            </div>
          }
          position="bottom"
        >
          <p className="!p-xxs !m-0">Envoyez un message pour participer à cette conversation</p>
        </WithTooltip>
      );
    }

    return renderParticipant(item);
  };

  if (withDetail) {
    return (
      <div className="!flex !overflow-auto !flex-col !py-xs !px-xxs">
        {carouselItems.map(renderCarouselItem)}
      </div>
    );
  }

  return (
    <div className="px-md">
      <Carousel items={carouselItems} renderItem={renderCarouselItem} />
    </div>
  );
};
