import React from 'react';

import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';

import { phoneInputCountries } from './constant';
import { formatInternationalPhoneNumber, formatPhoneNumber, isValidPhoneNumber } from './utils';

import { Dropdown } from '../Dropdown';
import { Input, Props as InputProps } from '../Input';
import { InputErrorText, InputHelperText } from '../Input/commons';
import { IconInput } from '../Input/IconInput';

const defaultCountryCode = 'FR';

export interface PhoneNumber {
  countryCode?: CountryCode;
  number?: string;
  internationalPhoneNumber?: string;
}

type Props = Omit<InputProps, 'value' | 'onChange'> & {
  onChange: (phoneNumber: PhoneNumber) => void;
  value: PhoneNumber;
};

export const PhoneInput: React.FC<Props> = ({
  onBlur,
  onFocus,
  value,
  helperText,
  id,
  onChange,
  error,
  ...rest
}) => {
  const [selectedCountryCode, setSelectedCountryCode] =
    React.useState<CountryCode>(defaultCountryCode);
  const [typingPhoneNumber, setTypingPhoneNumber] = React.useState<PhoneNumber | undefined>();

  const onChangePhoneNumber = (newPhoneNumber: string): void => {
    const formattedPhoneNumber = typingPhoneNumber
      ? formatInternationalPhoneNumber(selectedCountryCode, newPhoneNumber)
      : undefined;

    const phoneNumberObject = {
      countryCode: selectedCountryCode,
      number: formatPhoneNumber(selectedCountryCode, newPhoneNumber),
      internationalPhoneNumber: formattedPhoneNumber,
      isValid: isValidPhoneNumber(selectedCountryCode, newPhoneNumber),
    };

    setTypingPhoneNumber(phoneNumberObject);
    onChange(phoneNumberObject);
  };

  React.useEffect(() => {
    if (typingPhoneNumber?.number) {
      onChangePhoneNumber(typingPhoneNumber.number);
    }
  }, [selectedCountryCode]);

  return (
    <div>
      <div className="flex cursor-pointer">
        <Dropdown
          id={`country-code_${id}`}
          inputClassName="w-3xl h-xxl !pr-0 !rounded-r-none"
          optionsContainerClassName="min-w-[160px]"
          options={phoneInputCountries}
          InputComponent={IconInput}
          onChange={setSelectedCountryCode}
          defaultOption={phoneInputCountries.find(country => country.value === defaultCountryCode)}
        />
        <Input
          error={error}
          onChange={onChangePhoneNumber}
          onFocus={onFocus}
          onBlur={onBlur}
          value={typingPhoneNumber?.number || ''}
          prefix={
            selectedCountryCode ? `+${getCountryCallingCode(selectedCountryCode)}` : undefined
          }
          renderingOptions={{
            hideErrorText: true,
            hideHelperText: true,
            containerClassName: '!rounded-l-none !border-l-0 h-xxl',
          }}
          id={id}
          {...rest}
        />
      </div>
      <InputErrorText error={error} />
      <InputHelperText helperText={helperText} />
    </div>
  );
};
