import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { getUrlParam } from '@travauxlib/shared/src/utils/urls';

import { useCheckAlmaPayment } from 'features/MonCompte/api/useCheckAlmaPayment';

export const CheckAlmaPayment: React.FC = () => {
  const { checkAlmaPayment, isLoading } = useCheckAlmaPayment();
  const paymentId = getUrlParam(useLocation(), 'pid');
  useEffect(() => {
    if (paymentId) {
      checkAlmaPayment(paymentId);
    }
  }, [paymentId, checkAlmaPayment]);

  if (isLoading) {
    return <div>Vérification du paiement en cours ...</div>;
  }
  return paymentId ? <div>{paymentId}</div> : <div>Échec du paiement</div>;
};
