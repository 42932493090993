import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Facture } from 'types';

export const LAST_FACTURES_KEY = 'last-factures';

export const useLastFactures = (limit?: number): { factures: Facture[]; isLoading: boolean } => {
  const { data, isLoading } = useQuery<{ data: Facture[] }>({
    queryKey: [LAST_FACTURES_KEY],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/factures`;

        return await request(requestURL, {
          params: {
            limit,
          },
        });
      } catch (err) {
        toast.error("Les factures n'ont pas pu être récupérées");
        throw err;
      }
    },
  });

  return { factures: data?.data || [], isLoading };
};
