import React from 'react';

import { useLocation } from 'react-router-dom';

import { Deal } from 'types';

import { ChaleurDeal } from './ChaleurDeal';
import { FeedbackRDV } from './FeedbackRDV';
import { TypePrestationArchi } from './typePrestationArchi';

const ArchitecteFeedback: React.FC<Feedback> = ({ deal }) => {
  const { feedbackRDV, typePrestationArchi } = deal;

  if (typePrestationArchi === undefined) {
    return <TypePrestationArchi dealUuid={deal.uuid} />;
  }

  if (feedbackRDV === undefined) {
    return <FeedbackRDV deal={deal} />;
  }

  return null;
};

type Props = {
  deal: Deal;
  isArchitecte: boolean;
};

const ProFeedback: React.FC<Feedback> = ({ deal }) => {
  const { chaleurDeal, feedbackRDV } = deal;
  const location = useLocation();
  const chaleurDealFromParams =
    new URLSearchParams(location.search).get('chaleurDeal') || undefined;

  if (chaleurDeal === undefined) {
    return <ChaleurDeal chaleurDeal={chaleurDealFromParams} deal={deal} />;
  }

  if (feedbackRDV === undefined) {
    return <FeedbackRDV deal={deal} />;
  }

  return null;
};

type Feedback = Omit<Props, 'isArchitecte'>;

export const FeedbackQuestions: React.FC<Props> = ({ deal, isArchitecte }) => (
  <div className="border !p-md">
    {isArchitecte ? <ArchitecteFeedback deal={deal} /> : <ProFeedback deal={deal} />}
  </div>
);
