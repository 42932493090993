import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import {
  ContractorDetails,
  ContractorModalProps,
} from '../components/Contractors/ContractorDetails';

export const useOpenContractorDetailsModal = (): ((props?: ContractorModalProps) => void) => {
  const openModal = useOpenModal();

  return (props: ContractorModalProps) =>
    openModal(ContractorDetails, {
      proContractor: props?.proContractor,
      title: 'Informations du prestataire',
      closable: true,
      onSave: props?.onSave,
      defaultCheckedRGE: props.defaultCheckedRGE,
    });
};
