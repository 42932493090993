import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { DEVIS_LIST_KEY } from 'features/Devis/api/useDevisList';

type Payload = {
  projectUuid: string;
  dealUuid: string;
  title: string;
};

export const handleUpdateProjectTitle = async ({
  projectUuid,
  dealUuid,
  title,
}: Payload): Promise<{ dealUuid: string }> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/projects/${projectUuid}/title`;
  await request(requestURL, {
    method: 'PATCH',
    body: {
      title,
    },
  });
  return { dealUuid };
};

export const useUpdateProjectTitle = (): typeof handleUpdateProjectTitle => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateProjectTitle } = useMutation({
    mutationFn: handleUpdateProjectTitle,
    onSuccess: ({ dealUuid }: { dealUuid: string }) => {
      queryClient.invalidateQueries({ queryKey: [DEVIS_LIST_KEY, dealUuid] });
    },
    onError: () => {
      toast.error('Impossible de mettre à jour le titre');
    },
  });
  return updateProjectTitle;
};
